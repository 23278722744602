import React, { useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { useEffect } from 'react';
import { ScaleLoader } from 'react-spinners';
// import { UserContext } from './Context';
// import { useContext } from 'react';
import { Modal, Button, Form, Table } from "react-bootstrap";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const DeclaredHolliday = () => {
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState(''); //search filter
  const componentRef = React.useRef();  //download options
  const [loading, setLoading] = useState(true);


  const [attendanceData, setAttendanceData] = useState([]);
  const userData = JSON.parse(localStorage.getItem('userData'));


  const userrole = userData?.userrole || '';
  const userempid = userData.userempid || '';
  const [conditionDisplay, setConditionDisplay] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://officeinteriorschennai.com/api.php?userrolenavigation');
        setConditionDisplay(response.data.data);
        //  console.log("setConditionDisplayAdmin --------------->", response.data.data)
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);



  const fetchData = async () => {
    try {

      const response = await axios.get(`https://officeinteriorschennai.com/api.php?viewholidaylist`);
      // Assuming the API response contains a data property with an array of employee data
      const { data } = response.data;

      // Sort the data based on the id property
      data.sort((a, b) => a.id - b.id);

      // Set the sorted data to the state
      setAttendanceData(data);
      //console.log("API Response", data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);





  // download options
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



  const handleExportCSV = () => {
    const csvData = attendanceData.map(({ h_name, h_date, h_day }, index) => ({
      'S.No': index + 1,
      'Public Holidays': h_name,
      'Date': h_date,
      'Day': h_day
    }));

    const headers = [
      { label: 'S.No', key: 'S.No' },
      { label: 'Date', key: 'Date' },
      { label: 'Public Holidays', key: 'Public Holidays' },
      { label: 'Day', key: 'Day' },
    ];

    const csvReport = {
      data: csvData,
      headers: headers,
      filename: 'holidaylist.csv',
    };

    // Use CSVLink for CSV export
    return <CSVLink {...csvReport}><i className="fas fa-file-csv" style={{ fontSize: '25px', color: '#212529' }}></i></CSVLink>;
  };




  const handleExportPDF = () => {
    const unit = 'pt';
    const size = 'A4';

    const doc = new jsPDF('landscape', unit, size);

    const data = attendanceData.map(({ h_name, h_date, h_day }, index) => [
      index + 1,
      h_name,
      h_date,
      h_day,
    ]);

    doc.autoTable({
      head: [['S.No', 'Public Holidays', 'Date', 'Day']],
      body: data,
    });

    doc.save('holidaylist.pdf');
  };
  // download options end


  // search filter
  const filteredData = attendanceData.filter((row) =>
    Object.values(row).some(
      (value) =>
        (typeof value === 'string' || typeof value === 'number') &&
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // pagination start

  const filteredAttendanceData = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  // pagination end

  // search filter end


  // internal styles start

  const myStyles = {
    color: 'white',
    fontSize: '16px',
    border: '1px solid black',
    marginRight: '15px',
    borderRadius: '21px',
    padding: '5px 7px',
    boxShadow: 'black 0px 0px 10px 1px'

  };

  const myStyles1 = {
    color: 'black',
    fontSize: '16px',
    border: '1px solid black',
    marginRight: '15px',
    borderRadius: '21px',
    padding: '5px 7px',
    boxShadow: 'black 0px 0px 10px 1px'

  };

  // add holiday 
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    updated_hr_id: userempid,
    h_name: "",
    h_date: "",
    h_day: "",
  });

  const handleAddHoliday = () => {
    setIsModalOpen(true);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onDelete = async (e) => {
    const hid = e;
    //  console.log('sdfsd', hid);
    try {
      const response = await axios.get(`https://officeinteriorschennai.com/api.php?deleteholiday=${hid}`);
      const { data } = response.data;

      if (response.data.status === "success") {
        Swal.fire({
          icon: 'delete',
          title: 'Delete',
          text: 'Holiday Deleted successfully',
        });
        // setLoading(false); // Assuming setLoading is properly defined and updates loading state
        fetchData();
      } else {
        console.error('Error deleting holiday:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting holiday:', error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.h_name && formData.h_date && formData.h_day) {

      const formDataToSend = new FormData();
      formDataToSend.append('updated_hr_id', formData.updated_hr_id);
      formDataToSend.append('h_name', formData.h_name);
      formDataToSend.append('h_date', formData.h_date);
      formDataToSend.append('h_day', formData.h_day);

      try {
        const response = await fetch('https://officeinteriorschennai.com/api.php?holidayinsert', {
          method: 'POST',
          body: formDataToSend,
        });

        const data = await response.json();

        if (data.status == 'success') {
          setIsModalOpen(false);
          setFormData({ ...formData, h_name: "", h_date: "", h_day: "" });
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Holiday Added successfully',
          });
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Holiday Already Added',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add Holiday',
        });
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill all the details',
      });
    }
  };

  const [editviewData, setEditviewData] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleEdit = (h_id, h_date, h_name, h_day, userempid) => {
    setIsEditModalOpen(true);
    const vieweditData = {
      h_id: h_id,
      h_date: formateditDate(h_date), // Format date before setting state
      h_name: h_name,
      h_day: h_day,
      userempid: userempid
    };
    setEditviewData(vieweditData);
  };

  // console.log(editviewData.h_date);

  useEffect(() => {
    if (isEditModalOpen && editviewData.h_date) {
      // Format date when the modal is opened and h_date is available
      setEditviewData({ ...editviewData, h_date: formateditDate(editviewData.h_date) });
    }
  }, [isEditModalOpen, editviewData.h_date]);

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditviewData({ ...editviewData, [name]: value });
  };


  // Function to format date as YYYY-MM-DD
  const formateditDate = (dateString) => {
    const dateParts = dateString.split('-');
    const year = dateParts[2];
    const month = dateParts[1];
    const day = dateParts[0];
    return `${day}-${month}-${year}`;
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    if (editviewData.h_name && editviewData.h_date && editviewData.h_day) {

      const formDataToSend = new FormData();
      formDataToSend.append('updated_hr_id', editviewData.userempid);
      formDataToSend.append('h_name', editviewData.h_name);
      formDataToSend.append('h_date', editviewData.h_date);
      formDataToSend.append('h_day', editviewData.h_day);
      formDataToSend.append('h_id', editviewData.h_id);

      try {
        const response = await fetch('https://officeinteriorschennai.com/api.php?editholiday', {
          method: 'POST',
          body: formDataToSend,
        });

        const data = await response.json();

        if (data.status == 'success') {
          setIsEditModalOpen(false);
          // setFormData({...formData,h_name: "",h_date: "",h_day: ""});
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Holiday Updated successfully',
          });
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Holiday Already Added',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add Holiday',
        });
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill all the details',
      });
    }
  };



  return (
    <>
      {loading ? (
        <div style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#f6f6f6'
        }}>
          <ScaleLoader color="#36d7b7" />
        </div>
      ) : (


        <div style={{ display: 'grid', maxWidth: '1035px', margin: '0 auto', paddingTop: '7vh' }}>

          <h3 style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>Declared holliday List</h3>
          {conditionDisplay.includes(userrole) && (
            <>
              <button
                className="btn btn-info btn-sm mr-2"
                style={{
                  width: '18%',
                  alignItems: 'left',
                  backgroundColor: '#0d6efd',
                  borderColor: '#0d6efd',
                  color: 'white',
                  marginRight: '10px', fontSize: '20px',
                }}
                onClick={handleAddHoliday}
              >
                + Add Holiday
              </button>
              <br></br>
            </>
          )}

          <Modal show={isModalOpen} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add Holiday</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="h_name">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Holiday Name :</Form.Label>
                  <Form.Control
                    as="input"
                    name="h_name"
                    value={formData.h_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="h_date">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="h_date"
                    value={formData.h_date || ''}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="h_day">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Day:</Form.Label>
                  <Form.Control
                    as="input"
                    name="h_day"
                    value={formData.h_day}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <div style={{ paddingTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" style={{ marginLeft: '10px' }}>
                    Submit
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={isEditModalOpen} onHide={handleEditCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Holiday</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleEditSubmit}>
                <Form.Group controlId="h_name">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Holiday Name :</Form.Label>
                  <Form.Control
                    as="input"
                    name="h_name"
                    value={editviewData.h_name || ''}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="h_date">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="h_date"
                    value={editviewData.h_date || ''}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="h_day">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Day:</Form.Label>
                  <Form.Control
                    as="input"
                    name="h_day"
                    value={editviewData.h_day || ''}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>

                <div style={{ paddingTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="secondary" onClick={handleEditCloseModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" style={{ marginLeft: '10px' }}>
                    Submit
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', justifyContent: 'space-between' }}>
            <div>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(0);
                }
                }
                style={myStyles1}

              />
            </div>
            <div>
              <button style={myStyles}>{handleExportCSV()}</button>

              <button style={myStyles} onClick={handleExportPDF}><i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#212529' }}></i></button>
              <button style={myStyles} onClick={handlePrint}><i className="fas fa-print" style={{ fontSize: '25px', color: '#212529' }}></i></button>
            </div>
          </div>
          <div ref={componentRef}>
            <table className="table table-dark table-striped">
              <thead>
                <tr>

                  <th scope="col">S.no</th>
                  <th scope="col">Public Holidays</th>
                  <th scope="col">Date</th>
                  <th scope="col">Day</th>
                  {conditionDisplay.includes(userrole) && (
                    <th scope="col">Action</th>
                  )}



                </tr>
              </thead>
              <tbody>
                {filteredAttendanceData.map((row, index) => {
                  const serialNumber = currentPage * itemsPerPage + index + 1;
                  return (
                    <tr key={row.h_id}>
                      <th scope="row">{serialNumber}</th>
                      <td>{row.h_name}</td>
                      <td>{row.format_date}</td>
                      <td>{row.h_day}</td>
                      {conditionDisplay.includes(userrole) && (
                        <td>
                          <button
                            className="btn btn-info btn-sm mr-2"
                            onClick={() => handleEdit(row.h_id, row.h_date, row.h_name, row.h_day, userempid)}
                            style={{ width: '45%', backgroundColor: '#0d6efd', borderColor: '#0d6efd', color: 'white', marginRight: '10px' }}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => onDelete(row.h_id)}
                            style={{ width: '45%' }}
                          >
                            Delete
                          </button>
                        </td>
                      )}

                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>


          {/* Pagination */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <ReactPaginate
              previousLabel={<span aria-hidden="true">&laquo;</span>}
              nextLabel={<span aria-hidden="true">&raquo;</span>}
              breakLabel={<span>...</span>}
              breakClassName={'page-item disabled'}
              breakLinkClassName={'page-link'}
              pageCount={Math.ceil(filteredData.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              disabledClassName={'disabled'}
              activeLinkClassName={'bg-dark text-white'}
            />


          </div>
        </div>

      )}
    </>
  );
}


export default DeclaredHolliday;
