// LoadingIcon.js
import React from 'react';

function LoadingIcon() {
  return (
<div class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
  );
  }
export default LoadingIcon;
