import React from 'react'
import '../../assets/admin/css/Employeedetails.css'
import Empprofile from "../../assets/admin/assets/img/profile.png";

import { useNavigate } from 'react-router-dom';


export default function EmployeeDetailsComponent(props) {

  const { name, role, mobile, email, picture, id, empid } = props;
  // console.log("props id -------->", id)


  const navigate = useNavigate();

  const handleVisitProfile = () => {
    // Use navigate function to navigate to the '/profile' route
    navigate(`/admin/user_profile/${id}`);
  };


  // const[fname, setFname]=useState('Roobun');
  // const[empid, setEmpid]=useState('16536');
  // const[role, setRole]=useState('Developer');
  // const[department, setDepartment]=useState('IT');

  //   const gotoDetailsPage = () => {
  //     navigate(`/user_detail/${id}`);
  // };


  return (

    <div className='full-card-container'>
      <div className='Empcard'>


        <div className='upper-container'>
          <div className='image-container'>
            <img src={`https://officeinteriorschennai.com/${picture}`} alt="" style={{ width: '100px', height: '100px' }} className='empprofile' />
          </div>
        </div>

        <div className='lower-contaier'>
          <h4>Name: {name}</h4>
          <h5>EMP id: <span style={{ color: '#838383', fontWeight: '700' }}>{empid}</span></h5>
          <h5>Role: <span style={{ color: '#838383', fontWeight: '700' }}>{role}</span></h5>
          <h5>Mobile: <span style={{ color: '#838383', fontWeight: '700' }}>{mobile}</span></h5>
          <h5>Email: <span style={{ color: '#838383', fontWeight: '700' }}>{email}</span></h5>
          <button className='bt1' onClick={handleVisitProfile}>View profile</button>



        </div>

      </div>

    </div>
  )
}
