import React, { useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ReactPaginate from 'react-paginate';
import { FaCheck, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { ScaleLoader } from 'react-spinners';


export default function HalfdayrequestTL() {

    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const componentRef = React.useRef();
    const [halfdayData, setHalfdayData] = useState([]);
    const [loading, setLoading] = useState(true);

    // ========================================
    // print start

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // print end
    // ========================================

    // ========================================
    // CSV start

    const handleExportCSV = () => {
        const csvData = halfdayData.map(
            ({

                emp_name,
                category_name,
                permission_date,
                permission_timefrom,
                permission_timeto,
                leave_reason,
                emp_status,

            }, index) => ({

                '#': index + 1,
                emp_name,
                category_name,
                permission_date,
                permission_timefrom,
                permission_timeto,
                leave_reason,
                emp_status,

            })
        );

        const headers = [
            { label: '#', key: '#' },
            { label: 'Name', key: 'emp_name' },
            { label: 'Category', key: 'category_name' },
            { label: 'Date', key: 'permission_date' },
            { label: 'Time From', key: 'permission_timefrom' },
            { label: 'Time To', key: 'permission_timeto' },
            { label: 'Reason', key: 'leave_reason' },
            { label: 'Status', key: 'emp_status' },

        ];

        const csvReport = {
            data: csvData,
            headers: headers,
            filename: 'monthly_pay.csv',
        };

        return <CSVLink {...csvReport}><i className="fas fa-file-csv" style={{ fontSize: '25px', color: '#212529' }}></i></CSVLink>;
    };
    // csv end
    // ========================================

    // ========================================
    // PDF start

    const handleExportPDF = () => {
        const unit = 'pt';
        const size = 'A4';

        const doc = new jsPDF('landscape', unit, size);

        const data = halfdayData.map(({ emp_name, category_name, permission_date, permission_timefrom, permission_timeto, leave_reason, emp_status }, index) => [
            index + 1,
            emp_name,
            category_name,
            permission_date,
            permission_timefrom,
            permission_timeto,
            leave_reason,
            emp_status,
        ]);

        doc.autoTable({
            head: [['#', 'Name', 'category', 'date', 'timeFrom', 'timeTo', 'reason', 'status']],
            body: data,
        });

        doc.save('attendance.pdf');
    };

    // PDF End
    // ========================================


    // ========================================
    // Fillter start


    const filteredData = halfdayData.filter((row) =>
        Object.values(row).some(
            (value) =>
                (typeof value === 'string' || typeof value === 'number') &&
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );


    // Fillter End
    // ========================================
    const filteredhalfdayData = filteredData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };



    // const handleStatusButtonClick = (index, status) => {
    //     // Update the status of the corresponding row
    //     const updatedhalfdayData = [...halfdayData];
    //     updatedhalfdayData[index].status = status;
    //     // Update the state with the modified data
    //     setHalfdayData(updatedhalfdayData);
    // };

    const myStyles = {
        color: 'white',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        borderRadius: '21px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'
    };

    const myStyles1 = {
        color: 'black',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',

        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'
    };

    // ==============================================================

    // Retrieve userData from local storage
    const userData = JSON.parse(localStorage.getItem('userData'));


    const userrole = userData?.userrole || '';
    const userempid = userData?.userempid || '';

    // ================================================


    useEffect(() => {
        fetchData();
    }, []); // Fetch data on initial render

    const fetchData = async () => {
        try {                                 
            const response = await axios.get(`https://officeinteriorschennai.com/api.php?halfdayapprovallistroleid=${userrole}&halfdayapprovallistempid=${userempid}`);
            setHalfdayData(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // console.log("setHalfdayData --->", halfdayData)

    // =====================================================================================

    // To approve or Reject Request
    const [refresh, setRefresh] = useState(false); // State to trigger refresh

    // To approve or Reject Request


    const handleStatusButtonClick = (row, status) => {
        // Update the row status locally (optional)
        row.status = status;

        // Create FormData object
        const formData = new FormData();
        formData.append('id', row.id);
        formData.append('e_id', row.emp_id);
        formData.append('hr_id', userempid);
        formData.append('hrstatus', status); // Use the passed 'status' parameter instead of 'row.value'

        formData.append('request_date', row.permission_date);
        formData.append('request_fromtime', row.permission_timefrom);
        formData.append('request_totime', row.permission_timeto);

        // Make API call                                  
        // https://officeinteriorschennai.com/api.php?approvalhalfdayrequest
        fetch('https://officeinteriorschennai.com/api.php?approvalhalfdayrequestteamlead', {
            method: 'POST',
            body: formData
        })



            .then(response => {

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })

            .then(data => {
                // console.log('API response:', data);
                // Check if the status is success
                if (data.status === 'success') {
                    // Update hrstatus locally without waiting for reload
                    // Update hrstatus and emp_status locally without waiting for reload
                    row.hrstatus = status;
                    row.emp_status = status; // Directly use 'status'

                    // Depending on the status, display the corresponding message
                    if (status === 'Approved') {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Leave Request Successfully Approved',
                        });
                    } else if (status === 'Rejected') {
                        Swal.fire({
                            icon: 'error',
                            title: 'Rejected',
                            text: 'Leave Request Rejected',
                        });
                    }

                    // Trigger component refresh by updating state
                    setRefresh(prevRefresh => !prevRefresh);

                } else {
                    // Handle other status scenarios if needed
                    console.error('Leave Request Approval failed:', data.message);

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Leave Request Approval failed',
                    });
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                // Handle error

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'There was a problem with the fetch operation',
                });
            });
    };

    // =====================================================================================


    // ====== this is side bar validation ======

    const [conditionDisplay, setConditionDisplay] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?userrolenavigation');
                setConditionDisplay(response.data.data);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchData();
    }, []);


    // ======================================================================================

    return (
        <>

            {loading ? (
                <div style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#f6f6f6'
                }}>
                    <ScaleLoader color="#36d7b7" />
                </div>
            ) : (


                <div style={{ display: 'grid', maxWidth: '1035px', margin: '0 auto', paddingTop: '7vh' }}>
                    <h3 style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>Half Day Request List</h3>
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', justifyContent: 'space-between' }}>
                        <div>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={myStyles1}
                            />
                        </div>
                        <div>
                            <button style={myStyles}>{handleExportCSV()}</button>
                            <button style={myStyles} onClick={handleExportPDF}><i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                            <button style={myStyles} onClick={handlePrint}><i className="fas fa-print" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                        </div>
                    </div>
                    <div ref={componentRef}>
                        <table className="table table-dark table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">FromTime</th>
                                    <th scope="col">To Time</th>
                                    <th scope="col" style={{ width: '150px' }}>Reason</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    filteredhalfdayData.length === 0 ? (
                                        <tr>
                                            <td colSpan="8" style={{ textAlign: 'center' }}>No search data found</td>
                                        </tr>
                                    ) : (

                                        // =======================

                                        filteredhalfdayData.map((row, index) => {

                                            const serialNumber = currentPage * itemsPerPage + index + 1;
                                            return (
                                                <tr key={row.id}>
                                                    <th scope="row">{serialNumber}</th>
                                                    <td>{row.emp_name}</td>
                                                    <td>{row.category_name}</td>
                                                    <td>{row.permission_date}</td>
                                                    <td>{row.permission_timefrom}</td>
                                                    <td>{row.permission_timeto}</td>
                                                    <td>{row.leave_reason}</td>
                                                    <td>
                                                        {row.emp_status === 'Pending' ?
                                                            
                                                                <div>
                                                                    <button
                                                                        style={row.emp_status === 'Approved' ? myStyles : myStyles1}
                                                                        onClick={() => handleStatusButtonClick(row, 'Approved')}
                                                                    >
                                                                        <FaCheck color="green" size={24} />
                                                                    </button>
                                                                    <button
                                                                        style={row.emp_status === 'Rejected' ? myStyles : myStyles1}
                                                                        onClick={() => handleStatusButtonClick(row, 'Rejected')}
                                                                    >
                                                                        <FaTimes color="red" size={24} />
                                                                    </button>
                                                                </div>
                                                                
                                                            :
                                                            <p>{row.emp_status}</p>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })

                                        // =======================

                                    )}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <ReactPaginate
                            previousLabel={<span aria-hidden="true">&laquo;</span>}
                            nextLabel={<span aria-hidden="true">&raquo;</span>}
                            breakLabel={<span>...</span>}
                            breakClassName={'page-item disabled'}
                            breakLinkClassName={'page-link'}
                            pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            disabledClassName={'disabled'}
                            activeLinkClassName={'bg-dark text-white'}
                        />
                    </div>
                </div>


            )}
        </>
    );
}
