import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const GeneratePaySlip = () => {

    const userData = JSON.parse(localStorage.getItem('userData'));

    const [person, setPerson] = useState('');
    const [late, setLate] = useState('');
    const [absent, setAbsent] = useState('');
    const [leave, setLeave] = useState('');
    const [permission, setPermission] = useState('');
    const [halfDays, setHalfDays] = useState('');
    const [totalWorkingDays, setTotalWorkingDays] = useState('');
    const [workedDays, setWorkedDays] = useState('');
    const [lossofPay, setLossofPay] = useState('');
    const [emppf, setEmppf] = useState('');
    const [salary, setSalary] = useState('');
    const [perDaySalary, setPerDaySalary] = useState('');
    const [basicDA, setBasicDA] = useState('');
    const [basicDAPercent, setBasicDAPercent] = useState('');
    const [hra, setHra] = useState('');
    const [hraPercent, setHraPercent] = useState('');
    const [convenience, setConvenience] = useState('');
    const [transport, setTransport] = useState('');
    const [specialAllowance, setSpecialAllowance] = useState('');
    const [specialAllowancePercent, setSpecialAllowancePercent] = useState('');
    const [netPay, setNetPay] = useState('');


    //---------------------------------------------------------------


    const [conditionDisplay, setConditionDisplay] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://officeinteriorschennai.com/api.php?editpayslipgenerator`);
                setConditionDisplay(response.data.data);
            } catch (error) {
                // console.log(error.message);
            }
        };
        fetchData();
    }, []);

    //---------------------------------------------------------------


    const handleSubmit = async (e) => {


        e.preventDefault();


        if (person.trim() && userData && selectedMonth.trim() && selectedYear.trim() && lossofPay.trim() && basicDAPercent.trim()
            && hraPercent.trim() && convenience.trim() && transport.trim() && late.trim() && halfDays.trim() && absent.trim() && leave.trim()
            && permission.trim() && workedDays.trim() && totalWorkingDays.trim()) {


            const formData = new FormData();

            formData.append('addpayslipempid', person);
            formData.append('updateloginhrid', userData.userempid);
            formData.append('addpayslipdate', `${selectedYear}-${selectedMonth}`);
            formData.append('addpayslipemplop', lossofPay);
            formData.append('addpayslipbasicdapercentage', basicDAPercent);
            formData.append('addpayslippercentage', hraPercent);
            formData.append('addpayslipconveyanceamount', convenience);
            formData.append('addpaysliptransportamount', transport);
            formData.append('late_count', late);
            formData.append('halfday_count', halfDays);
            formData.append('absent_count', absent);
            formData.append('leave_count', leave);
            formData.append('permission_count', permission);
            formData.append('present_count', workedDays);
            formData.append('total_days_in_month', totalWorkingDays);
            formData.append('emppf', emppf);


            // console.log('addpayslipempid:', person);
            // console.log('updateloginhrid:', userData.userempid);
            // console.log('addpayslipdate:', `${selectedYear}-${selectedMonth}`);
            // console.log('addpayslipemplop:', lossofPay);
            // console.log('addpayslipbasicdapercentage:', basicDAPercent);
            // console.log('addpayslippercentage:', hraPercent);
            // console.log('addpayslipconveyanceamount:', convenience);
            // console.log('addpaysliptransportamount:', transport);
            // console.log('late_count:', late);
            // console.log('halfday_count:', halfDays);
            // console.log('absent_count:', absent);
            // console.log('permission_count:', permission);
            // console.log('present_count:', workedDays);
            // console.log('total_days_in_month:', totalWorkingDays);

            try {
                const response = await fetch('https://officeinteriorschennai.com/api.php?insertpayslip', {
                    method: 'POST',
                    body: formData,
                });

                const data = await response.json();

                if (data.status == 'success') {

                    setPerson(null);
                    setLate('');
                    setSelectedMonth(0);
                    setSelectedYear(0)
                    setPermission('');
                    setAbsent('');
                    setLeave('');
                    setHalfDays('');
                    setTotalWorkingDays('');
                    setWorkedDays('');
                    setLossofPay('');
                    setEmppf('');
                    setSalary('');
                    setPerDaySalary('');
                    setBasicDA('');
                    setBasicDAPercent('');
                    setHra('');
                    setHraPercent('');
                    setConvenience('');
                    setTransport('');
                    setSpecialAllowance('');
                    setSpecialAllowancePercent('');
                    setNetPay('');

                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: `Pay Slip Generated Successfully..`,
                    });
                }
                else {
                    throw new Error(`Already you have added this month Payslip..`);
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Error: ${error.message}`,
                });
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill in all the required fields.',
            });
        }
    }

    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?employeelist');
                setData([{ id: "0", first_name: "Select", last_name: "Employee" }, ...response.data.data]);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchData();
    }, []);


    const NameArray = data.map(employee => ({
        id: employee.id,
        name: `${employee.first_name} ${employee.last_name}`,
    }));


    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const yearRange = 2;

    const months = [
        { label: 'Select Month', value: 0 },
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
    ];

    const generateYears = (range) => {
        const currentYear = new Date().getFullYear();
        const years = [{ label: "Select Year", value: 0 }];
        for (let i = currentYear - 2; i <= currentYear; i++) {
            years.push({ label: `${i}`, value: i });
        }
        return years;
    };

    //-----------------------------------------------------------------------------------


    useEffect(() => {
        if (person, selectedMonth, selectedYear) {
            const fetchData = async () => {
                try {
                    const res = await axios.get(`https://officeinteriorschennai.com/api.php?payslipempid=${person}&payslipdate=${selectedYear}-${selectedMonth}`);
                    if (res.status === 200) {
                        let values = res.data;
                        // console.log("values --->", values);
                        setLate(values.latecount !== undefined ? values.latecount.toString() : '');
                        setAbsent(values.absentcount !== undefined ? values.absentcount.toString() : '');
                        setLeave(values.leavecount !== undefined ? values.leavecount.toString() : '');
                        setPermission(values.permissioncount !== undefined ? values.permissioncount.toString() : '');
                        setHalfDays(values.halfdaycount !== undefined ? values.halfdaycount.toString() : '')
                        setTotalWorkingDays(values.totalmonthlyworkingdays !== undefined ? values.totalmonthlyworkingdays.toString() : '');
                        setWorkedDays(values.totalworkeddays !== undefined ? values.totalworkeddays.toString() : '');
                        setLossofPay(values.totallopdays !== undefined ? values.totallopdays.toString() : '');
                        setSalary(values.empsalary !== undefined ? values.empsalary.toString() : '');
                        setEmppf(values.emppf !== undefined ? values.emppf.toString() : '')
                        setPerDaySalary(values.empsalaryperday !== undefined ? values.empsalaryperday.toString() : '');
                        setBasicDA(values.basicdaamount !== undefined ? values.basicdaamount.toString() : '');
                        setBasicDAPercent(values.basicdapercentage !== undefined ? values.basicdapercentage.toString() : '');
                        setHra(values.hraamount !== undefined ? values.hraamount.toString() : '');
                        setHraPercent(values.hrapercentage !== undefined ? values.hrapercentage.toString() : '');
                        setConvenience(values.conveyanceAllowance !== undefined ? values.conveyanceAllowance.toString() : '');
                        setTransport(values.transportAllowance !== undefined ? values.transportAllowance.toString() : '');
                        setSpecialAllowance(values.otherAllowancesamount !== undefined ? values.otherAllowancesamount.toString() : '');
                        setSpecialAllowancePercent(values.otherAllowancespercentage !== undefined ? values.otherAllowancespercentage.toString() : '');
                        setNetPay(values.totalnetpayamount !== undefined ? values.totalnetpayamount.toString() : '');
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        }
    }, [person, selectedMonth, selectedYear]);


    //==========================================================================

    const [editingLossOfPay, setEditingLossOfPay] = useState(false);
    const [editingBasicDAPercent, setEditingBasicDAPercent] = useState(false);
    const [editingHraPercent, setEditingHraPercent] = useState(false);
    const [editingConvenience, setEditingConvenience] = useState(false);
    const [editingTransport, setEditingTransport] = useState(false);

    const [editingEmployeePF, setEditingEmployeePF] = useState(false);



    const handleEditLossOfPay = () => {
        setEditingLossOfPay(true);
    };

    const handleSaveLossOfPay = async () => {
        setEditingLossOfPay(false);
        try {
            const res = await axios.get(`https://officeinteriorschennai.com/api.php?payslipemployeeempid=${person}&payslipemployeedate=${selectedYear}-${selectedMonth}&payslipemployeelop=${lossofPay}&payslipemployeebasicdapercentage=${basicDAPercent}&payslipemployeehrapercentage=${hraPercent}&payslipemployeeconveyanceamount=${convenience}&payslipemployeetransportamount=${transport}&payslipemployeepfamount=${emppf}`);
            if (res.status === 200) {
                let values = res.data;
                setLate(values.latecount !== undefined ? values.latecount.toString() : '');
                setAbsent(values.absentcount !== undefined ? values.absentcount.toString() : '');
                setLeave(values.leavecount !== undefined ? values.leavecount.toString() : '');
                setPermission(values.permissioncount !== undefined ? values.permissioncount.toString() : '');
                setHalfDays(values.halfdaycount !== undefined ? values.halfdaycount.toString() : '')
                setTotalWorkingDays(values.totalmonthlyworkingdays !== undefined ? values.totalmonthlyworkingdays.toString() : '');
                setWorkedDays(values.totalworkeddays !== undefined ? values.totalworkeddays.toString() : '');
                setLossofPay(values.totallopdays !== undefined ? values.totallopdays.toString() : '');
                setSalary(values.empsalary !== undefined ? values.empsalary.toString() : '');
                setEmppf(values.emppf !== undefined ? values.emppf.toString() : '')
                setPerDaySalary(values.empsalaryperday !== undefined ? values.empsalaryperday.toString() : '');
                setBasicDA(values.basicdaamount !== undefined ? values.basicdaamount.toString() : '');
                setBasicDAPercent(values.basicdapercentage !== undefined ? values.basicdapercentage.toString() : '');
                setHra(values.hraamount !== undefined ? values.hraamount.toString() : '');
                setHraPercent(values.hrapercentage !== undefined ? values.hrapercentage.toString() : '');
                setConvenience(values.conveyanceAllowance !== undefined ? values.conveyanceAllowance.toString() : '');
                setTransport(values.transportAllowance !== undefined ? values.transportAllowance.toString() : '');
                setSpecialAllowance(values.otherAllowancesamount !== undefined ? values.otherAllowancesamount.toString() : '');
                setSpecialAllowancePercent(values.otherAllowancespercentage !== undefined ? values.otherAllowancespercentage.toString() : '');
                setNetPay(values.totalnetpayamount !== undefined ? values.totalnetpayamount.toString() : '');
            }
        } catch (error) {
            console.log(error);
        }
    };


    //=====================================================================================

    const handleEditBasicDAPercent = () => {
        setEditingBasicDAPercent(true);
    };

    const handleSaveBasicDAPercent = async () => {
        setEditingBasicDAPercent(false);
        try {
            const res = await axios.get(`https://officeinteriorschennai.com/api.php?payslipemployeeempid=${person}&payslipemployeedate=${selectedYear}-${selectedMonth}&payslipemployeelop=${lossofPay}&payslipemployeebasicdapercentage=${basicDAPercent}&payslipemployeehrapercentage=${hraPercent}&payslipemployeeconveyanceamount=${convenience}&payslipemployeetransportamount=${transport}&payslipemployeepfamount=${emppf}`);
            if (res.status === 200) {
                let values = res.data;
                setLate(values.latecount !== undefined ? values.latecount.toString() : '');
                setAbsent(values.absentcount !== undefined ? values.absentcount.toString() : '');
                setLeave(values.leavecount !== undefined ? values.leavecount.toString() : '');
                setHalfDays(values.halfdaycount !== undefined ? values.halfdaycount.toString() : '')
                setPermission(values.permissioncount !== undefined ? values.permissioncount.toString() : '');
                setTotalWorkingDays(values.totalmonthlyworkingdays !== undefined ? values.totalmonthlyworkingdays.toString() : '');
                setWorkedDays(values.totalworkeddays !== undefined ? values.totalworkeddays.toString() : '');
                setLossofPay(values.totallopdays !== undefined ? values.totallopdays.toString() : '');
                setSalary(values.empsalary !== undefined ? values.empsalary.toString() : '');
                setEmppf(values.emppf !== undefined ? values.emppf.toString() : '')
                setPerDaySalary(values.empsalaryperday !== undefined ? values.empsalaryperday.toString() : '');
                setBasicDA(values.basicdaamount !== undefined ? values.basicdaamount.toString() : '');
                setBasicDAPercent(values.basicdapercentage !== undefined ? values.basicdapercentage.toString() : '');
                setHra(values.hraamount !== undefined ? values.hraamount.toString() : '');
                setHraPercent(values.hrapercentage !== undefined ? values.hrapercentage.toString() : '');
                setConvenience(values.conveyanceAllowance !== undefined ? values.conveyanceAllowance.toString() : '');
                setTransport(values.transportAllowance !== undefined ? values.transportAllowance.toString() : '');
                setSpecialAllowance(values.otherAllowancesamount !== undefined ? values.otherAllowancesamount.toString() : '');
                setSpecialAllowancePercent(values.otherAllowancespercentage !== undefined ? values.otherAllowancespercentage.toString() : '');
                setNetPay(values.totalnetpayamount !== undefined ? values.totalnetpayamount.toString() : '');
            }
        } catch (error) {
            console.log(error);
        }
    };

    //=====================================================================================

    const handleEditHraPercent = () => {
        setEditingHraPercent(true);
    };

    const handleSaveHraPercent = async () => {
        setEditingHraPercent(false);
        try {
            const res = await axios.get(`https://officeinteriorschennai.com/api.php?payslipemployeeempid=${person}&payslipemployeedate=${selectedYear}-${selectedMonth}&payslipemployeelop=${lossofPay}&payslipemployeebasicdapercentage=${basicDAPercent}&payslipemployeehrapercentage=${hraPercent}&payslipemployeeconveyanceamount=${convenience}&payslipemployeetransportamount=${transport}&payslipemployeepfamount=${emppf}`);
            if (res.status === 200) {
                let values = res.data;
                setLate(values.latecount !== undefined ? values.latecount.toString() : '');
                setAbsent(values.absentcount !== undefined ? values.absentcount.toString() : '');
                setLeave(values.leavecount !== undefined ? values.leavecount.toString() : '');
                setHalfDays(values.halfdaycount !== undefined ? values.halfdaycount.toString() : '')
                setPermission(values.permissioncount !== undefined ? values.permissioncount.toString() : '');
                setTotalWorkingDays(values.totalmonthlyworkingdays !== undefined ? values.totalmonthlyworkingdays.toString() : '');
                setWorkedDays(values.totalworkeddays !== undefined ? values.totalworkeddays.toString() : '');
                setLossofPay(values.totallopdays !== undefined ? values.totallopdays.toString() : '');
                setSalary(values.empsalary !== undefined ? values.empsalary.toString() : '');
                setEmppf(values.emppf !== undefined ? values.emppf.toString() : '')
                setPerDaySalary(values.empsalaryperday !== undefined ? values.empsalaryperday.toString() : '');
                setBasicDA(values.basicdaamount !== undefined ? values.basicdaamount.toString() : '');
                setBasicDAPercent(values.basicdapercentage !== undefined ? values.basicdapercentage.toString() : '');
                setHra(values.hraamount !== undefined ? values.hraamount.toString() : '');
                setHraPercent(values.hrapercentage !== undefined ? values.hrapercentage.toString() : '');
                setConvenience(values.conveyanceAllowance !== undefined ? values.conveyanceAllowance.toString() : '');
                setTransport(values.transportAllowance !== undefined ? values.transportAllowance.toString() : '');
                setSpecialAllowance(values.otherAllowancesamount !== undefined ? values.otherAllowancesamount.toString() : '');
                setSpecialAllowancePercent(values.otherAllowancespercentage !== undefined ? values.otherAllowancespercentage.toString() : '');
                setNetPay(values.totalnetpayamount !== undefined ? values.totalnetpayamount.toString() : '');
            }
        } catch (error) {
            console.log(error);
        }
    };


    //=======================================================================================

    const handleEditEmployeePF = () => {
        setEditingEmployeePF(true);
    };

    const handleSaveEmployeePF = async () => {
        setEditingEmployeePF(false);
        try {
            const res = await axios.get(`https://officeinteriorschennai.com/api.php?payslipemployeeempid=${person}&payslipemployeedate=${selectedYear}-${selectedMonth}&payslipemployeelop=${lossofPay}&payslipemployeebasicdapercentage=${basicDAPercent}&payslipemployeehrapercentage=${hraPercent}&payslipemployeeconveyanceamount=${convenience}&payslipemployeetransportamount=${transport}&payslipemployeepfamount=${emppf}`);
            if (res.status === 200) {
                let values = res.data;
                setLate(values.latecount !== undefined ? values.latecount.toString() : '');
                setAbsent(values.absentcount !== undefined ? values.absentcount.toString() : '');
                setLeave(values.leavecount !== undefined ? values.leavecount.toString() : '');
                setHalfDays(values.halfdaycount !== undefined ? values.halfdaycount.toString() : '')
                setPermission(values.permissioncount !== undefined ? values.permissioncount.toString() : '');
                setTotalWorkingDays(values.totalmonthlyworkingdays !== undefined ? values.totalmonthlyworkingdays.toString() : '');
                setWorkedDays(values.totalworkeddays !== undefined ? values.totalworkeddays.toString() : '');
                setLossofPay(values.totallopdays !== undefined ? values.totallopdays.toString() : '');
                setSalary(values.empsalary !== undefined ? values.empsalary.toString() : '');
                setEmppf(values.emppf !== undefined ? values.emppf.toString() : '')
                setPerDaySalary(values.empsalaryperday !== undefined ? values.empsalaryperday.toString() : '');
                setBasicDA(values.basicdaamount !== undefined ? values.basicdaamount.toString() : '');
                setBasicDAPercent(values.basicdapercentage !== undefined ? values.basicdapercentage.toString() : '');
                setHra(values.hraamount !== undefined ? values.hraamount.toString() : '');
                setHraPercent(values.hrapercentage !== undefined ? values.hrapercentage.toString() : '');
                setConvenience(values.conveyanceAllowance !== undefined ? values.conveyanceAllowance.toString() : '');
                setTransport(values.transportAllowance !== undefined ? values.transportAllowance.toString() : '');
                setSpecialAllowance(values.otherAllowancesamount !== undefined ? values.otherAllowancesamount.toString() : '');
                setSpecialAllowancePercent(values.otherAllowancespercentage !== undefined ? values.otherAllowancespercentage.toString() : '');
                setNetPay(values.totalnetpayamount !== undefined ? values.totalnetpayamount.toString() : '');
            }
        } catch (error) {
            console.log(error);
        }
    };

    //=======================================================================================

    const handleEditConvenience = () => {
        setEditingConvenience(true);
    };

    const handleSaveConvenience = async () => {
        setEditingConvenience(false);
        try {
            const res = await axios.get(`https://officeinteriorschennai.com/api.php?payslipemployeeempid=${person}&payslipemployeedate=${selectedYear}-${selectedMonth}&payslipemployeelop=${lossofPay}&payslipemployeebasicdapercentage=${basicDAPercent}&payslipemployeehrapercentage=${hraPercent}&payslipemployeeconveyanceamount=${convenience}&payslipemployeetransportamount=${transport}&payslipemployeepfamount=${emppf}`);
            if (res.status === 200) {
                let values = res.data;
                setLate(values.latecount !== undefined ? values.latecount.toString() : '');
                setAbsent(values.absentcount !== undefined ? values.absentcount.toString() : '');
                setLeave(values.leavecount !== undefined ? values.leavecount.toString() : '');
                setHalfDays(values.halfdaycount !== undefined ? values.halfdaycount.toString() : '')
                setPermission(values.permissioncount !== undefined ? values.permissioncount.toString() : '');
                setTotalWorkingDays(values.totalmonthlyworkingdays !== undefined ? values.totalmonthlyworkingdays.toString() : '');
                setWorkedDays(values.totalworkeddays !== undefined ? values.totalworkeddays.toString() : '');
                setLossofPay(values.totallopdays !== undefined ? values.totallopdays.toString() : '');
                setSalary(values.empsalary !== undefined ? values.empsalary.toString() : '');
                setEmppf(values.emppf !== undefined ? values.emppf.toString() : '')
                setPerDaySalary(values.empsalaryperday !== undefined ? values.empsalaryperday.toString() : '');
                setBasicDA(values.basicdaamount !== undefined ? values.basicdaamount.toString() : '');
                setBasicDAPercent(values.basicdapercentage !== undefined ? values.basicdapercentage.toString() : '');
                setHra(values.hraamount !== undefined ? values.hraamount.toString() : '');
                setHraPercent(values.hrapercentage !== undefined ? values.hrapercentage.toString() : '');
                setConvenience(values.conveyanceAllowance !== undefined ? values.conveyanceAllowance.toString() : '');
                setTransport(values.transportAllowance !== undefined ? values.transportAllowance.toString() : '');
                setSpecialAllowance(values.otherAllowancesamount !== undefined ? values.otherAllowancesamount.toString() : '');
                setSpecialAllowancePercent(values.otherAllowancespercentage !== undefined ? values.otherAllowancespercentage.toString() : '');
                setNetPay(values.totalnetpayamount !== undefined ? values.totalnetpayamount.toString() : '');
            }
        } catch (error) {
            console.log(error);
        }
    };

    //========================================================================================

    const handleEditTransport = () => {
        setEditingTransport(true);
    };

    const handleSaveTransport = async () => {
        setEditingTransport(false);
        try {
            const res = await axios.get(`https://officeinteriorschennai.com/api.php?payslipemployeeempid=${person}&payslipemployeedate=${selectedYear}-${selectedMonth}&payslipemployeelop=${lossofPay}&payslipemployeebasicdapercentage=${basicDAPercent}&payslipemployeehrapercentage=${hraPercent}&payslipemployeeconveyanceamount=${convenience}&payslipemployeetransportamount=${transport}&payslipemployeepfamount=${emppf}`);
            if (res.status === 200) {
                let values = res.data;
                setLate(values.latecount !== undefined ? values.latecount.toString() : '');
                setAbsent(values.absentcount !== undefined ? values.absentcount.toString() : '');
                setLeave(values.leavecount !== undefined ? values.leavecount.toString() : '');
                setHalfDays(values.halfdaycount !== undefined ? values.halfdaycount.toString() : '')
                setPermission(values.permissioncount !== undefined ? values.permissioncount.toString() : '');
                setTotalWorkingDays(values.totalmonthlyworkingdays !== undefined ? values.totalmonthlyworkingdays.toString() : '');
                setWorkedDays(values.totalworkeddays !== undefined ? values.totalworkeddays.toString() : '');
                setLossofPay(values.totallopdays !== undefined ? values.totallopdays.toString() : '');
                setSalary(values.empsalary !== undefined ? values.empsalary.toString() : '');
                setEmppf(values.emppf !== undefined ? values.emppf.toString() : '')
                setPerDaySalary(values.empsalaryperday !== undefined ? values.empsalaryperday.toString() : '');
                setBasicDA(values.basicdaamount !== undefined ? values.basicdaamount.toString() : '');
                setBasicDAPercent(values.basicdapercentage !== undefined ? values.basicdapercentage.toString() : '');
                setHra(values.hraamount !== undefined ? values.hraamount.toString() : '');
                setHraPercent(values.hrapercentage !== undefined ? values.hrapercentage.toString() : '');
                setConvenience(values.conveyanceAllowance !== undefined ? values.conveyanceAllowance.toString() : '');
                setTransport(values.transportAllowance !== undefined ? values.transportAllowance.toString() : '');
                setSpecialAllowance(values.otherAllowancesamount !== undefined ? values.otherAllowancesamount.toString() : '');
                setSpecialAllowancePercent(values.otherAllowancespercentage !== undefined ? values.otherAllowancespercentage.toString() : '');
                setNetPay(values.totalnetpayamount !== undefined ? values.totalnetpayamount.toString() : '');
            }
        } catch (error) {
            console.log(error);
        }
    };


    return (

        <div className="container mt-4" style={{ display: 'grid', maxWidth: '1035px', margin: '0 auto', boxShadow: '0px 0px 10px 1px black', marginBottom: '15px', paddingBottom: '10px' }}>
            <h2 style={{ textAlign: 'center' }}>Generate Pay slip</h2>

            <div style={boxStyles}>

                <form onSubmit={handleSubmit}>


                    <div className="row mb-3">
                        <div className="col-md-4 mb-3">
                            <label className="form-label">Select Employee</label>
                            <select className="form-select" value={person} onChange={(e) => setPerson(e.target.value)}>
                                {NameArray.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-4 mb-3">
                            <label className="form-label">Select Month</label>
                            <select className="form-select" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                                {months.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>


                        <div className="col-md-4 mb-3">
                            <label className="form-label">Select Year</label>
                            <select className="form-select" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                                {generateYears(yearRange).map((year, index) => (
                                    <option key={year.value} value={year.value}>{year.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">

                        <div className="col-md-6 mb-3">
                            <label className="form-label">No of Leave</label>
                            <input disabled type="text" className="form-control" value={leave} onChange={(e) => setLeave(e.target.value)} />

                        </div>


                        <div className="col-md-6 mb-3">
                            <label className="form-label">No of Late</label>
                            <input disabled type="text" className="form-control" value={late} onChange={(e) => setLate(e.target.value)} />

                        </div>
                    </div>


                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">No of Absent</label>
                            <input disabled type="text" className="form-control" value={absent} onChange={(e) => setAbsent(e.target.value)} />

                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">No of Permission</label>
                            <input disabled type="text" className="form-control" value={permission} onChange={(e) => setPermission(e.target.value)} />

                        </div>
                    </div>


                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">No of Half Days</label>
                            <input disabled type="text" className="form-control" value={halfDays} onChange={(e) => setHalfDays(e.target.value)} />

                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">Total Working Days in Month</label>
                            <input disabled type="text" className="form-control" value={totalWorkingDays} onChange={(e) => setTotalWorkingDays(e.target.value)} />

                        </div>
                    </div>


                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">No of Days Worked</label>
                            <input disabled type="text" className="form-control" value={workedDays} onChange={(e) => setWorkedDays(e.target.value)} />

                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">Loss of Pay</label>
                            <div className="input-group">
                                <input disabled={!editingLossOfPay} type="text" className="form-control" value={lossofPay} onChange={(e) => setLossofPay(e.target.value)} />
                                {!editingLossOfPay ? (
                                    <span className="input-group-text" onClick={handleEditLossOfPay}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
                                ) : (
                                    <span className="input-group-text" onClick={handleSaveLossOfPay} style={{ cursor: 'pointer', color: 'green' }}>
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                )}
                            </div>
                        </div>


                    </div>
                    {/* ======================================= */}






                    {/* ========================================== */}

                    <div className="row mb-3">



                        <div className="col-md-6 mb-3">
                            <label className="form-label">Employee PF</label>
                            <div className="input-group">
                                <input disabled={!editingEmployeePF} type="text" className="form-control" value={emppf} onChange={(e) => setEmppf(e.target.value)} />


                                {!editingEmployeePF ? (
                                    <span className="input-group-text" onClick={handleEditEmployeePF}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
                                ) : (
                                    <span className="input-group-text" onClick={handleSaveEmployeePF} style={{ cursor: 'pointer', color: 'green' }}>
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                )}
                            </div>
                        </div>



                        <div className="col-md-6 mb-3">
                            <label className="form-label">Salary</label>
                            <input disabled type="text" className="form-control" value={salary} onChange={(e) => setSalary(e.target.value)} />
                        </div>
                    </div>


                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Per Day Salary</label>
                            <input disabled type="text" className="form-control" value={Math.ceil(perDaySalary * 10) / 10} onChange={(e) => setPerDaySalary(e.target.value)} />

                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">Basic DA Percentage</label>
                            <div className="input-group">
                                <input disabled={!editingBasicDAPercent} type="text" className="form-control" value={basicDAPercent} onChange={(e) => setBasicDAPercent(e.target.value)} />
                                {!editingBasicDAPercent ? (
                                    <span className="input-group-text" onClick={handleEditBasicDAPercent}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
                                ) : (
                                    <span className="input-group-text" onClick={handleSaveBasicDAPercent} style={{ cursor: 'pointer', color: 'green' }}>
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                )}
                            </div>
                        </div>

                    </div>



                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Basic DA</label>
                            <input disabled type="text" className="form-control" value={Math.round(basicDA)} onChange={(e) => setBasicDA(e.target.value)} />

                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">HRA Percentage</label>
                            <div className="input-group">
                                <input disabled={!editingHraPercent} type="text" className="form-control" value={hraPercent} onChange={(e) => setHraPercent(e.target.value)} />
                                {!editingHraPercent ? (
                                    <span className="input-group-text" onClick={handleEditHraPercent}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
                                ) : (
                                    <span className="input-group-text" onClick={handleSaveHraPercent} style={{ cursor: 'pointer', color: 'green' }}>
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">HRA</label>
                            <input disabled type="text" className="form-control" value={Math.round(hra)} onChange={(e) => setHra(e.target.value)} />

                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">Conveyance</label>
                            <div className="input-group">
                                <input disabled={!editingConvenience} type="text" className="form-control" value={convenience} onChange={(e) => setConvenience(e.target.value)} />
                                {!editingConvenience ? (
                                    <span className="input-group-text" onClick={handleEditConvenience}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
                                ) : (
                                    <span className="input-group-text" onClick={handleSaveConvenience} style={{ cursor: 'pointer', color: 'green' }}>
                                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>


                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Transport</label>
                            <div className="input-group">
                                <input disabled={!editingTransport} type="text" className="form-control" value={transport} onChange={(e) => setTransport(e.target.value)} />
                                {!editingTransport ? (
                                    <span className="input-group-text" onClick={handleEditTransport}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
                                ) : (
                                    <span className="input-group-text" onClick={handleSaveTransport} style={{ cursor: 'pointer', color: 'green' }}>
                                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">Other Percentage</label>
                            <input disabled type="text" className="form-control" value={specialAllowancePercent} onChange={(e) => setSpecialAllowancePercent(e.target.value)} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Other Allowance</label>
                            <input disabled type="text" className="form-control" value={Math.round(specialAllowance)} onChange={(e) => setSpecialAllowance(e.target.value)} />

                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">Net Pay</label>
                            <input disabled type="text" className="form-control" value={netPay} onChange={(e) => setNetPay(e.target.value)} />
                        </div>
                    </div>


                    <button type="submit" className="btn btn-primary">
                        Generate PaySlip
                    </button>
                </form>
            </div>
        </div>
    );
};


const boxStyles = {
    background: 'linear-gradient(90deg, rgb(240, 223, 223), rgb(234, 234, 234), rgb(222, 222, 222))',
    borderRadius: '10px',
    padding: '20px 25px 0px 25px',
    marginTop: '35px'
};

export default GeneratePaySlip;

