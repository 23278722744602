import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardText,
    MDBCardBody,
    MDBCardImage,
    MDBBtn,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBProgress,
    MDBProgressBar,
    MDBIcon,
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { ScaleLoader } from 'react-spinners';


export default function AdminUserProfile() {

    const { id } = useParams();


   

   

    const navigate = useNavigate();

    const handleVisitedit = (id) => {
        // Use navigate function to navigate to the '/profile' route
        navigate(`/admin/editemployee/${id}`);
    };


    // const [employeeData, setEmployeeData] = useState({});

    const [loading, setLoading] = useState(true);

    const [employeeData, setEmployeeData] = useState({
        epkemployee_id: '23',
        first_name: 'example name',
        dob: 'January 1, 1990',
        gender: 'Males',
        phone_no: '(097) 234-5678',
        whatsapp: '(098) 765-4321',
        email: 'example@example.com',
        address: 'Bay Area, San Francisco, CA',
        current_address: 'New York, NY',
        marital_status: 'Single',
        doj: 'January 1, 2020',
        spouse_name: 'Spouse Smith',
        department_name: 'IT Department',
        bank_accountnumber: '1234567890',
        bank_name: 'ABC Bank',
        bank_branch: 'Main Street Branch',
        ifsc_code: 'ABCD0123456',
        ac_type: 'Savings',
    });

    // console.log("employeeData values------------>:", employeeData.image);



    const horizontalRule = {
        margin: '0.6rem 0',
        color: 'inherit',
        border: '0',
        borderTop: 'var(--bs-border-width) solid',
        opacity: '0.25',
    };

    

    //   ============================

    // Retrieve userData from local storage
    const userData = JSON.parse(localStorage.getItem('userData'));

    
    useEffect(() => {
        // Fetch data from the API when the component mounts
        const fetchData = async () => {
            try {
                const userempid = userData.userempid; // Replace with your userempid
                const response = await axios.get(`https://officeinteriorschennai.com/api.php?viewemployeelist=${id}`);
                // Assuming the API response contains a data property with an array of employee data
                const { data } = response.data;
                // console.log("API Response", data)
                // Assuming the API response has only one employee data object, you can adjust accordingly
                if (data && data.length > 0) {
                    setEmployeeData(data[0]);



                    setLoading(false);

                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };

        fetchData();
    }, []);


    console.log("employeeData -->", employeeData)




    return (
        <section style={{ backgroundColor: '#eee' }}>




            <MDBContainer className="py-5">

                {loading ? (
                    <div style={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#f6f6f6'
                    }}>
                        <ScaleLoader color="#36d7b7" />
                    </div>
                ) : (
                    <MDBRow>
                        <MDBCol lg="4">
                            <MDBCard className="mb-4">
                                <MDBCardBody className="text-center">
                                    <MDBCardImage
                                        src={`https://officeinteriorschennai.com/${employeeData.image}`}
                                        alt="avatar"
                                        className="rounded-circle"
                                        style={{width: '400px', height:'20vh',objectFit:'contain' }}
                                        fluid
                                    />

                                    <h4 className="text-muted mb-1">{employeeData.first_name} {employeeData.last_name}</h4>
                                    <h5 className="text-muted mb-4">{employeeData.department_name}</h5>
                                    <div className="d-flex justify-content-center mb-2">
                                        {/* <MDBBtn>Follow</MDBBtn>
                  <MDBBtn outline className="ms-1">Message</MDBBtn> */}
                                    </div>
                                </MDBCardBody>
                            </MDBCard>


                        </MDBCol>



                        <MDBCol lg="8">

                            <MDBCard className="mb-4">
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol>
                                            <h3>Basic Details</h3>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Employee ID</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.epkemployee_id}</MDBCardText> {/* Replace with actual Employee ID */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Name</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.first_name}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Date of Birth</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.dob}</MDBCardText> {/* Replace with actual date of birth */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Gender</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.gender}</MDBCardText> {/* Replace with actual gender */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Phone Number</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.phone_no}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>WhatsApp</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.whatsapp}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Email</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.email}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>


                            <MDBCard className="mb-4">
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol>
                                            <h3>Address Details</h3>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Address</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.address}</MDBCardText> {/* Replace with actual address */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Current Address</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.current_address}</MDBCardText> {/* Replace with actual current address */}
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>

                            <MDBCard className="mb-4">
                                <MDBCardBody><MDBRow>
                                    <MDBCol>
                                        <h3>Personal Details</h3>
                                    </MDBCol>
                                </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Marital Status</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.marital_status}</MDBCardText> {/* Replace with actual marital status */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Date of Join</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.doj}</MDBCardText> {/* Replace with actual date of join */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Spouse Name</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.spouse_name}</MDBCardText> {/* Replace with actual spouse name */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Department</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.department_name}</MDBCardText> {/* Replace with actual department */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Pan Number</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.pan_number}</MDBCardText> {/* Replace with actual spouse name */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>UAN Number</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.uan_number}</MDBCardText> {/* Replace with actual spouse name */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Salary</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.emp_salary}</MDBCardText> {/* Replace with actual spouse name */}
                                        </MDBCol>
                                    </MDBRow>
                                    
                                    </MDBCardBody>
                            </MDBCard>

                            <MDBCard className="mb-4">
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol>
                                            <h3>Bank Account Details</h3>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Bank Account Number</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.bank_accountnumber}</MDBCardText> {/* Replace with actual account number */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Bank Name</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.bank_name}</MDBCardText> {/* Replace with actual bank name */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Bank Branch</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.bank_branch}</MDBCardText> {/* Replace with actual branch name */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>IFSC Code</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.ifsc_code}</MDBCardText> {/* Replace with actual IFSC code */}
                                        </MDBCol>
                                    </MDBRow>
                                    <hr style={horizontalRule} />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Account Type</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{employeeData.ac_type}</MDBCardText> {/* Replace with actual account type */}
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>



                            <button className="btn btn-primary" onClick={()=>handleVisitedit(id)}>
                                Edit
                            </button>


                        </MDBCol>
                    </MDBRow>
                )}
            </MDBContainer>


        </section>
    );
}

