import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';

const AddMeetingTable = () => {

    const userData = JSON.parse(localStorage.getItem('userData'));

    const myStyles = {
        color: 'white',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'
    };

    const myStyles1 = {
        color: 'black',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'
    };


    const navigate = useNavigate();

    const GoToEditPage = (id) => {
        navigate(`/admin/editmeeting/${id}`);
    };

    useEffect(() => {
        fetchTableData();
    }, []);

    // -------------------------------------

    const [tableData, setTableData] = useState([]);

    const fetchTableData = async () => {
        const apiUrl = `https://officeinteriorschennai.com/api.php?meetinglistempid=${userData.userempid}&&meetinglistroleid=${userData.userrole}`;
        try {
            const response = await axios.get(apiUrl);
            const data = response.data.data;
            setTableData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    //--------------------------------------


    const [modalData, setModalData] = useState([]);
    const [modalReasonHeading, setModalReasonHeading] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = async (item, status) => {
        setShow(true);
        try {
            const response = await axios.get(`https://officeinteriorschennai.com/api.php?meetingid=${item.id}&meetingstatus=${status}`);
            setModalData(response.data.data);
            setModalReasonHeading(status)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    //----------------------------------------------------

    const [conditionDisplay, setConditionDisplay] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://officeinteriorschennai.com/api.php?userrolenavigation`);
                setConditionDisplay(response.data.data);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchData();
    }, []);

    //----------------------------------------------------------

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [tempId, setTempID] = useState('');
    const [approvalType, setApprovalType] = useState('');
    const [reason, setReason] = useState('');

    const handleActionAcceptandReject = async () => {
        try {
            const formData = new FormData();

            formData.append('meeting_id', tempId);
            formData.append('meetingemp_id', userData.userempid);
            formData.append('meeting_status', approvalType);
            formData.append('meeting_remarks', reason);

            const response = await fetch('https://officeinteriorschennai.com/api.php?approvalmeetingrequest', {
                method: 'POST',
                body: formData,
            });

            const responseData = await response.json();

            if (responseData.status == 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: `Reason submitted Successfully..`,
                });
                toggleModal();
                fetchTableData();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Can't add Reason, try again`,
                });
            }
        } catch (error) {
            alert('Error in Submission');
        }
    };

    const [reasonShow, setReasonShow] = useState(false);

    const handleReasonClose = () => setReasonShow(false);

    const toggleModal = () => {
        setReasonShow(!reasonShow);
    };

    const handleReasonShow = (item, status) => {
        setReasonShow(!reasonShow);
        setApprovalType(status);
        setTempID(item.id)
    }

    const HandleReasonSubmit = () => {
        toggleModal();
        handleActionAcceptandReject()
    }

    //------------------------------------------------------------------


    const HandleDelete = async (item) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this Meeting?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`https://officeinteriorschennai.com/api.php?deletemeeting=${item.id}`, {
                    method: 'GET',
                });

                if (response.ok) {
                    Swal.fire(
                        'Deleted!',
                        'Your item has been deleted.',
                        'success'
                    );
                    fetchTableData()
                } else {
                    Swal.fire(
                        'Error!',
                        'Failed to delete data. Please try again.',
                        'error'
                    );
                }
            } catch (error) {
                console.error('Error:', error);
                Swal.fire(
                    'Error!',
                    'An error occurred. Please try again.',
                    'error'
                );
            }
        }
    };

    //---------------------------- Page Number ----------------------------------

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const componentRef = useRef();

    //---------------------------  Excel -------------------------------------


    const handleExportCSV = () => {
        const csvData = filteredMeetings.map((meeting, indexInPage) => {
            return {
                'S.no': calculateSNo(indexInPage),
                'Title': meeting.meeting_title,
                'Created by': meeting.hrname,
                'Teams': meeting.teamname,
                'Members': meeting.membername,
                'Date': meeting.meeting_date,
                'Start Time': meeting.start_time,
                'End Time': meeting.end_time,
                'Agenda': meeting.meeting_reason,
                'Remarks': meeting.meeting_remarks,

            };
        });

        const headers = [
            { label: 'S.No', key: 'S.no' },
            { label: 'Title', key: 'Title' },
            { label: 'Created by', key: 'Created by' },
            { label: 'Teams', key: 'Teams' },
            { label: 'Members', key: 'Members' },
            { label: 'Date', key: 'Date' },
            { label: 'Start Time', key: 'Start Time' },
            { label: 'End Time', key: 'End Time' },
            { label: 'Agenda', key: 'Agenda' },
            { label: 'Remarks', key: 'Remarks' },
        ];

        const csvReport = {
            data: csvData,
            headers: headers,
            filename: 'meeting_data.csv',
        };

        return <CSVLink {...csvReport}><i className="fas fa-file-csv" style={{ fontSize: '25px', color: '#212529' }}></i></CSVLink>;
    };


    //=========================== PDF ==========================

    const handleExportPDF = () => {
        const unit = 'pt';
        const size = 'A4';

        const doc = new jsPDF('landscape', unit, size);

        const data = filteredMeetings.map((meeting, indexInPage) => [
            calculateSNo(indexInPage),
            meeting.meeting_title,
            meeting.teamname,
            meeting.membername,
            meeting.meeting_date,
            meeting.start_time,
        ]);

        doc.autoTable({
            head: [
                ['S.No', 'Title', 'Teams', 'Members', 'Date', 'Start Time'],
            ],
            body: data,
        });

        doc.save('meeting_data.pdf');
    };

    //-------------------- Page --------------------------------------- 

    const filteredData = tableData.filter((row) =>
        Object.values(row).some(
            (value) =>
                (typeof value === 'string' || typeof value === 'number') &&
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const pageCount = Math.ceil(tableData.length / itemsPerPage);

    const filteredMeetings = filteredData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );


    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };


    const calculateSNo = (index) => {
        return currentPage * itemsPerPage + index + 1;
    };


    return (
        <div style={{ maxWidth: '100%', overflowX: 'auto', paddingTop: '7vh', padding:'80px 110px' }}>
            <h2 style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>Meeting List</h2>


            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', justifyContent: 'space-between' }}>
                <div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(0);
                        }}

                        style={myStyles1}
                    />
                </div>
                <div>
                    <button style={myStyles}>{handleExportCSV()}</button>
                    <button style={myStyles} onClick={handleExportPDF}><i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                </div>
            </div>


            <div style={{ width: '100%', overflowX: 'auto' }}>


                <table className="table table-auto table-dark table-striped" style={{ minWidth: '100%', width: 'max-content' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '100px' }}>S.No</th>
                            <th style={{ width: '150px' }}>Title</th>
                            <th style={{ width: '150px' }}>Created by</th>
                            <th style={{ width: '150px' }}>Team</th>
                            <th style={{ width: '150px' }}>Members</th>
                            <th style={{ width: '150px' }}>Date</th>
                            <th style={{ width: '150px' }}>Start Time</th>
                            <th style={{ width: '150px' }}>End Time</th>
                            <th style={{ width: '150px' }}>Agenda</th>
                            <th style={{ width: '150px' }}>Remarks</th>
                            {conditionDisplay.includes(userData.userrole) && (<th style={{ width: '150px' }}>Approved List</th>)}
                            {conditionDisplay.includes(userData.userrole) && (<th style={{ width: '150px' }}>Rejected List</th>)}
                            {!conditionDisplay.includes(userData.userrole) && (<th style={{ width: '150px' }}>Reason</th>)}
                            <th style={{ width: '250px' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            filteredMeetings.length === 0 ? (
                                <tr>
                                    <td colSpan="13" style={{ textAlign: 'center' }}>No search data found</td>
                                </tr>
                            ) : (

                                // =======================
                                filteredMeetings.map((meeting, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{meeting.meeting_title}</td>
                                        <td>{meeting.hrname}</td>
                                        <td>{meeting.teamname}</td>
                                        <td>{meeting.membername}</td>
                                        <td>{meeting.meeting_date}</td>
                                        <td>{meeting.start_time}</td>
                                        <td>{meeting.end_time}</td>
                                        <td>{meeting.meeting_reason}</td>
                                        <td>{meeting.meeting_remarks}</td>
                                        {conditionDisplay.includes(userData.userrole) && (<td onClick={() => handleShow(meeting, 'Approved')} style={{ cursor: 'pointer' }} >{meeting.approved_approval}</td>)}
                                        {conditionDisplay.includes(userData.userrole) && (<td onClick={() => handleShow(meeting, 'Rejected')} style={{ cursor: 'pointer' }} >{meeting.rejected_approval}</td>)}
                                        {!conditionDisplay.includes(userData.userrole) && (<td>{meeting.empreason}</td>)}

                                        {conditionDisplay.includes(userData.userrole) ? (
                                            <td>
                                                <button className="btn btn-info btn-sm mr-2"
                                                    style={{ width: '45%', backgroundColor: '#0d6efd', borderColor: '#0d6efd', color: 'white', marginRight: '10px' }}
                                                    onClick={() => { GoToEditPage(meeting.id) }}>Edit</button>
                                                <button className="btn btn-danger btn-sm" style={{ width: '45%' }}
                                                    onClick={() => { HandleDelete(meeting) }}>Delete</button>
                                            </td>
                                        ) :
                                            meeting.emp_status == null ? (
                                                <td>
                                                    <button className="btn btn-info btn-sm mr-2"
                                                        style={{ width: '40%', backgroundColor: '#0d6efd', borderColor: '#0d6efd', color: 'white', marginRight: '10px' }}
                                                        onClick={() => { handleReasonShow(meeting, 'Approved') }}>Approve</button>
                                                    <button className="btn btn-danger btn-sm" style={{ width: '40%' }}
                                                        onClick={() => { handleReasonShow(meeting, 'Rejected') }}>Reject</button>
                                                </td>) : (
                                                <td>{meeting.emp_status}</td>
                                            )
                                        }

                                    </tr>
                                ))

                                // =======================

                            )}
                    </tbody>
                </table>

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <ReactPaginate
                    previousLabel={<span aria-hidden="true">&laquo;</span>}
                    nextLabel={<span aria-hidden="true">&raquo;</span>}
                    breakLabel={<span>...</span>}
                    breakClassName={'page-item disabled'}
                    breakLinkClassName={'page-link'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    disabledClassName={'disabled'}
                    activeLinkClassName={'bg-dark text-white'}
                />
            </div>







            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalReasonHeading} Reasons</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{ width: '30%' }}>Name</th>
                                <th style={{ width: '70%' }}>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modalData.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.first_name}</td>
                                    <td>{data.remarks}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={reasonShow} onHide={handleReasonClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Selected Type: {approvalType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="reasonTextArea">
                        <Form.Label>Reason</Form.Label>
                        <Form.Control as="textarea" rows={4} onChange={(e) => setReason(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={HandleReasonSubmit}>
                        Submit
                    </Button>
                    <Button variant="secondary" onClick={handleReasonClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>



        </div>
    );
};

export default AddMeetingTable;
