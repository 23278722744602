import React, { useEffect, useState } from 'react';
import '../../assets/admin/css/Admindashboard.css';
import Ontime from '../../assets/admin/assets/img/ontime.png';
import employee from '../../assets/admin/assets/img/employee.png';
import permission from "../../assets/admin/assets/img/absent.png";
import slow from "../../assets/admin/assets/img/late.png";
import { FaBell } from 'react-icons/fa';
import { Modal, Button, Form, Table } from "react-bootstrap";

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function HRSupport() {
  const navigate = useNavigate();

  const handleVisitattendancetable = () => {
    navigate('/admin/attendancerequest');
  };

  const handleVisitleavetable = () => {
    navigate('/admin/leaverequest');
  };

  const handleVisitpermissiontable = () => {
    navigate('/admin/permission');
  };

  const handleVisithalfdaytable = () => {
    navigate('/admin/halfday');
  };

  const dataBoxStyle = {
    cursor: 'pointer',
  };

  const AttendancenotificationCount = 5;
  const LeavenotificationCount = 9;

  //============================================================================



  const [person, setPerson] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [reason, setReason] = useState('');


  const [isModalOpen, setIsModalOpen] = useState(false)


  const openModal = () => {
    setIsModalOpen(true);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://officeinteriorschennai.com/api.php?employeelist');
        setData([{ "id": "0", "first_name": "Select", "last_name": "Employee" }, ...response.data.data]);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);


  const NameArray = data.map(employee => ({
    id: employee.id,
    name: `${employee.first_name} ${employee.last_name}`,
  }));

  //==============================================================================


  const [typeArray, setTypeArray] = useState([{ "id": "0", "request_type_name": "Select Type" }]);


  useEffect(() => {
    const apiUrl = 'https://officeinteriorschennai.com/api.php?leaverequesttype';
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const data = response.data.data;
        setTypeArray([{ "id": "0", "request_type_name": "Select Type" }, ...data]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


  //=============================================================


  const [leaveCategoryArray, setLeaveCategoryArray] = useState([{ "cid": "0", "category_name": "Select Type" }]);

  useEffect(() => {
    const apiUrl = 'https://officeinteriorschennai.com/api.php?leaverequestcategory';
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const data = response.data.data;
        setLeaveCategoryArray([{ "cid": "0", "category_name": "Select Category Type" }, ...data]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


  //=============================================================

  const userData = JSON.parse(localStorage.getItem('userData'));

  const submitData = async () => {
    const formData = new FormData();

    const toTimeupdated = `${toTime}:00`;

    formData.append('id', person);
    formData.append('request_type', type);
    formData.append('request_category', category);
    formData.append('loginhr_id', userData.userempid);
    formData.append('leave_reason', reason);

    formData.append('from_date', fromDate);
    formData.append('to_date', toDate);
    formData.append('permission_date', fromDate);
    formData.append('permission_timefrom', fromTime);
    formData.append('permission_timeto', toTimeupdated);
    formData.append('proof', []);

    try {
      const response = await fetch('https://officeinteriorschennai.com/api.php?hraddattendance', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.status === 'success') {
        setPerson('');
        setCategory('');
        setType('');
        setFromDate('');
        setToDate('');
        setFromTime('');
        setToTime('');
        setReason('');
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: `Attendance Added Successfully..`,
        });
        setIsModalOpen(false);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Error in adding Attendance yes: ${data.message || 'Unknown error yes'}`,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error in adding Attendance no: ${error.message || 'no Unknown error'}`,
      });
    }
  }


  const handleSubmitattendance = (e) => {
    e.preventDefault();

    if (!person || !type || !category || !fromDate || (!toDate && type === 1) || (!fromTime && (type === 2 || type === 3)) || (!toTime && (type === 2 || type === 3)) || !reason) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Fill all details`,
      });
      return;
    }

    submitData();
  };

  // ======================================



  const userrole = userData?.userrole || '';

  const [conditionDisplay, setConditionDisplay] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://officeinteriorschennai.com/api.php?userrolenavigation');
        setConditionDisplay(response.data.data);
        // console.log("setConditionDisplayAdmin --------------->", response.data.data)
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  // ========================

  const userempid = userData?.userempid || '';
  // const username = userData?.username || '';
  // const useremail = userData?.useremail || '';

  const [formData, setFormData] = useState({
    emp_id: userempid,
    request_date: "",
    request_fromtime: "",
    request_totime: "",

  });



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const [isModalOpenattendance, setIsModalOpenattendance] = useState(false);

  const handleApplyClick = () => {
    setIsModalOpenattendance(true);
  };

  const handleCloseModalattendance = () => {
    setIsModalOpenattendance(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !person ||
      !formData.request_date ||
      !formData.request_fromtime
      // !formData.request_totime

    ) {
      console.error("All fields are required");
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill in all the required fields.',
      });
      return;
    }


    // console.log(formData)


    // Set default value "00:00:00" for request_totime if not selected
  const request_totimeUpdated = formData.request_totime ? `${formData.request_totime}:00` : '00:00:00';


    const formDataToSend = new FormData();

    // const request_totimeupdated = `${formData.request_totime}:00`;


    formDataToSend.append('e_id', person);
    formDataToSend.append('hr_id', formData.emp_id);
    formDataToSend.append('request_date', formData.request_date);
    formDataToSend.append('request_fromtime', formData.request_fromtime);

    formDataToSend.append('request_totime', request_totimeUpdated);



    try {

      const response = await fetch('https://officeinteriorschennai.com/api.php?addhrattendance', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        // console.log("Form submitted successfully karthik testing");
        setIsModalOpen(false);
        setFormData({
          ...formData,

          request_date: "",
          request_fromtime: "",
          request_totime: ""

        });

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Form submitted successfully',
        });
      } else {
        console.error("Failed to submit form");

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to submit form',
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error submitting form. Please try again later.',
      });
    }
  };





  return (
    <div>


      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10%', paddingLeft: '10%', paddingTop: '5%', marginBottom: '40px' }}>

        <h1 >HelpDesk</h1>
        <>
          {conditionDisplay.includes(userrole) ?
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <Button onClick={openModal}>
                Add Leave / Permission /Half day
              </Button>

              <Button onClick={handleApplyClick}>
                Add Attendance
              </Button>
            </div>


            : null}
        </>
      </div>


      {/* =========================== */}
      <Modal show={isModalOpenattendance} onHide={handleCloseModalattendance}>
        <Modal.Header closeButton>
          <Modal.Title>Add Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>

            <Form.Group controlId="empName" className='mb-2'>
              <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Select Member:</Form.Label>
              <Form.Control
                as="select"
                name="empName"
                value={person}
                onChange={(e) => setPerson(e.target.value)}
              >
                {NameArray.map((option) => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </Form.Control>
            </Form.Group>




            <Form.Group controlId="request_date">
              <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Date</Form.Label>
              <Form.Control
                type="date"
                name="request_date"
                value={formData.request_date}
                onChange={handleInputChange}
              />
            </Form.Group>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Group controlId="request_fromtime" style={{ width: '48%' }}>
                <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Time From</Form.Label>
                <Form.Control
                  type="time"
                  name="request_fromtime"
                  value={formData.request_fromtime}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="request_totime" style={{ width: '48%' }}>
                <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Time To</Form.Label>
                <Form.Control
                  type="time"
                  name="request_totime"
                  value={formData.request_totime}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>


            <div style={{ paddingTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="secondary" onClick={handleCloseModalattendance}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ marginLeft: '10px' }}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* =========================== */}



      <div className='Hrsupport-dashboard'>
        <div className='data-box' onClick={handleVisitattendancetable} style={dataBoxStyle}>
          <div className='box-content'>
            <span>
              <span className='img-alignment'>
                <img src={Ontime} alt='employee' className='dash-img' />
              </span>
              <h4 className='dash-title'>Attendance Request</h4>
              <h4 className='dash-values'></h4>
            </span>
          </div>
        </div>

        <div className='data-box' onClick={handleVisitleavetable} style={dataBoxStyle}>
          <div className='box-content'>
            <span>
              <span className='img-alignment'>
                <img src={employee} alt='Ontime' className='dash-img' />
              </span>
              <h4 className='dash-title'>Leave Request</h4>
              <h4 className='dash-values'></h4>
            </span>
          </div>
        </div>


        <div className='data-box' onClick={handleVisitpermissiontable} style={dataBoxStyle}>
          <div className='box-content'>
            <span>
              <span className='img-alignment'>
                <img src={permission} alt='permission' className='dash-img' />
              </span>
              <h4 className='dash-title'>Permission Request</h4>
              <h4 className='dash-values'></h4>
            </span>
          </div>
        </div>

        <div className='data-box' onClick={handleVisithalfdaytable} style={dataBoxStyle}>
          <div className='box-content'>
            <span>
              <span className='img-alignment'>
                <img src={slow} alt='permission' className='dash-img' />
              </span>
              <h4 className='dash-title'>Half Day Request</h4>
              <h4 className='dash-values'></h4>
            </span>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Leave / Permission / halfDay</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form onSubmit={handleSubmitattendance}>

            <Form.Group controlId="empName" className='mb-2'>
              <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Select Member:</Form.Label>
              <Form.Control
                as="select"
                name="empName"
                value={person}
                onChange={(e) => setPerson(e.target.value)}
              >
                {NameArray.map((option) => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </Form.Control>
            </Form.Group>


            <Form.Group controlId="selectType" className='mb-2'>
              <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Select Type:</Form.Label>
              <Form.Control
                as="select"
                name="selectType"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {typeArray.map((option) => (
                  <option key={option.id} value={option.id}>{option.request_type_name}</option>
                ))}
              </Form.Control>
            </Form.Group>


            <Form.Group controlId="selectCategory" className='mb-2'>
              <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Select Category:</Form.Label>
              <Form.Control
                as="select"
                name="selectCategory"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {leaveCategoryArray.map((option) => (
                  <option key={option.cid} value={option.cid}>{option.category_name}</option>
                ))}
              </Form.Control>
            </Form.Group>


            {type == 2 || type == 3 ?
              <div className='mb-2'>
                <div style={{ marginRight: "10px", width: "100%" }}>
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>


                <div className='mb-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Form.Group controlId="request_fromtime" style={{ width: '48%' }}>
                    <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>From Time</Form.Label>
                    <Form.Control
                      type="time"
                      name="request_fromtime"
                      value={fromTime}
                      onChange={(e) => setFromTime(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="request_totime" style={{ width: '48%' }}>
                    <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>To Time</Form.Label>
                    <Form.Control
                      type="time"
                      name="request_totime"
                      value={toTime}
                      onChange={(e) => setToTime(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div> : null}


            {type == 1 || type == 4?
              <div className='mb-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>From Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>

                <div style={{ marginRight: "10px", width: "50%" }}>
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>To Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
              </div>
              : null}

            <Form.Group controlId="reason" className='mb-2'>
              <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Reason:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Group>

            <div style={{ paddingTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" style={{ marginLeft: '10px' }}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>


    </div>
  );
}