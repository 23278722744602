import React, { useState } from 'react';
import axios from 'axios';
import LoginLogo from '../../../assets/admin/assets/img/EPK_group_Logo.png';
import '../../../assets/admin/css/Login.css';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        c_password: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://127.0.0.1:8000/api/register', formData);
            // console.log(response.data);
            // console.log("Registration successfully Registered ------------------->");

            // Clear the form fields
            setFormData({
                name: '',
                email: '',
                password: '',
                c_password: '',
            });


            // Show success popup using SweetAlert
            Swal.fire({
                icon: 'success',
                title: 'Register',
                text: 'Register successful!',
                timer: 2000, // Specify the duration in milliseconds
                showConfirmButton: false, // Hide the "OK" button
            });
        } catch (error) {
            if (error.response.status === 422) {
                console.error('Validation errors:', error.response.data.errors);
                // Show error popup using SweetAlert
                Swal.fire({
                    icon: 'error',
                    title: 'Validation errors',
                    // text: 'Login error',
                });
            } else {
                console.error('Registration error:', error.response.data);
                // Show error popup using SweetAlert
                Swal.fire({
                    icon: 'error',
                    title: 'Registration error',
                    text: 'Email Id Already exists',
                });
            }
        }
    };

    return (
        <div className="full-body">
            <div className="login-container">
                <div className="screen_Register">
                    <div className="screen__content">


                        {/* ========================================= */}

                        <form onSubmit={handleSubmit} style={{ width: '320px', padding: '30px', paddingTop: '156px' }}>
                            <img src={LoginLogo} alt='' style={{ width: '100%', height: '92px' }} />

                            <div className="login__field">
                                <i className="login__icon fas fa-user"></i>
                                <input type="text" name="name" value={formData.name} onChange={handleChange} className="login__input" placeholder="name" />
                            </div>

                            <div className="login__field">
                                <i className="login__icon fas fa-user"></i>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} className="login__input" placeholder="email" />
                            </div>

                            <div className="login__field">
                                <i className="login__icon fas fa-lock"></i>
                                <input type="password" name="password" value={formData.password} onChange={handleChange} className="login__input" placeholder="Password" />

                            </div>
                            <div className="login__field">
                                <i className="login__icon fas fa-lock"></i>
                                <input
                                    type="password"
                                    name="c_password"
                                    value={formData.c_password}
                                    onChange={handleChange}
                                    className="login__input" placeholder="Confirm password" />


                            </div>

                            <button className="button login__submit" type="submit">
                                <span className="button__text">Register</span>
                                <i className="button__icon fas fa-chevron-right"></i>
                            </button>


                        </form>


                        {/* ================================================ */}





                    </div>
                    <div className="screen__background">
                        <span className="screen__background__shape screen__background__shape4"></span>
                        <span className="screen__background__shape screen__background__shape3"></span>
                        <span className="screen__background__shape screen__background__shape2"></span>
                        <span className="screen__background__shape screen__background__shape1"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
