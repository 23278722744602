import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import MasterLayout from "./layouts/admin/MasterLayout";
// import Login from "./components/frontend/auth/LoginLaravel";
import Register from "./components/frontend/auth/Register";
import Loginuser from "./components/frontend/auth/Login";

function App() {
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Redirect to login if not authenticated */}
          <Route path="/" element={isAuthenticated ? <Navigate to="/admin" /> : <Navigate to="/login" />} />
          <Route path="/register" element={<Register />} />
          {/* <Route path="/LoginLaravel" element={<Login />} /> */}
          <Route path="/login" element={<Loginuser />} />

          {/* Protected admin route */}
          <Route path="/admin/*" element={<MasterLayout />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
