import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    //Login Credentials 

    const [userimage, setUserimage] = useState('');
    const [username, setUsername] = useState('');
    const [usermail, setUsermail] = useState('');
    const [userepkempid, setUserepkempid] = useState('EPK001');
    const [usermobile, setUsermobile] = useState('');
    const [userrole, setUserrole] = useState(1);
    const [userdepartment, setUserdepartment] = useState('');
    const [userempid, setUserempid] = useState(1);
    const [isloggedin, setIsLoggedIn] = useState(null);

    const [loggedInTime, setLoggedInTime] = useState(null);
    const [loggedOutTime, setLoggedOutTime] = useState(null);
    const [totalHours, setTotalHours] = useState(null);
    const [userdepartmentname, setUserdepartmentname] = useState(null);
    const [useralreadyLoggedIn, setUseralreadyLoggedIn] = useState(false);

    const contextValue = {
        username, userepkempid, usermobile, userrole, userdepartment, userempid,
        setUsername, setUserepkempid, setUsermobile, setUserrole, setUserdepartment, setUserempid,
        isloggedin, setIsLoggedIn, loggedInTime, setLoggedInTime, loggedOutTime, setLoggedOutTime,
        totalHours, setTotalHours, userimage, setUserimage, usermail, setUsermail,
        userdepartmentname, setUserdepartmentname, useralreadyLoggedIn, setUseralreadyLoggedIn
    };

    // =======================================================================
    
  
     // =======================================================================

    return (
        <AuthContext.Provider value={{contextValue }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export { AuthProvider, useAuth };
