import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../assets/admin/css/Sidebar.css';
import { Alert } from 'react-bootstrap';

export default function Sidebar() {
    const [conditionDisplayAdmin, setConditionDisplayAdmin] = useState('');
    const [employeeCollapseOpen, setEmployeeCollapseOpen] = useState(false);
    const [attendanceCollapseOpen, setAttendanceCollapseOpen] = useState(false);
    const [helpDeskCollapseOpen, setHelpDeskCollapseOpen] = useState(false);
    const [payrollCollapseOpen, setPayrollCollapseOpen] = useState(false);
    const [eventCollapseOpen, setEventCollapseOpen] = useState(false);
    const [meetingCollapseOpen, setMeetingCollapseOpen] = useState(false);
    const [hollidayCollapseOpen, setHollidayCollapseOpen] = useState(false);




    // =======================================================
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?userrolenavigation');
                setConditionDisplayAdmin(response.data.data);
                // console.log("setConditionDisplayAdmin --------------->", response.data.data);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchData();
    }, []);

    // =======================================================

    const [conditionDisplayHead, setConditionDisplayHead] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?userrolemeeting');
                setConditionDisplayHead(response.data.data);
                // console.log("setConditionDisplayHead --------------->", response.data.data);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchData();
    }, []);

    // console.log("conditionDisplayHead ===============================>", conditionDisplayHead)

    // =======================================================

    // for accounts

    const [conditionDisplayAccounts, setConditionDisplayAccounts] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?accountsrole');
                setConditionDisplayAccounts(response.data.data);
                // console.log("setConditionDisplayHead --------------->", response.data.data);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchData();
    }, []);

    // ==========================================================================


    // for teamleadaccess

    const [conditionDisplayTeamleadaccess, setConditionDisplayTeamleadaccess] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?teamleadaccess');
                // Assuming the data is always in the expected format and non-empty
                const supervisorsString = response.data.data[0].all_supervisors;
                const supervisorsArray = supervisorsString.split(','); 
                setConditionDisplayTeamleadaccess(supervisorsArray);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchData();
    }, []);
    
    

    // console.log("setConditionDisplayTeamleadaccess", conditionDisplayTeamleadaccess)

    //    =============================================================================



    const handleCollapseToggle = (collapseState, setCollapseState) => {
        setCollapseState(!collapseState);
    };

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userrole = userData?.userrole || '';
    const userempid = userData?.userempid || '';
    const userdepartment = userData?.userdepartment || '';
    const usersupervisor = userData?.usersupervisor || '';

    // console.log('userdepartment error --->', userdepartment)


    const location = useLocation(); // Use useLocation hook

    return (
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    <div className="sb-sidenav-menu-heading">Core</div>

                    {/* ============================================================================ */}

                    {/* Dashboard */}
                    <Link className={`nav-link ${location.pathname === '/admin/dashboard' ? 'active' : ''}`} to="/admin/dashboard">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        Dashboard
                    </Link>


                    {/* ============================================================================ */}



                    {/* ============================================================================ */}

                    {/* Employee */}
                    {conditionDisplayAdmin.includes(userrole) &&
                        <>
                            <Link className="nav-link collapsed" to="#" onClick={() => handleCollapseToggle(employeeCollapseOpen, setEmployeeCollapseOpen)} aria-expanded={employeeCollapseOpen ? "true" : "false"} aria-controls="employee">
                                <div className="sb-nav-link-icon"><i className="fas fa-user-tie"></i></div>
                                Employee
                                <div className={`sb-sidenav-collapse-arrow ${employeeCollapseOpen ? 'sb-sidenav-collapse-arrow' : 'down'}`}><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className={`collapse ${employeeCollapseOpen ? "show" : ""}`} id="employee" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location.pathname === '/admin/addemployee' ? 'active' : ''}`} to="/admin/addemployee">Add Employee</Link>
                                    <Link className={`nav-link ${location.pathname === '/admin/employeedetails' ? 'active' : ''}`} to="/admin/employeedetails">Employee Details</Link>
                                </nav>
                            </div>
                        </>
                    }

                    {/* ============================================================================ */}



                    {/* ============================================================================ */}

                    {/* Attendance */}
                    <Link className="nav-link collapsed" to="#" onClick={() => handleCollapseToggle(attendanceCollapseOpen, setAttendanceCollapseOpen)} aria-expanded={attendanceCollapseOpen ? "true" : "false"} aria-controls="attendance">
                        <div className="sb-nav-link-icon"><i className="fas fa-calendar-check"></i></div>
                        Attendance
                        <div className={`sb-sidenav-collapse-arrow ${attendanceCollapseOpen ? 'sb-sidenav-collapse-arrow' : 'down'}`}><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className={`collapse ${attendanceCollapseOpen ? "show" : ""}`} id="attendance" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link className={`nav-link ${location.pathname === '/admin/dailyattendance' ? 'active' : ''}`} to="/admin/dailyattendance">Daily Attendance</Link>
                            <Link className={`nav-link ${location.pathname === '/admin/monthlyattendance' ? 'active' : ''}`} to="/admin/monthlyattendance">Monthly Attendance</Link>

                            {!conditionDisplayAdmin.includes(userrole) &&
                                <Link className={`nav-link ${location.pathname === '/admin/attendance' ? 'active' : ''}`} to="/admin/attendance">Monthly Attendance calendar</Link>
                            }
                        </nav>
                    </div>

                    {/* ============================================================================ */}



                    {/* ============================================================================ */}

                    {/* Help Desk */}
                    <Link className={`nav-link ${location.pathname === '/admin/hrsupport' ? 'active' : ''}`} to="/admin/hrsupport">
                        <div className="sb-nav-link-icon"><i className="fas fa-life-ring"></i></div>
                        HR Support
                    </Link>

                    {/* ============================================================================ */}

                    {/* Team leads */}


                    {conditionDisplayTeamleadaccess.includes(userempid) ?
                        <Link className={`nav-link ${location.pathname === '/admin/teamleads' ? 'active' : ''}`} to="/admin/teamleads">
                            <div className="sb-nav-link-icon"><i className="fas fa-life-ring"></i></div>
                            TL Approval
                        </Link>
                        : null}




                    {/* ============================================================================ */}



                    {/* accounts */}
                    {conditionDisplayAccounts.includes(userrole) ?
                        <Link className={`nav-link ${location.pathname === '/admin/yearlyattendance' ? 'active' : ''}`} to="/admin/yearlyattendance">
                            <div className="sb-nav-link-icon"><i className="fas fa-money-bill-wave"></i></div>
                            Accounts
                        </Link>
                        : null}



                    {/* ============================================================================ */}

                    {/* ============================================================================ */}

                    {/* Payroll */}
                    {conditionDisplayAdmin.includes(userrole) ?
                        <>
                            <Link className="nav-link collapsed" to="#" onClick={() => handleCollapseToggle(payrollCollapseOpen, setPayrollCollapseOpen)} aria-expanded={payrollCollapseOpen ? "true" : "false"} aria-controls="Payroll">
                                <div className="sb-nav-link-icon"><i className="fas fa-user-tie"></i></div>
                                Payroll
                                <div className={`sb-sidenav-collapse-arrow ${payrollCollapseOpen ? 'sb-sidenav-collapse-arrow' : 'down'}`}><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className={`collapse ${payrollCollapseOpen ? "show" : ""}`} id="Payroll" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location.pathname === '/admin/payslipgenerate' ? 'active' : ''}`} to="/admin/payslipgenerate">
                                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                        Generate Payslip
                                    </Link>
                                    <Link className={`nav-link ${location.pathname === '/admin/payrollemployee' ? 'active' : ''}`} to="/admin/payrollemployee">
                                        <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                                        Pay Roll
                                    </Link>
                                </nav>
                            </div>
                        </>
                        :
                        <>
                            <Link className="nav-link collapsed" to="#" onClick={() => handleCollapseToggle(payrollCollapseOpen, setPayrollCollapseOpen)} aria-expanded={payrollCollapseOpen ? "true" : "false"} aria-controls="Payroll">
                                <div className="sb-nav-link-icon"><i className="fas fa-user-tie"></i></div>
                                Payroll
                                <div className={`sb-sidenav-collapse-arrow ${payrollCollapseOpen ? 'sb-sidenav-collapse-arrow' : 'down'}`}><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className={`collapse ${payrollCollapseOpen ? "show" : ""}`} id="Payroll" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link className="nav-link" to={`/admin/payslip/${userempid}`}>
                                        <div className="sb-nav-link-icon"><i className="fas fa-file-invoice-dollar"></i></div>
                                        Pay Roll
                                    </Link>
                                </nav>
                            </div>
                        </>
                    }

                    {/* ============================================================================ */}


                    {/* ============================================================================ */}

                    {/* Event */}

                    <>
                        <Link className="nav-link collapsed" to="#" onClick={() => handleCollapseToggle(eventCollapseOpen, setEventCollapseOpen)} aria-expanded={eventCollapseOpen ? "true" : "false"} aria-controls="event">
                            <div className="sb-nav-link-icon"><i className="fas fa-user-tie"></i></div>
                            Event
                            <div className={`sb-sidenav-collapse-arrow ${eventCollapseOpen ? 'sb-sidenav-collapse-arrow' : 'down'}`}><i className="fas fa-angle-down"></i></div>
                        </Link>
                        <div className={`collapse ${eventCollapseOpen ? "show" : ""}`} id="event" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                            <nav className="sb-sidenav-menu-nested nav">

                                {conditionDisplayAdmin.includes(userrole) &&
                                    <Link className={`nav-link ${location.pathname === '/admin/addevent' ? 'active' : ''}`} to="/admin/addevent">
                                        <div className="sb-nav-link-icon"><i className="far fa-calendar-plus"></i></div>
                                        Add Event
                                    </Link>

                                }
                                <Link className={`nav-link ${location.pathname === '/admin/addeventtable' ? 'active' : ''}`} to="/admin/addeventtable">
                                    <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                                    View Event
                                </Link>
                            </nav>
                        </div>
                    </>


                    {/* ============================================================================ */}


                    {/* ============================================================================ */}

                    {/* Add Meeting */}

                    <Link className="nav-link collapsed" to="#" onClick={() => handleCollapseToggle(meetingCollapseOpen, setMeetingCollapseOpen)} aria-expanded={meetingCollapseOpen ? "true" : "false"} aria-controls="meeting">
                        <div className="sb-nav-link-icon"><i className="fas fa-user-tie"></i></div>
                        Meeting
                        <div className={`sb-sidenav-collapse-arrow ${meetingCollapseOpen ? 'sb-sidenav-collapse-arrow' : 'down'}`}><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className={`collapse ${meetingCollapseOpen ? "show" : ""}`} id="event" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">

                            {conditionDisplayHead.includes(userdepartment) &&

                                <Link className={`nav-link ${location.pathname === '/admin/addmeeting' ? 'active' : ''}`} to="/admin/addmeeting">
                                    <div className="sb-nav-link-icon"><i className="fas fa-smile"></i> </div>
                                    Add Meeting
                                </Link>
                            }
                            <Link className={`nav-link ${location.pathname === '/admin/addmeetingtable' ? 'active' : ''}`} to="/admin/addmeetingtable">
                                <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                                View Meeting
                            </Link>
                        </nav>
                    </div>

                    {/* ============================================================================ */}


                    {/* ============================================================================ */}

                    {/* Holiday List */}
                    {/* <Link className={`nav-link ${location.pathname === '/admin/holidaylist' ? 'active' : ''}`} to="/admin/holidaylist">
                        <div className="sb-nav-link-icon"><i className="fas fa-calendar-alt"></i></div>
                        Holiday List
                    </Link> */}



                    <Link className="nav-link collapsed" to="#" onClick={() => handleCollapseToggle(hollidayCollapseOpen, setHollidayCollapseOpen)} aria-expanded={hollidayCollapseOpen ? "true" : "false"} aria-controls="holliday">
                        <div className="sb-nav-link-icon"><i className="fas fa-user-tie"></i></div>
                        Holiday
                        <div className={`sb-sidenav-collapse-arrow ${hollidayCollapseOpen ? 'sb-sidenav-collapse-arrow' : 'down'}`}><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className={`collapse ${hollidayCollapseOpen ? "show" : ""}`} id="event" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link className={`nav-link ${location.pathname === '/admin/holidaylist' ? 'active' : ''}`} to="/admin/holidaylist">
                                <div className="sb-nav-link-icon"><i className="fas fa-calendar-alt"></i></div>
                                Holiday List
                            </Link>
                            {/* <Link className={`nav-link ${location.pathname === '/admin/declaredholliday' ? 'active' : ''}`} to="/admin/declaredholliday">
                                <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                                Declared Holiday
                            </Link> */}
                        </nav>
                    </div>

                    {/* ============================================================================ */}
                </div>
            </div>
        </nav>
    )
}
