import React from 'react';
import { Route, Routes, } from 'react-router-dom';
import '../../assets/admin/css/styles.css';
import '../../assets/admin/js/scripts.js'; // Make sure to specify the file extension, like 'scripts.js'
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
// import routes from '../../routes/routes';
import Dashboard from '../../components/admin/Dashboard';
import Profile from '../../components/admin/Profile';
import Empattendance from '../../components/admin/Empattendance';
import EmployeeDetails from '../../components/admin/EmployeeDetails';


import AddEmployeeForm from '../../components/admin/Add_Employee';
import EditEmployeeForm from '../../components/admin/Edit_Employee';
import DailyAttendance from '../../components/admin/DailyAttendance';
import MonthlyAttendance from '../../components/admin/MonthlyAttendance';
import Settings from '../../components/admin/Settings';
import PaySlip from '../../components/admin/PaySlip';
import PaySlipEmp from '../../components/admin/PaySlipEmp';

import HRSupport from '../../components/admin/HRSupport';
import AttendanceRequest from '../../components/admin/AttendanceRequest';
import LeaveRequest from '../../components/admin/LeaveRequest';
import PermissionRequest from '../../components/admin/PermissionRequest';
import PaySlipEmp1 from '../../components/admin/PaySlipEmp1';
import Notification from '../../components/admin/Notification';
import NotificationPage from '../../components/admin/NotificationPage';
import PaySlipEmp2 from '../../components/admin/PaySlipEmp2';
import AddEvent from '../../components/admin/AddEvent';
import AddMeeting from '../../components/admin/AddMeeting';
import HolidayList from '../../components/admin/LeaveList';
import UserProfile from '../../components/admin/UserProfile';
import RegisterUser from '../../components/admin/RegisterUser';
// import LoginUser from '../../components/admin/LoginUser';
import TestApi from '../../components/admin/TestApi';
import AdminUserProfile from '../../components/admin/AdminUserProfile';
import MonthlyAttendanceprevious from '../../components/admin/MonthlyAttendanceprevious';
import Empattendanceadmin from '../../components/admin/Empattendanceadmin';
import Attendanceadmin from '../../components/admin/Monthlyattendanceadmin';
import PayrollEmployee from '../../components/admin/PayrolleEmployee';
import Halfdayrequest from '../../components/admin/Halfdayrequest';
import EditMeeting from '../../components/admin/EditMeeting';
import EditEvent from '../../components/admin/EditEvent';
import DashboardViewAttendance from '../../components/admin/DashboardViewAttendance';
import GeneratePaySlip from '../../components/admin/GeneratePaySlip.jsx';
import AddEventTable from '../../components/admin/AddEventTable';
import AddMeetingTable from '../../components/admin/AddMeetingTable';
import DeclaredHolliday from '../../components/admin/Declaredholliday';
import YearlyAttendance from '../../components/admin/YearlyAttendance';
import TeamLeads from '../../components/admin/TeamLeads';
import LeaverequestTL from '../../components/admin/LeaveRequestTL';
import PermissionRequestTL from '../../components/admin/PermissionRequestTL';
import HalfdayrequestTL from '../../components/admin/HalfdayrequestTL';





export default function MasterLayout() {
    return (
        
        <div className='sb-nav-fixed'>
            <Navbar />
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <Sidebar />
                </div>
                <div id="layoutSidenav_content">
                    <main>

                    

                        <Routes>
                            {/* Set Dashboard as the default page */}
                            <Route path="/" element={<Dashboard />} />

                            {/* Explicit routes */}




                            <Route path="/employeedetails" element={<EmployeeDetails />} />
                            <Route path="/userprofile" element={<UserProfile />} />

                            <Route path="/user_profile/:id" element={<AdminUserProfile />} />



                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/attendance" element={<Empattendance />} />
                            <Route path="/attendance_emps/:id" element={<Empattendanceadmin />} />
                            <Route path="/attendance_emp" element={<Attendanceadmin />} />

                            <Route path="/employeedetails" element={<EmployeeDetails />} />
                            <Route path="/addemployee" element={<AddEmployeeForm />} />
                            <Route path="/editemployee/:id" element={<EditEmployeeForm />} />

                            <Route path="/dailyattendance" element={<DailyAttendance />} />
                            <Route path="/monthlyattendance" element={<MonthlyAttendance />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/payslip/:empId" element={<PaySlip />} />
                            <Route path="/payslipemp/:pid" element={<PaySlipEmp />} />

                            <Route path="/payslipgenerate" element={<GeneratePaySlip />} />

                            <Route path="/attendancerequest" element={<AttendanceRequest />} />
                            <Route path="/hrsupport" element={<HRSupport />} />
                            <Route path="/leaverequest" element={<LeaveRequest />} />
                            <Route path="/permission" element={<PermissionRequest />} />
                            <Route path="/halfday" element={<Halfdayrequest />} />
                            <Route path="/payslipemp1" element={<PaySlipEmp1 />} />
                            <Route path="/notification" element={<Notification />} />
                            <Route path="/notificationpage" element={<NotificationPage />} />
                            <Route path="/PaySlipEmp2" element={<PaySlipEmp2 />} />
                            <Route path="/addevent" element={<AddEvent />} />
                            <Route path="/addmeeting" element={<AddMeeting />} />
                            <Route path="/holidaylist" element={<HolidayList />} />
                            
                            <Route path="/registeruser" element={<RegisterUser />} />
                            {/* <Route path="/loginuser" element={<LoginUser />} /> */}
                            <Route path="/testApi" element={<TestApi />} />
                            <Route path="/monthlyattendanceprevious" element={<MonthlyAttendanceprevious />} />
                            <Route path="/payrollemployee" element={<PayrollEmployee />} />

                            <Route path="/editmeeting/:id" element={<EditMeeting />} />
                            <Route path="/editevent/:id" element={<EditEvent />} />
                            <Route path="/dashboardviewattendance/:aId" element={<DashboardViewAttendance />} />
                            <Route path="/addeventtable" element={<AddEventTable />} />
                            <Route path="/addmeetingtable" element={<AddMeetingTable />} />
                            <Route path="/declaredholliday" element={<DeclaredHolliday />} />
                            <Route path="/yearlyattendance" element={<YearlyAttendance />} />
                            <Route path="/teamleads" element={<TeamLeads />} />
                            <Route path="/leaverequesttl" element={<LeaverequestTL />} />
                            <Route path="/permissionrequesttl" element={<PermissionRequestTL />} />
                            <Route path="/halfdayrequestTL" element={<HalfdayrequestTL />} />
                            
                            
                        </Routes>


                    </main>
                    <Footer />
                </div>
            </div>
        </div>
   
    );
}
