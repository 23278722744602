import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../frontend/auth/Authentication';

export default function TestApi() {
    const [user, setUser] = useState([]);

    // useEffect(() => {
    //     fetch('https://jsonplaceholder.typicode.com/users')
    //         .then(res => res.json())
    //         .then(res => setUser(res));
    // }, []); // Provide an empty array as the second argument to useEffect

    useEffect(()=>{
        axios.get('https://jsonplaceholder.typicode.com/users')
        .then(res=>setUser(res.data))
    },[]
        
    )




  

    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/users')
            .then(response => response.json())
            .then(data => setUsers(data))
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    
    // Retrieve userData from local storage
const userData = JSON.parse(localStorage.getItem('userData'));

// Now you can access userData properties
// console.log(userData.username);
// console.log(userData.userepkempid);
// Access other properties as needed

    return (
        <div>
            {user.map(u => (
                <div key={u.id}>
                    {u.name}
                </div>
            ))}

            <h1>hello Mr{userData.userepkempid}</h1>
        </div>
    );
}
