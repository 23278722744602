import React, { useState } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import FullCalendar from "@fullcalendar/react";
import { UserContext } from "./Context";
import { useContext } from "react";
import { ScaleLoader } from 'react-spinners';




function Attendance({setCenterDate, centerDate, calendarRef, handleDatesSet, formattedCenterDate, events}) {

  // const { events, loading } = useContext(UserContext);

  // console.log("events -=-=-=-=-=-=--=>", props.events)

  // const events = props.events


  // console.log('intime --->', props.events.Intime)


  return (
    <>


      {/* {loading ? (
        <div style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#f6f6f6'
        }}>
          <ScaleLoader color="#36d7b7" />


        </div>
      ) : ( */}
      <div>

        <FullCalendar
          id="calendar"
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            start: "today prev,next",
            center: `title`,
            end: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          height={"90vh"}
          events={events}
          // initialDate={"2023-11-01"}
          views={{
            dayGridMonth: { buttonText: "Month" },
            timeGridWeek: { buttonText: "Week" },
            timeGridDay: { buttonText: "Day" },
          }}
          eventContent={renderEventContent}
          datesSet={handleDatesSet}
        />
      </div>
      {/* )} */}
    </>
  );
}

function renderEventContent(eventInfo) {
  return (
    <div style={{ background: getEventColor(eventInfo.event), fontSize: '17px', fontWeight: 'bold' }}>
      <div>{eventInfo.event.title}</div>


      {eventInfo.event.extendedProps.late && (
        <div style={{ backgroundColor: '#FF5722' }}>Late: <AiOutlineCheck style={{ marginLeft: '5px', color: 'green' }} /></div>
      )}
      {eventInfo.event.extendedProps.permission && (
        <div style={{ backgroundColor: 'grey' }}>Permission:  <AiOutlineCheck style={{ marginLeft: '5px', color: 'green' }} /></div>
      )}
      {eventInfo.event.extendedProps.HalfDay && (
        <div style={{ backgroundColor: '#0d6efd' }}>Half Day: <AiOutlineCheck style={{ marginLeft: '5px', color: 'black' }} /></div>
      )}




      {eventInfo.event.extendedProps.Absent && (
        <div style={{ backgroundColor: 'red' }}>Absent</div>
      )}
      
      {eventInfo.event.extendedProps.Leave && (
        <div style={{ backgroundColor: '#fb15ff' }}>Leave</div>
      )}

      {eventInfo.event.extendedProps.Intime && (
        <div style={{ backgroundColor: 'white', borderTop: '4px solid white', color: '#545454' }}>In-time: {eventInfo.event.extendedProps.Intime.split(' ')[1]}</div>
      )}

      {eventInfo.event.extendedProps.Outtime && (
        <div style={{ backgroundColor: 'white', borderTop: '4px solid white', color: '#545454' }}>Out-time: {eventInfo.event.extendedProps.Outtime.split(' ')[1]}</div>
      )}
    </div>
  );
}

function getEventColor(event) {
  switch (event.title) {
    case "Week Off":
      return "rgb(103, 58, 183)"; // Week Off - Purple
    case "Present":
      return "green"; // Present - Green
    case "Absent":
      return "red"; // Absent - Red
    case "On Duty":
      return "rgb(245, 124, 0)"; // On Duty - Orange
    default:
      return "";
  }
}

export default Attendance;