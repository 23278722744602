import React, { useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ReactPaginate from 'react-paginate';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';


export default function PaySlip() {


    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState(''); //search filter
    const componentRef = React.useRef();  //download options
    const [loading, setLoading] = useState(true);
    const { empId } = useParams();
    //console.log(empId);
    const [attendanceData, setAttendanceData] = useState([]);

    // Sample data in array format
    // const attendanceData = [
    //     { month: 'Apr 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: <Link to="/admin/payslipemp" style={{color:'white'}}>View & Download</Link> },
    //     { month: 'may 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'june 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'july 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'Aguest 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'sep 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'oct 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },



    // ];



    useEffect(() => {

        const fetchData = async () => {
            try {

                const response = await axios.get(`https://officeinteriorschennai.com/api.php?viewsingleemppaysliplist=${empId}`);

                const { data } = response.data;
                setAttendanceData(data);
                //  console.log('--------------', data);
                //console.log("API Response", data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };

        fetchData();
    }, []);




    // download options
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const handleExportCSV = () => {

        const csvData = attendanceData.map(
            ({
                payslipmonthyear,
                emp_lop,
                basicda_amount,
                hra_amount,
                transportallowance_amount,
                conveyanceallowance_amount,
                otherallowances_amount,
                totalnetpay_amount,

            }, index) => ({
                'S.No': index + 1,
                'Month': payslipmonthyear,
                'Loss of Pay': emp_lop,
                'Basic + DA': basicda_amount,
                'HRA': hra_amount,
                'Transport Allowance': transportallowance_amount,
                'Convenience Allowance': conveyanceallowance_amount,
                'Other Allowance': otherallowances_amount,
                'Net Pay': totalnetpay_amount,
            })
        );



        const headers = [
            { label: 'S.No', key: 'S.No' },
            { label: 'Month', key: 'Month' },
            { label: 'Loss of Pay', key: 'Loss of Pay' },
            { label: 'Basic + DA', key: 'Basic + DA' },
            { label: 'HRA', key: 'HRA' },
            { label: 'Transport Allowance', key: 'Transport Allowance' },
            { label: 'Convenience Allowance', key: 'Convenience Allowance' },
            { label: 'Other Allowance', key: 'Other Allowance' },
            { label: 'Net Pay', key: 'Net Pay' },

        ];

        const csvReport = {
            data: csvData,
            headers: headers,
            filename: 'monthly_pay.csv',
        };

        // Use CSVLink for CSV export
        return <CSVLink {...csvReport}><i className="fas fa-file-csv" style={{ fontSize: '25px', color: '#212529' }}></i></CSVLink>;
    };

    const handleExportPDF = () => {
        const unit = 'pt';
        const size = 'A4';

        const doc = new jsPDF('landscape', unit, size);

        const data = attendanceData.map(
            ({
                payslipmonthyear,
                emp_lop,
                basicda_amount,
                hra_amount,
                transportallowance_amount,
                conveyanceallowance_amount,
                otherallowances_amount,
                totalnetpay_amount,
            }, index) => [
                    index + 1,
                    payslipmonthyear,
                    emp_lop,
                    basicda_amount,
                    hra_amount,
                    transportallowance_amount,
                    conveyanceallowance_amount,
                    otherallowances_amount,
                    totalnetpay_amount,
                ]
        );


        doc.autoTable({
            head: [
                ['S.No', 'Month', 'Loss of Pay', 'Basic + DA', 'HRA', 'Transport Allowance', 'Convenience Allowance', 'Other Allowance', 'Net Pay'],
            ],
            body: data,
        });

        doc.save('monthly_pay.pdf');
    };
    // download options end


    // search filter
    const filteredData = attendanceData.filter((row) =>
        Object.values(row).some(
            (value) =>
                (typeof value === 'string' || typeof value === 'number') &&
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // pagination start

    const filteredAttendanceData = filteredData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const navigate = useNavigate()

    const handleVisitPayslip = (empId) => {
        // Use navigate function to navigate to the '/profile' route
        navigate(`/admin/payslipemp/${empId}`);
    };

    // pagination end

    // search filter end


    // internal styles start

    const myStyles = {
        color: 'white',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        borderRadius: '21px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'

    };

    const myStyles1 = {
        color: 'black',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        borderRadius: '21px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'

    };

    // internal styles end

    return (
        <div style={{ display: 'grid', maxWidth: '1035px', margin: '0 auto', paddingTop: '7vh' }}>

            <h3 style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>Pay Slip</h3>
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', justifyContent: 'space-between' }}>
                <div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={myStyles1}

                    />
                </div>
                <div>
                    <button style={myStyles}>{handleExportCSV()}</button>

                    <button style={myStyles} onClick={handleExportPDF}><i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                    <button style={myStyles} onClick={handlePrint}><i className="fas fa-print" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                </div>
            </div>
            <div ref={componentRef}>
                <table className="table table-auto table-dark table-striped">
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: "10%" }}>S.No</th>
                            <th scope="col" style={{ width: "10%" }}>Month</th>
                            <th scope="col" style={{ width: "10%" }}>Loss of Pay</th>
                            <th scope="col" style={{ width: "10%" }}>Basic+DA</th>
                            <th scope="col" style={{ width: "10%" }}>HRA</th>
                            <th scope="col" style={{ width: "10%" }}>Transport Allowance</th>
                            <th scope="col" style={{ width: "10%" }}>Convenience Allowance</th>
                            <th scope="col" style={{ width: "10%" }}>Other Allowance</th>
                            <th scope="col" style={{ width: "10%" }}>Net Pay</th>
                            <th scope="col" style={{ width: "10%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            filteredAttendanceData.length === 0 ? (
                                <tr>
                                    <td colSpan="10" style={{ textAlign: 'center' }}>No search data found</td>
                                </tr>
                            ) : (

                                // ==================
                                filteredAttendanceData.map((row, index) => {
                                    const serialNumber = currentPage * itemsPerPage + index + 1;
                                    return (
                                        <tr key={row.id}>
                                            <th scope="row">{serialNumber}</th>
                                            <td>{row.payslipmonthyear}</td>
                                            <td>{row.emp_lop}</td>
                                            <td>{Math.round(row.basicda_amount)}</td>
                                            <td>{Math.round(row.hra_amount)}</td>
                                            <td>{row.transportallowance_amount}</td>
                                            <td>{row.conveyanceallowance_amount}</td>
                                            <td>{Math.round(row.otherallowances_amount)}</td>
                                            <td>{row.totalnetpay_amount}</td>
                                            <td onClick={() => handleVisitPayslip(row.id)}><Link style={{ color: 'white' }}>View Payslip</Link></td>
                                        </tr>
                                    );
                                })

                                // ==================

                            )}
                    </tbody>
                </table>
            </div>


            {/* Pagination */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <ReactPaginate
                    previousLabel={<span aria-hidden="true">&laquo;</span>}
                    nextLabel={<span aria-hidden="true">&raquo;</span>}
                    breakLabel={<span>...</span>}
                    breakClassName={'page-item disabled'}
                    breakLinkClassName={'page-link'}
                    pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    disabledClassName={'disabled'}
                    activeLinkClassName={'bg-dark text-white'}
                />
            </div>
        </div>
    );
}
