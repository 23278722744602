import React, { useRef } from 'react'
import Attendance from './Attendance'
import Emptotaldays from './emptotaldays'
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Attendanceadmin from './Monthlyattendanceadmin';
import { ScaleLoader } from 'react-spinners';


export default function Empattendanceadmin() {

  // ==================================================================================

  const [user, setUser] = useState('karthik praveen');
  const [salary, setSalary] = useState('25000');
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);


  // Retrieve userData from local storage
  const userData = JSON.parse(localStorage.getItem('userData'));

  const { id } = useParams();
  // console.log("this is useParams id", { id })



  //  Fetching data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const id = userData.userempid;
        const response = await axios.get(`https://officeinteriorschennai.com/api.php?viewemployeealllistempidholiday=${id}`);

        const { attendance_data, holiday_data } = response.data;

        // Transforming API response data into FullCalendar events format
        const transformedEvents = attendance_data.map(event => {
          // Check if checkin_time exists and is not null before splitting
          const start = event.checkin_time ? event.checkin_time.split(' ')[0] : null;
          const end = event.checkout_time ? event.checkout_time.split(' ')[0] : null;

          return {
            title: event.emp_present === 'P' ? 'Present' : '',
            start: start, // Assigning the split value or null
            end: end,
            late: event.emp_late === 'LA',
            permission: event.emp_permission == 'PR',
            HalfDay: event.emp_present == 'HL', // OffDay is true if emp_present is neither 'P' nor 'A'
            Absent: event.emp_present === 'A',
            Leave: event.emp_present === 'L',
            Onduty: event.emp_onduty !== '-',
            Intime: event.checkin_time ? event.checkin_time : null,
            Outtime: event.checkout_time ? event.checkout_time: null

          };
        });

        const holidayEvents = holiday_data.map(event => ({
          title: event.h_name,
          start: event.h_date,
          end: event.h_date,
          className: "public-holiday-event"
        }));

        // console.log("Transformed Events:", transformedEvents);

        // Including the weekly off event
        const eventsWithWeekOff = [
          ...transformedEvents,
          ...holidayEvents,
          {
            title: "Week Off",
            daysOfWeek: [0], // 0 is Sunday
            startRecur: "2000-01-01", // Start date for the recurrence
            endRecur: "2050-12-31", // End date for the recurrence (adjust as needed)
            className: "week-off-event",
          }
        ];

        setEvents(eventsWithWeekOff);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchData();
  }, []);



  // ===================================================================================

  const [employee, setEmployee] = useState('')

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await fetch(`https://officeinteriorschennai.com/api.php?viewemployeelist=${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();

        // console.log("this is responseData", responseData);
        const foundEmployee = responseData.data.find(employee => employee.id === id);

        if (foundEmployee) {
          setEmployee(foundEmployee);
          setLoading(false);
        } else {
          throw new Error('Employee not found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchEmployee();
  }, [id]);

  // console.log("check --------------------------------->", employee)

  // console.log("this is employee list ----->", employee.first_name)

  const firstname = employee.first_name;

  // ==============================

  const [centerDate, setCenterDate] = useState(new Date());
  const calendarRef = useRef(null);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setCenterDate(calendarApi.getDate()); // Set the initial center date
    }
  }, []);

  const handleDatesSet = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const newCenterDate = calendarApi.getDate();
      setCenterDate(newCenterDate); // Update the center date when the user navigates
      // console.log('Center date:', newCenterDate);
      // Here you can send the new center date to your API
    }
  };

  // console.log("setCenterDate ---------->", centerDate)

  // Format the date to display only month and year
  const formattedCenterDate = centerDate.toLocaleDateString(undefined, {
    month: 'long',
    year: 'numeric',
  });

  // ==============================


  return (
    <div className='container'>
      <div class="p-4">
        {/* <Emptotaldays events={events} user={user} salary={salary} loading={loading}/> */}


        {loading ? (
          <div style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f6f6f6'
          }}>
            <ScaleLoader color="#36d7b7" />


          </div>
        ) : (
          <>
            <Emptotaldays events={events} user={user} salary={salary} loading={loading} name={firstname} formattedCenterDate={formattedCenterDate}/>
            <Attendanceadmin events={events} user={user} salary={salary} loading={loading} name={firstname}     setCenterDate={setCenterDate}  centerDate={centerDate} calendarRef={calendarRef} handleDatesSet={handleDatesSet} formattedCenterDate={formattedCenterDate}/>
          </>
        )}
      </div>
    </div>
  )
}
