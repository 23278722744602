import React, { useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

export default function PayrollEmployee() {


    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState(''); //search filter
    const componentRef = React.useRef();  //download options
    const [loading, setLoading] = useState(true);
    const [attendanceData, setAttendanceData] = useState([]);

    // Sample data in array format
    // const attendanceData = [
    //     { month: 'Apr 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: <Link to="/admin/payslipemp" style={{color:'white'}}>View & Download</Link> },
    //     { month: 'may 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'june 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'july 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'Aguest 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'sep 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },
    //     { month: 'oct 2023', workingdays: 30, gross: 16812, deduction: 1635, tds: 0, net: 15177, view: 'View & Download' },



    // ];
    useEffect(() => {
        // Fetch data from the API when the component mounts
        const fetchData = async () => {
            try {
                // const userempid = userData.userempid;
                // const userrole = userData.userrole;
                const response = await axios.get(`https://officeinteriorschennai.com/api.php?viewallpaysliplist`);
                // Assuming the API response contains a data property with an array of employee data
                const { data } = response.data;

                // Sort the data based on the id property
                data.sort((a, b) => a.id - b.id);

                // Set the sorted data to the state
                setAttendanceData(data);
                // console.log("API Response", data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };

        fetchData();
    }, []);


    // download options
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleExportCSV = () => {
        const csvData = attendanceData.map(
            ({
                emp_name,

            }, index) => ({
                'S.No': index + 1,
                'Employee Name': emp_name,
            })
        );

        const headers = [
            { label: 'S.No', key: 'S.No' },
            { label: 'Employee Name', key: 'Employee Name' },


        ];

        const csvReport = {
            data: csvData,
            headers: headers,
            filename: 'monthly_pay.csv',
        };

        // Use CSVLink for CSV export
        return <CSVLink {...csvReport}><i className="fas fa-file-csv" style={{ fontSize: '25px', color: '#212529' }}></i></CSVLink>;
    };

    const handleExportPDF = () => {
        const unit = 'pt';
        const size = 'A4';

        const doc = new jsPDF('landscape', unit, size);

        const data = attendanceData.map(
            ({
                emp_name,
            }, index) => [
                    index + 1,
                    emp_name,
                ]
        );


        doc.autoTable({
            head: [
                ['S.No', 'Employee Name'],
            ],
            body: data,
        });

        doc.save('monthly_pay.pdf');
    };
    // download options end


    // search filter
    const filteredData = attendanceData.filter((row) =>
        Object.values(row).some(
            (value) =>
                (typeof value === 'string' || typeof value === 'number') &&
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // pagination start

    const filteredAttendanceData = filteredData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    // pagination end

    // search filter end


    // internal styles start

    const myStyles = {
        color: 'white',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        borderRadius: '21px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'

    };

    const myStyles1 = {
        color: 'black',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        borderRadius: '21px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'

    };

    // internal styles end

    const navigate = useNavigate()

    const handleVisitPayroll = (empId) => {
        // Use navigate function to navigate to the '/profile' route
        navigate(`/admin/payslip/${empId}`);
    };

    return (
        <div style={{ display: 'grid', maxWidth: '1035px', margin: '0 auto', paddingTop: '7vh' }}>

            <h3 style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>Pay-Roll Employee Details</h3>
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', justifyContent: 'space-between' }}>
                <div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={myStyles1}

                    />
                </div>
                <div>
                    <button style={myStyles}>{handleExportCSV()}</button>

                    <button style={myStyles} onClick={handleExportPDF}><i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                    <button style={myStyles} onClick={handlePrint}><i className="fas fa-print" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                </div>
            </div>
            <div ref={componentRef}>
                <table className="table table-auto table-dark table-striped">
                    <thead>
                        <tr><th scope="col">S.no</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>


                        {

                            filteredAttendanceData.length === 0 ? (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: 'center' }}>No search data found</td>
                                </tr>
                            ) : (
                                // ====================
                                filteredAttendanceData.map((row, index) => {
                                    const serialNumber = currentPage * itemsPerPage + index + 1;
                                    return (
                                        <tr key={row.empid}>
                                            <th scope="row">{serialNumber}</th>
                                            <td>{row.emp_name}</td>
                                            <td onClick={() => handleVisitPayroll(row.empid)}><Link style={{ color: 'white' }}>View Details</Link></td>
                                        </tr>
                                    );
                                })

                                // ====================


                            )}
                    </tbody>
                </table>
            </div>


            {/* Pagination */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <ReactPaginate
                    previousLabel={<span aria-hidden="true">&laquo;</span>}
                    nextLabel={<span aria-hidden="true">&raquo;</span>}
                    breakLabel={<span>...</span>}
                    breakClassName={'page-item disabled'}
                    breakLinkClassName={'page-link'}
                    pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    disabledClassName={'disabled'}
                    activeLinkClassName={'bg-dark text-white'}
                />
            </div>
        </div>
    );
}
