import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';



import Checkin from "../../assets/admin/assets/img/checkin.png";
import Checkout from "../../assets/admin/assets/img/checkout.png";
import Totalhouse from "../../assets/admin/assets/img/totalhouse.png";

export default function EmployeeDashboard({checkintime,checkouttime,checktotaltime}) {
    // const [checkInTime] = useState('09:10:00');
    // const [checkOutTime] = useState('06:30:00');
    // const [totalHours] = useState('09:20:00');


    return (
        <div className='container mt-5'>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col'>
                            <span style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={Checkin} alt="Checkin" className='dash-img' style={{ width: '60%' }} />
                            </span>
                            <h4 style={{textAlign:'center'}}>Check-in Time</h4>
                            <h5 style={{textAlign:'center'}}>{checkintime}</h5>
                        </div>
                        <div className='col'>
                            <span style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={Checkout} alt="Checkout" className='dash-img' style={{ width: '60%' }} />
                            </span>
                            <h4 style={{textAlign:'center'}}>Check-out Time</h4>
                            <h5 style={{textAlign:'center'}}>{checkouttime}</h5>
                        </div>
                        <div className='col'>
                            <span style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={Totalhouse} alt="Totalhouse" className='dash-img' style={{ width: '60%' }} />
                            </span>
                            <h4 style={{textAlign:'center'}}>Total Hours</h4>
                            <h5 style={{textAlign:'center'}}>{checktotaltime}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
