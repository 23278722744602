import React, { useState } from 'react';
import axios from 'axios';

const RegisterUser = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    c_password: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/register', formData);
      // console.log(response.data);
      // Handle success, e.g., redirect to login page
    } catch (error) {
      if (error.response.status === 422) {
        // Handle validation errors
        console.error('Validation errors:', error.response.data.errors);
      } else {
        console.error('Registration error:', error.response.data);
        // Handle other registration errors
      }
    }
  };

//   ============

  return (
    <form onSubmit={handleSubmit}>
      <label>Name:</label>
      <input type="text" name="name" value={formData.name} onChange={handleChange} />

      <label>Email:</label>
      <input type="email" name="email" value={formData.email} onChange={handleChange} />

      <label>Password:</label>
      <input type="password" name="password" value={formData.password} onChange={handleChange} />

      <label>Confirm Password:</label>
      <input
        type="password"
        name="c_password"
        value={formData.c_password}
        onChange={handleChange}
      />

      <button type="submit">Register</button>
    </form>
  );
};

export default RegisterUser;
