import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../../assets/admin/css/Add_employee.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { ScaleLoader } from 'react-spinners';

const AddEmployeeForm = () => {

    const [loading, setLoading] = useState(true);


    // Basic Details
    const [employeeId, setEmployeeId] = useState('');
    const [empimage, setEmpimage] = useState('');
    const [first_name, setFirst_name] = useState('');
    const [last_name, setLast_name] = useState('');
    const [gender, setGender] = useState('');
    const [phone_no, setPhone_no] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [email, setemail] = useState('');
    const [dob, setdob] = useState('');

    // Permanent Address
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');

    // Current Address
    const [current_address, setCurrent_address] = useState('');
    const [current_city, setCurrent_city] = useState('');
    const [current_state, setCurrent_state] = useState('');
    const [current_pincode, setCurrent_pincode] = useState('');

    // Personal Details
    const [parents, setParents] = useState('');
    const [proofs_image, setProofs_image] = useState('');
    const [doj, setDoj] = useState('');
    const [marital_status, setMarital_status] = useState('');
    const [pan_number, setPan_number] = useState('');
    const [uan_number, setUan_number] = useState('');
    const [emp_salary, setEmp_salary] = useState('');
    const [empgrosssalary, setEmpgrosssalary] = useState('');
    const [emppf, setEmppf] = useState('');
    const [spouse_name, setSpouse_name] = useState('');


    // User Create
    const [role, setRole] = useState('');
    const [department_name, setDepartment_name] = useState([]);
    const [designation, setDesignation] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [user_id, setUser_id] = useState('');
    const [password, setPassword] = useState('');
    const [emp_status, setEmp_status] = useState('');

    // Bank Account Detailsn
    const [bank_accountnumber, setBank_accountnumber] = useState('');
    const [bank_name, setBank_name] = useState('');
    const [bank_branch, setBank_branch] = useState('');
    const [ifsc_code, setIfsc_code] = useState('');
    const [ac_type, setAc_type] = useState('');

    // ==============================================================================================================

    const handleCancel = () => {
        // Assuming you have state-setting functions for each field
        setEmployeeId('');
        setEmpimage('');
        setFirst_name('');
        setLast_name('');
        setGender('');
        setPhone_no('');
        setWhatsapp('');


        setemail('');
        setdob('');


        setAddress('');
        setCity('');
        setState('');
        setPincode('');


        setCurrent_address('');
        setCurrent_city('');
        setCurrent_state('');
        setCurrent_pincode('');



        setBank_accountnumber('');
        setBank_name('');
        setBank_branch('');
        setIfsc_code('');
        setAc_type('');


        setParents('');
        setProofs_image('');
        setDoj('');
        setMarital_status('');

        setRole('');
        setDepartment_name('');
        setSupervisor('');
        setUser_id('');
        setPassword('');
        setEmp_status('');

        setBank_accountnumber('');
        setBank_name('');
        setBank_branch('');
        setIfsc_code('');
        setAc_type('');


    };


    // ==============================================================================================================


    const handleImageChange = (e) => {
        setEmpimage(e.target.files[0]);

    };

    const handleProofChange = (e) => {
        setProofs_image(e.target.files[0]);
    };

    // =============================================================================================================

    const handleAddEmployee = async (e) => {
        // Validation
        if (!validateForm()) {
            // If validation fails, display error messages or handle appropriately
            return;
        }

        // have to add validation

        e.preventDefault();

        const formData = new FormData();

        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('gender', gender);
        formData.append('phone_no', phone_no);
        formData.append('whatsapp', whatsapp);
        formData.append('email', email);
        formData.append('dob', dob);

        formData.append('address', address);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('pincode', pincode);

        formData.append('current_address', current_address);
        formData.append('current_city', current_city);
        formData.append('current_state', current_state);
        formData.append('current_pincode', current_pincode);

        formData.append('parents', parents);
        formData.append('doj', doj);
        formData.append('marital_status', marital_status);
        formData.append('pan_number', pan_number);
        formData.append('uan_number', uan_number);
        formData.append('emp_salary', emp_salary);
        formData.append('empgrosssalary', empgrosssalary);
        formData.append('emppf', emppf);
        formData.append('spouse_name', spouse_name);
        formData.append('role', role);
        formData.append('department_name', designation);
        formData.append('designation', department_name);
        formData.append('supervisor', supervisor);
        formData.append('user_id', user_id);
        formData.append('password', password);
        formData.append('emp_status', emp_status);

        formData.append('bank_accountnumber', bank_accountnumber);
        formData.append('bank_name', bank_name);
        formData.append('bank_branch', bank_branch);
        formData.append('ifsc_code', ifsc_code);
        formData.append('ac_type', ac_type);
        formData.append('loginepkempid', employeeId);
        formData.append('empimage', empimage);
        formData.append('proofs_image', proofs_image);

        try {
            const response = await fetch('https://officeinteriorschennai.com/api.php?webaddemployee', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            // console.log(data);


            if (data.status === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Form submitted successfully',
                });
                clearFormFields();

            } else {
                console.error('Failed to submit form: ', response.statusText);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to submit form',
                });
            }
        } catch (error) {
            console.error('Error submitting form: ', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error submitting form. Please try again later.',
            });
        }
    };


    // ===============

    const validateForm = () => {
        // Validation logic for each field
        // Check if fields are not empty, valid email format, etc.


        if (!empimage) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please upload employee image',
            });
            return false;
        }


        // first name

        if (!first_name.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a first name',
            });
            return false;
        }

        // last name

        if (!last_name.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a last name',
            });
            return false;
        }

        // gender

        if (!gender.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please Select a gender',
            });
            return false;
        }



        // Validate phone number format
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(phone_no.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a valid 10-digit phone number',
            });
            return false;
        }


        // Validate whatsapp number format
        const whatsappphonePattern = /^\d{10}$/;
        if (!whatsappphonePattern.test(whatsapp.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a valid 10-digit whatsapp number',
            });
            return false;
        }

        // Validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a valid email address',
            });
            return false;
        }

        // Check if date of birth is provided
        if (!dob.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a date of birth',
            });
            return false;
        }

        // Validate address fields
        if (!address.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter an address',
            });
            return false;
        }

        if (!city.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a city',
            });
            return false;
        }

        if (!state.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a state',
            });
            return false;
        }

        if (!pincode.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a pincode',
            });
            return false;
        }

        if (!current_address.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a current address',
            });
            return false;
        }

        if (!current_city.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a current city',
            });
            return false;
        }

        if (!current_state.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a current state',
            });
            return false;
        }

        if (!current_pincode.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a current pincode',
            });
            return false;
        }

        if (!parents.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter parent information',
            });
            return false;
        }

        if (!proofs_image) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please upload proof images',
            });
            return false;
        }

        if (!doj.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a date of joining',
            });
            return false;
        }

        if (!marital_status.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select marital status',
            });
            return false;
        }

        if (!pan_number.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter PAN number',
            });
            return false;
        }

        if (!uan_number.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter UAN number',
            });
            return false;
        }

        if (!emp_salary.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter employee salary',
            });
            return false;
        }


        if (!empgrosssalary.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter employee Grosssalary',
            });
            return false;
        }



        if (!emppf.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter employee PF',
            });
            return false;
        }

        if (!spouse_name.trim() && marital_status.trim() === 'Married') {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter spouse name',
            });
            return false;
        }

        if (!role.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter role',
            });
            return false;
        }

        if (!designation.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter designation',
            });
            return false;
        }

        if (!supervisor.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter supervisor name',
            });
            return false;
        }



        // Validate email format
        const user_idemailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!user_idemailPattern.test(user_id.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a valid Office mail address',
            });
            return false;
        }

        if (!password.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter password',
            });
            return false;
        }

        if (!emp_status.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select employee status',
            });
            return false;
        }

        if (!bank_accountnumber.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter bank account number',
            });
            return false;
        }

        if (!bank_name.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter bank name',
            });
            return false;
        }

        if (!bank_branch.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter bank branch',
            });
            return false;
        }

        if (!ifsc_code.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter IFSC code',
            });
            return false;
        }

        if (!ac_type.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select account type',
            });
            return false;
        }

        if (!employeeId.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter employee ID',
            });
            return false;
        }





        return true; // Return true if all validations pass
    };


    const clearFormFields = () => {
        setFirst_name('');
        setLast_name('');
        setdob('');
        setGender('');
        setPhone_no('');
        setWhatsapp('');
        setemail('');

        setAddress('');
        setState('');
        setCity('');
        setPincode('');

        setCurrent_address('');
        setCurrent_state('');
        setCurrent_city('');
        setCurrent_pincode('');

        setParents('');
        setMarital_status('');
        setSpouse_name('');
        setDoj('');

        setBank_accountnumber('');
        setBank_name('');
        setBank_branch('');
        setIfsc_code('');
        setAc_type('');

        setUser_id('');
        setSupervisor('');
        setRole('');
        setPassword('');
        setEmp_status('');

        setEmployeeId('');
        setEmpimage(null);
        setProofs_image(null);
    };


    // =============================================================================================================

    const boxStyles = {
        background: 'linear-gradient(90deg, rgb(240, 223, 223), rgb(234, 234, 234), rgb(222, 222, 222))',
        borderRadius: '10px',
        padding: '20px 25px 0px 25px',
        marginTop: '35px'
    };



    // =============================================================================================================
    // Same As current Address Function


    const [copyCurrentToPermanent, setCopyCurrentToPermanent] = useState(false);

    const handleCopyToggle = () => {
        setCopyCurrentToPermanent(!copyCurrentToPermanent);
        if (!copyCurrentToPermanent) {
            setAddress(current_address);
            setCity(current_city);
            setState(current_state);
            setPincode(current_pincode);
        } else {

            setAddress('');
            setCity('');
            setState('');
            setPincode('');
        }
    };


    // =============================================================================================================



    // Fetch employee ID
    useEffect(() => {
        const fetchEmployeeId = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?epkemployeeid');
                setEmployeeId(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee ID:', error);
            }
        };

        fetchEmployeeId();
    }, []);

    // // Fetch user roles
    const [userRoleDropdown, setUserRoleDropdown] = useState([]);

    useEffect(() => {
        const fetchUserRoleDropdown = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?userroletype');
                const data = response.data.data.map(user => ({ id: user.id, name: user.name }));
                setUserRoleDropdown([{ id: '', name: 'Select User Role' }, ...data]);
            } catch (error) {
                console.error('Error fetching user roles:', error);
            }
        };

        fetchUserRoleDropdown();
    }, []);

    // // Fetch user departments

    const [userDepartmentDropdown, setUserDepartmentDropdown] = useState([]);


    useEffect(() => {
        const fetchUserDepartmentDropdown = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?department');
                const data = response.data.data.map(department => ({ id: department.department_id, name: department.department_name }));
                setUserDepartmentDropdown([{ id: '', name: 'Select User Department' }, ...data]);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        fetchUserDepartmentDropdown();
    }, []);

    // // Fetch supervisors based on selected department
    const [userSupervisorDropdown, setUserSupervisorDropdown] = useState([]);
    useEffect(() => {
        const fetchSupervisors = async () => {
            if (department_name) {
                try {
                    const response = await axios.get(`https://officeinteriorschennai.com/api.php?supervisorlist=${department_name}`);
                    const data = response.data.data.map(supervisor => ({ id: supervisor.id, name: supervisor.first_name }));
                    setUserSupervisorDropdown([{ id: '', name: 'Select Supervisor' }, ...data]);
                } catch (error) {
                    console.error('Error fetching supervisors:', error);
                }
            }
        };

        fetchSupervisors();
    }, [department_name]);



    // ==================================================================

    return (
        <>

            {loading ? (
                <div style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#f6f6f6'
                }}>
                    <ScaleLoader color="#36d7b7" />
                </div>
            ) : (

                <div className="container mt-4" style={{ display: 'grid', maxWidth: '1035px', margin: '0 auto', boxShadow: '0px 0px 10px 1px black', marginBottom: '15px', paddingBottom: '10px' }}>
                    <h2 style={{ textAlign: 'center' }}>Add Employee</h2>

                    {/* ================================================================================================== */}
                    <div style={boxStyles}>

                        {/* Basic Details */}
                        <h4>Basic details</h4>
                        <div className="row mb-3">
                            {/* Employee ID */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Employee ID</label>
                                {/* <input type="text" className="form-control" value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} /> */}
                                <input type='text' className="form-control" value={employeeId} readOnly />
                            </div>
                            {/* Profile */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Profile</label>
                                {/* <input type="file" className="form-control" value={empimage} onChange={(e) => setEmpimage(e.target.value)} /> */}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* First Name */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">First Name</label>
                                <input type="text" className="form-control" value={first_name} onChange={(e) => setFirst_name(e.target.value)} />

                            </div>

                            {/* Last Name */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Last Name</label>
                                <input type="text" className="form-control" value={last_name} onChange={(e) => setLast_name(e.target.value)} />



                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* Gender */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Gender</label>
                                <select className="form-select" value={gender} onChange={(e) => setGender(e.target.value)}>
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>

                            {/* Phone Number */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Phone Number</label>
                                <input type="text" className="form-control" value={phone_no} onChange={(e) => setPhone_no(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* Whatsapp Number */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Whatsapp Number</label>
                                <input type="text" className="form-control" value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} />
                            </div>

                            {/* Personal Email Id */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Personal Email Id</label>
                                <input type="email" className="form-control" value={email} onChange={(e) => setemail(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* Birthday */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Birthday</label>
                                <input type="date" className="form-control" value={dob} onChange={(e) => setdob(e.target.value)} />
                            </div>


                        </div>

                    </div>


                    {/* ================================================================================================== */}
                    <div style={boxStyles}>

                        {/* Current Address */}
                        <h4>Current Address</h4>
                        <div className="row mb-3">
                            {/* Current Address */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Current Address</label>
                                <input type="text" className="form-control" value={current_address} onChange={(e) => setCurrent_address(e.target.value)} />
                            </div>

                            {/* Current City */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Current City</label>
                                <input type="text" className="form-control" value={current_city} onChange={(e) => setCurrent_city(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* Current State */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Current State</label>
                                <input type="text" className="form-control" value={current_state} onChange={(e) => setCurrent_state(e.target.value)} />
                            </div>

                            {/* Current Pincode */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Current Pincode</label>
                                <input type="text" className="form-control" value={current_pincode} onChange={(e) => setCurrent_pincode(e.target.value)} />
                            </div>
                        </div>

                    </div>
                    {/* ================================================================================================== */}



                    <div style={boxStyles}>

                        {/* Permanent Address */}
                        <h4>Permanent Address</h4>
                        <div className="mb-3 form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="copyCurrentToPermanent"
                                checked={copyCurrentToPermanent}
                                onChange={handleCopyToggle}
                            />
                            <label className="form-check-label" htmlFor="copyCurrentToPermanent">
                                Same As Current Address
                            </label>
                        </div>
                        <div className="row mb-3">
                            {/* Permanent Address */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Permanent Address</label>
                                <input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>

                            {/* Permanent City */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Permanent City</label>
                                <input type="text" className="form-control" value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* Permanent State */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Permanent State</label>
                                <input type="text" className="form-control" value={state} onChange={(e) => setState(e.target.value)} />
                            </div>

                            {/* Permanent Pincode */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Permanent Pincode</label>
                                <input type="text" className="form-control" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                            </div>
                        </div>

                    </div>


                    {/* ================================================================================================== */}
                    <div style={boxStyles}>

                        {/* Personal Details */}
                        <h4>Personal Details</h4>
                        <div className="row mb-3">
                            {/* Father/Guardian Name */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Father/Guardian Name</label>
                                <input type="text" className="form-control" value={parents} onChange={(e) => setParents(e.target.value)} />
                            </div>

                            {/* Proof */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Proof</label>
                                {/* <input type="file" className="form-control" value={proofs_image} onChange={(e) => setProofs_image(e.target.value)} /> */}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleProofChange}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* Date of Join */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Date of Join</label>
                                <input type="date" className="form-control" value={doj} onChange={(e) => setDoj(e.target.value)} />
                            </div>

                            {/* Martial Status */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Martial Status</label>
                                <select className="form-select" value={marital_status} onChange={(e) => setMarital_status(e.target.value)}>
                                    <option value="">Select Martial Status</option>
                                    <option value="single">Single</option>
                                    <option value="married">Married</option>
                                    <option value="divorced">Divorced</option>
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* PAN Number */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">PAN Number</label>
                                <input type="text" className="form-control" value={pan_number} onChange={(e) => setPan_number(e.target.value)} />
                            </div>
                            <div className="col-md-6 mb-3">
                                {/* UAN Number */}
                                <label className="form-label">UAN Number</label>
                                <input type="text" className="form-control" value={uan_number} onChange={(e) => setUan_number(e.target.value)} />
                            </div>

                        </div>




                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                {/* Net Salary */}
                                <label className="form-label">Net Salary</label>
                                <input type="text" className="form-control" value={emp_salary} onChange={(e) => setEmp_salary(e.target.value)} />
                            </div>

                            <div className="col-md-6 mb-3">
                                {/* Gross salary */}
                                <label className="form-label">Gross salary</label>
                                <input type="text" className="form-control" value={empgrosssalary} onChange={(e) => setEmpgrosssalary(e.target.value)} />
                            </div>

                        </div>

                        {/* Conditional Rendering for Spouse Name */}


                        <div className="row mb-3">
                            {/* PF */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">PF</label>
                                <select className="form-select" value={emppf} onChange={(e) => setEmppf(e.target.value)}>
                                    <option value="">Select</option>
                                    <option value="NA">NA</option>
                                    <option value="Applicable">Applicable</option>
                                </select>
                            </div>


                            {marital_status === 'married' && (
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Spouse Name</label>
                                    <input type="text" className="form-control" value={spouse_name} onChange={(e) => setSpouse_name(e.target.value)} />
                                </div>
                            )}

                        </div>


                    </div>


                    {/* ================================================================================================== */}
                    <div style={boxStyles}>

                        {/* User Create */}
                        <h4>User Create</h4>
                        <div className="row mb-3">
                            {/* Select User Role */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Select User Role</label>
                                <select className="form-select" value={role} onChange={(e) => setRole(e.target.value)}>
                                    {userRoleDropdown.map(option => (
                                        <option key={option.id} value={option.id}>{option.name}</option>

                                    ))}
                                </select>


                            </div>

                            {/* Select User Department */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Select User Department</label>
                                <select className="form-select" value={department_name} onChange={(e) => setDepartment_name(e.target.value)}>
                                    {userDepartmentDropdown.map(option => (
                                        <option key={option.id} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* Select Designation */}


                            <div className="col-md-6 mb-3">
                                <label className="form-label">Desigination</label>
                                <input type="text" className="form-control" value={designation} onChange={(e) => setDesignation(e.target.value)} />
                            </div>

                            {/* Select Supervisor */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Select Supervisor</label>
                                <select className="form-select" value={supervisor} onChange={(e) => setSupervisor(e.target.value)}>
                                    {userSupervisorDropdown.map(option => (
                                        <option key={option.id} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* Office Mail Id */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Office Mail Id</label>
                                <input type="email" className="form-control" value={user_id} onChange={(e) => setUser_id(e.target.value)} />
                            </div>

                            {/* Office Mail Password */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Office Mail Password</label>
                                <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">


                            {/* Select Status */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Select Status</label>
                                <select className="form-select" value={emp_status} onChange={(e) => setEmp_status(e.target.value)}>
                                    <option value="">Select Status</option>
                                    {/* Add options for status */}
                                    <option value="Active">Active</option>
                                    <option value="In-Active">Inactive</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    {/* ================================================================================================== */}
                    <div style={boxStyles}>
                        {/* Bank Account Details */}
                        <h4>Bank Account Details</h4>
                        <div className="row mb-3">
                            {/* Bank Account Number */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Bank Account Number</label>
                                <input type="text" className="form-control" value={bank_accountnumber} onChange={(e) => setBank_accountnumber(e.target.value)} />
                            </div>

                            {/* Bank Name */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Bank Name</label>
                                <input type="text" className="form-control" value={bank_name} onChange={(e) => setBank_name(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            {/* Bank Branch */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Bank Branch</label>
                                <input type="text" className="form-control" value={bank_branch} onChange={(e) => setBank_branch(e.target.value)} />
                            </div>

                            {/* IFSC Code */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">IFSC Code</label>
                                <input type="text" className="form-control" value={ifsc_code} onChange={(e) => setIfsc_code(e.target.value)} />
                            </div>
                        </div>

                        {/* Select Account Type */}
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Select Account Type</label>
                                <select className="form-select" value={ac_type} onChange={(e) => setAc_type(e.target.value)}>
                                    <option value="">Select Account Type</option>
                                    {/* Add options for account types */}
                                    <option value="savings">Savings</option>
                                    <option value="current">Current</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    {/* ================================================================================================== */}







                    {/* Buttons */}
                    <div className="mb-3" style={{ marginTop: '30px' }}>
                        <button className="btn btn-secondary me-2" onClick={handleCancel}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={handleAddEmployee}>
                            Add Employee
                        </button>
                    </div>

                </div>

            )}

        </>
    );
};

export default AddEmployeeForm;
