import React from 'react';
import Notification from './Notification'; // Adjust the path accordingly
// import Userprofile from "../../assets/admin/assets/img/profile.png";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../layouts/admin/Navbar';
import useNotificationData from './useNotificationData';
import LoadingIcon from './LoadingIcon';


const NotificationPage = () => {
  // Sample data for testing

  // const initialNotifications  = [
  //     {
  //         imageSrc: Userprofile,
  //         name: 'Anna priya',
  //         message: 'You are attendance request was approved',
  //         time: '2 hours ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are leave request was rejected',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are permission request was approved',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'Your payslip for September 2023 has been released',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are attendance request was approved',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are leave request was rejected',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are permission request was approved',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are attendance request was approved',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are attendance request was approved',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are permission request was approved',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are attendance request was approved',
  //         time: '1 day ago',
  //     },
  //     {
  //         imageSrc: Userprofile,
  //         name: 'jagadesh',
  //         message: 'You are attendance request was approved',
  //         time: '1 day ago',
  //     },

  //     // Add more notification objects as needed
  // ];


  const { displayedNotifications } = useNotificationData();

  // const [displayedNotifications, setDisplayedNotifications] = useState([]);




  // useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await axios.get('https://officeinteriorschennai.com/api.php?employeelist');
  //         console.log(response)
  //         setDisplayedNotifications(response.data.data);
  //       } catch (error) {
  //         console.log(error.message);
  //       }
  //     };
  //     fetchData();
  //   }, []);

  //   console.log(displayedNotifications);


  // const [loadMoreCount, setLoadMoreCount] = useState(10);

  // const handleShowMore = () => {
  //     const newLoadMoreCount = loadMoreCount + 10;
  //     setLoadMoreCount(newLoadMoreCount);
  //     setDisplayedNotifications(initialNotifications.slice(0, newLoadMoreCount));
  // };


  // =============================

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating data fetching with a setTimeout
    const fetchData = () => {
      // Simulate an API call delay
      setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Adjust the timeout as needed

      // Actual data fetching logic goes here...
      // For example, fetch notifications from an API
      // fetchNotifications().then((data) => {
      //   setNotifications(data);
      //   setIsLoading(false);
      // }).catch((error) => {
      //   console.error('Error fetching notifications:', error);
      //   setIsLoading(false);
      // });
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs once on mount

// =================================================================================

  return (


    <div className="notification-page" style={{ padding: '50px 80px 32px' }}>
      <h3>Notification</h3>


      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          {displayedNotifications.map((notification, index) => (
            <Notification
              key={index}
              imageSrc={notification.image}
              name={notification.first_name}
              message={notification.address}
              time={notification.updated_at}
            />
          ))}

        </>
      )}




    </div>

  );
};

export default NotificationPage;
