// Dashboard.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Admindashboard from './admindashboard';
import EmployeeDashboard from './employeedashboard';
import { ScaleLoader } from 'react-spinners';
import { Navigate, useNavigate } from 'react-router-dom';

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({});
  const [empdashboardData, setEmpdashboardData] = useState({});
  const [loading, setLoading] = useState(true);

  // Retrieve userData from local storage
  const userData = JSON.parse(localStorage.getItem('userData'));




  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = 'https://officeinteriorschennai.com/api.php?adminindextodaycount';
        const response = await axios.get(apiUrl);
        setDashboardData(response.data);
        console.log("this is for Admin count", response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // for Api Employee

  // Hardcoded userempid for demonstration

  // const userempid = userData.userempid;
  // const userepkempid = userData.userepkempid;

  // Check if userData exists and has necessary properties before accessing them
  const userempid = userData?.userempid || '';
  const userepkempid = userData?.userepkempid || '';
  const userrole = userData?.userrole || '';



  // const userempid = 37; // Replace this with the actual userempid
  // const userepkempid = 'EPK007';  // for APi Admin

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Updated the apiUrl1 to include the correct query parameters

        const apiUrl1 = `https://officeinteriorschennai.com/api.php?indexloginepkempid=${userepkempid}&indexloginempid=${userempid}`;

        const empresponse = await axios.get(apiUrl1);
        setEmpdashboardData(empresponse.data);
        // console.log("This is for employee:", empresponse.data);
      } catch (error) {
        console.error('Error fetching employee data:', error);

      }
    };

    fetchData();
  }, [userempid, userepkempid]); // Added userepkempid to the dependency




  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('isAuthenticated')) {
      navigate('/login');
    }
  }, [navigate]);


  // ====== this is side bar validation ======

  // const userrole = userData.userrole;


  const [conditionDisplay, setConditionDisplay] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://officeinteriorschennai.com/api.php?userrolenavigation');
        setConditionDisplay(response.data.data);
        // console.log("setConditionDisplayAdmin--------------->", response.data.data)
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);




  // ==================================================================================

  const [conditionDisplayAdmin, setConditionDisplayAdmin] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {                                                                          
        const response = await axios.get(`https://officeinteriorschennai.com/api.php?dashboardcountview`);
        setConditionDisplayAdmin(response.data.data);
        
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);


  return (
    <>

      <>
        {loading ? (
          <div style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f6f6f6'
          }}>
            <ScaleLoader color="#36d7b7" />


          </div>
        ) : (
          <>
            {conditionDisplay.includes(userrole) ?
              <Admindashboard
                days_absent={dashboardData.days_absent}
                days_leave={dashboardData.days_leave}
                days_halfday={dashboardData.days_halfday}
                days_late={dashboardData.days_late}
                days_onduty={dashboardData.days_onduty}
                days_permission={dashboardData.days_permission}
                days_present={dashboardData.days_present}
                total_employee_count={dashboardData.total_employee_count}
              />

              : null}
          </>
        )}
      </>



      {/* ================================================================== */}

      <>
        {loading ? (
          <div style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f6f6f6'
          }}>
            <ScaleLoader color="#36d7b7" />


          </div>
        ) : (

          <>
            {conditionDisplayAdmin.includes(userrole) ? null :

              <EmployeeDashboard
                checkintime={empdashboardData.userempcheckintime}
                checkouttime={empdashboardData.userempcheckouttime}
                checktotaltime={empdashboardData.userempchecktotaltime}
              />
            }
          </>
        )}
      </>

    </>
  );
}

export default Dashboard;
