import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import EPK from "../../assets/admin/assets/img/EPK_group_Logo.png";
import Userprofile from "../../assets/admin/assets/img/profile.png";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Notification from '../../components/admin/Notification';
// import axios from 'axios';
import useNotificationData from '../../components/admin/useNotificationData';
import LoadingIcon from '../../components/admin/LoadingIcon';
import Swal from 'sweetalert2';


function Navbar({ notifications = [] }) {

  useEffect(() => {
    const handleSidebarToggle = (event) => {
      event.preventDefault();
      document.body.classList.toggle('sb-sidenav-toggled');
      localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    };

    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
      if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
        document.body.classList.toggle('sb-sidenav-toggled');
      }
      sidebarToggle.addEventListener('click', handleSidebarToggle);
    }

    return () => {
      sidebarToggle.removeEventListener('click', handleSidebarToggle);
    };
  }, []);



  // State to manage the dropdown status
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  }
  const userImageUrl = Userprofile; // Replace this with the actual URL or variable


  const [isDropdownOpenAler, setDropdownOpenAler] = useState(false);



  const toggleDropdownAler = () => {
    setDropdownOpenAler(!isDropdownOpenAler);
  };

  const closeDropdownAler = () => {
    setDropdownOpenAler(false);
  };

  // const imgadjustment = {
  //   // width: '20%',
  //   height: '40px',
  //   borderRadius: '50%',
  //   border: '1px solid black',
  //   marginRight: '15px'
  // };

  // const ellipsisStyle = {
  //   display: 'flex',
  //   width: '200px',
  //   textOverflow: 'ellipsis',
  //   overflow: 'hidden',
  //   whiteSpace: 'nowrap',
  //   lineHeight: '28px',
  //   color: 'black', // Set the color to black
  // };

  //  ===========================

  const { firstThreeNotifications } = useNotificationData();

  // ==========================

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating data fetching with a setTimeout
    const fetchData = () => {
      // Simulate an API call delay
      setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Adjust the timeout as needed

      // Actual data fetching logic goes here...
      // For example, fetch notifications from an API
      // fetchNotifications().then((data) => {
      //   setNotifications(data);
      //   setIsLoading(false);
      // }).catch((error) => {
      //   console.error('Error fetching notifications:', error);
      //   setIsLoading(false);
      // });
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs once on mount




  // const handleLogout = () => {
  //   // console.log('Logging out...');
  //   localStorage.clear();

  // }
  const navigate = useNavigate(); 

  const handleLogout = (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will be logged out!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
       
        localStorage.clear();
        navigate('/login');
       
      }
    });
  };

  // Retrieve userData from local storage
  const userData = JSON.parse(localStorage.getItem('userData'));

  const username = userData ? userData.username : '';
  const userimage = userData ? userData.userimage : '';

  return (

    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">

      <Link className="navbar-brand ps-3" to="/admin/dashboard"><img src={EPK} alt="Logo" className='dash-img' style={{ width: '93%' }} /></Link>

      <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" to="#!"><i className="fas fa-bars"></i></button>





      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">

        {/* <div className="input-group">
          <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
          <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
        </div> */}
      </form>



      <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">

        {/* <li style={{ display: 'flex', alignItems: 'center', fontSize: '28px' }}>
          <Link className="dropdown-item" to="/admin/notification">
            <FontAwesomeIcon icon="fa-regular fa-bell" style={{ color: 'white' }} />
          </Link>
        </li> */}


        {/* ============================================================================================ */}
        <div className="nav-item dropdown" style={{ padding: '5px 30px 8px 4px', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center' }} >
          {/* <Link
            to="#"
            className="nav-link dropdown-toggle"
            onClick={toggleDropdownAler}
            data-bs-toggle="dropdown"
            aria-expanded={isDropdownOpenAler}
          >
            <i className="fa fa-bell me-lg-2"></i>
            <span className="d-none d-lg-inline-flex">Notification</span>
          </Link> */}
          <span>
            Hi, {username}
          </span>



          <div style={{ width: '200%', boxShadow: 'inset 5px 5px 10px rgba(0, 0, 0, 0.2)', marginLeft: '-100px', }}
            className={`dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom mt-4 ms-n3 ${isDropdownOpenAler ? 'show' : ''}`}>



            {/* ================= */}
            {isLoading ? (
              <LoadingIcon />
            ) : (
              <>

                {firstThreeNotifications.map((notification, index) => (
                  <Notification
                    key={index}
                    imageSrc={notification.image}
                    name={notification.first_name}
                    message={notification.address}
                    time={notification.updated_at}
                  />
                ))}
              </>
            )}

            {/* =============== */}
            <hr className="dropdown-divider" />
            <Link to="/admin/notificationpage" className="dropdown-item text-center" onClick={closeDropdownAler}>
              See all notifications
            </Link>



          </div>
        </div>
        {/* ============================================================================================ */}

        <li className={`nav-item dropdown ${isDropdownOpen ? 'show' : ''}`}>
          <Link
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            to="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded={isDropdownOpen}
            onClick={toggleDropdown}
          >
            {userImageUrl ? (
              <img src={`https://officeinteriorschennai.com/${userimage}`} alt="User" className="user-image" style={{ border: '3px solid white', borderRadius: '50px' }} />
            ) : (
              <i className="fas fa-user fa-fw"></i>
            )}
          </Link>
          <ul
            className={`dropdown-menu dropdown-menu-end ${isDropdownOpen ? 'show' : ''}`}
            aria-labelledby="navbarDropdown"
            style={{ marginLeft: '-95px' }}>
            {/* <li>
              <Link className="dropdown-item" to="/admin/settings">
                Settingss
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="/admin/userprofile">
                Activity Log
              </Link>
            </li> */}


            {/* <li>
              <hr className="dropdown-divider" />
            </li> */}

            <li>
              <Link className="dropdown-item" to="/admin/userprofile"> Profile</Link>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>

            <li>
              <Link className="dropdown-item" to="/" onClick={handleLogout}>
                Logout
              </Link>
            </li>
          </ul>
        </li>
      </ul>


    </nav >

  )
}

export default Navbar;
