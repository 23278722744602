import React from 'react'
import EmployeeDetailsComponent from './EmployeeDetailsComponent'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { ScaleLoader } from 'react-spinners';

export default function EmployeeDetails() {


  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  
  const [activeStatus, setActiveStatus] = useState('Active');



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://officeinteriorschennai.com/api.php?employeelistactiveinactive=${activeStatus}`
        );
        // console.log(response)
        setEmployeeData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [activeStatus]);

  // console.log(employeeData);


  const filteredEmployees = employeeData.filter(employee =>
    employee.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '65px 75px 0px 75px' }}>
        <h3>Employee Details</h3>

        <span style={{display:'flex', gap:'2rem'}}>
          <div>
            <label style={{fontSize:'21px', fontWeight:'500', paddingRight:'10px'}}>Status:</label>
            <select
              value={activeStatus}
              onChange={(e) => setActiveStatus(e.target.value)}
              style={{ marginBottom: '20px', border: '1px solid black', padding: '5px 7px', boxShadow: 'black 0px 0px 10px 1px' }}
            >
              <option value="Active">Active</option>
              <option value="In-Active">In-Active</option>
            </select>
          </div>
          <input
            type="text"
            placeholder="Search employee..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{ marginBottom: '20px', border: '1px solid black', padding: '5px 7px', boxShadow: 'black 0px 0px 10px 1px' }}
          />

        </span>
      </div>

      {loading ? (
        <div style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#f6f6f6'
        }}>
          <ScaleLoader color="#36d7b7" />
        </div>
      ) : (


        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', paddingTop: '60px' }}>
          {filteredEmployees.map((employee) => (
            <EmployeeDetailsComponent
              key={employee.id}
              name={employee.first_name}
              role={employee.department_name}
              mobile={employee.phone_no}
              email={employee.email}
              picture={employee.image}
              id={employee.id}
              empid={employee.epkemployee_id}
            />
          ))}
        </div>


      )}


    </>
  )
}