import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../../assets/admin/css/Add_employee.css';
import { useNavigate } from 'react-router-dom';

const ProfilePersonalDetails = () => {
  // Default Data
  const defaultEmployeeData = {
    employeeId: 'EPK0001',
    dob: '1999-27-05',
    gender: 'Male',
    mobileNumber: '1234567890',
    email: 'example@epkgroup.in',
    address: '123 Main St',
    maritalStatus: 'Single',
    joinDate: '2020-01-01',
    spouseName: 'Jane Doe',
    accountNumber: '9876543210',
    bankName: 'Indian Bank',
    bankBranch: 'Thounsand Light Branch',
    ifscCode: 'ABC1234567',
    accountType: 'Savings',
  };

  // Basic Details
  const [employeeId, setEmployeeId] = useState(defaultEmployeeData.employeeId);
  const [dob, setDob] = useState(defaultEmployeeData.dob);
  const [gender, setGender] = useState(defaultEmployeeData.gender);
  const [mobileNumber, setMobileNumber] = useState(defaultEmployeeData.mobileNumber);
  const [email, setEmail] = useState(defaultEmployeeData.email);
  const [address, setAddress] = useState(defaultEmployeeData.address);

  // Personal Details
  const [maritalStatus, setMaritalStatus] = useState(defaultEmployeeData.maritalStatus);
  const [joinDate, setJoinDate] = useState(defaultEmployeeData.joinDate);
  const [spouseName, setSpouseName] = useState(defaultEmployeeData.spouseName);

  // Bank Account Details
  const [accountNumber, setAccountNumber] = useState(defaultEmployeeData.accountNumber);
  const [bankName, setBankName] = useState(defaultEmployeeData.bankName);
  const [bankBranch, setBankBranch] = useState(defaultEmployeeData.bankBranch);
  const [ifscCode, setIfscCode] = useState(defaultEmployeeData.ifscCode);
  const [accountType, setAccountType] = useState(defaultEmployeeData.accountType);

  const handleCancel = () => {
    // Add logic to cancel the edit
    // You might want to clear the form or navigate back to the employee list page
  };

  const handleSaveChanges = () => {
    // Add logic to save the changes
    // You can send an API request to the server or handle it as per your backend setup
    const updatedEmployeeDetails = {
      employeeId,
      dob,
      gender,
      mobileNumber,
      email,
      address,
      maritalStatus,
      joinDate,
      spouseName,
      accountNumber,
      bankName,
      bankBranch,
      ifscCode,
      accountType,
    };
    // console.log('Updated Employee Details:', updatedEmployeeDetails);
    // You might want to reset the form after successful changes
  };


  const navigate = useNavigate();

  const handleVisitedit = () => {
    // Use navigate function to navigate to the '/profile' route
    navigate('/admin/editemployee');
  };

  return (
    <div className="container mt-4" style={{ display: 'grid', maxWidth: '1114px', margin: '0 auto',boxShadow:'0px 0px 10px 1px black' }}>
      
      {/* Basic Details */}
      <h4 style={{paddingTop:'36px'}}>Basic details</h4>
      <div className="row mb-3">
        {/* Employee ID */}
        <div className="col-md-6 mb-3">
          <label className="form-label">Employee ID</label>
          <input type="text" className="form-control" value={employeeId} readOnly />
        </div>
        {/* Date Of Birth */}
        <div className="col-md-6 mb-3">
          <label className="form-label">Date Of Birth</label>
          <input type="text" className="form-control" value={dob} readOnly />
        </div>
      </div>

      {/* Gender */}
      <div className="row mb-3">
        <div className="col-md-6 mb-3">
          <label className="form-label">Gender</label>
          <input type="text" className="form-control" value={gender} readOnly />
        </div>
        {/* Mobile number */}
        <div className="col-md-6 mb-3">
          <label className="form-label">Mobile Number</label>
          <input type="number" className="form-control" value={mobileNumber} readOnly />
        </div>
      </div>

      {/* Email */}
      <div className="row mb-3">
        <div className="col-md-6 mb-3">
          <label className="form-label">Email Id</label>
          <input type="email" className="form-control" value={email} readOnly />
        </div>
        {/* Address */}
        <div className="col-md-6 mb-3">
          <label className="form-label">Address</label>
          <input type="text" className="form-control" value={address} readOnly />
        </div>
      </div>

      {/* Add other basic details fields similarly */}

      {/* Personal Details */}
      <h4>Personal Details</h4>
      <div className="row mb-3">
        {/* Marital Status */}
        <div className="col-md-6 mb-3">
          <label className="form-label">Marital Status</label>
          <input type="text" className="form-control" value={maritalStatus} readOnly />
        </div>
        {/* Date of join */}
        <div className="col-md-6 mb-3">
          <label className="form-label">Date of join</label>
          <input type="text" className="form-control" value={joinDate} readOnly />
        </div>
      </div>

      {/* Spouse Name */}
      <div className="row mb-3">
        <div className="col-md-6 mb-3">
          <label className="form-label">Spouse Name</label>
          <input type="text" className="form-control" value={spouseName} readOnly />
        </div>
      </div>

      {/* Add other personal details fields similarly */}

      {/* Bank Account Details */}
      <h4>Bank Account Details</h4>
      <div className="row mb-3">
        {/* Account Number */}
        <div className="col-md-6 mb-3">
          <label className="form-label">Account Number</label>
          <input type="text" className="form-control" value={accountNumber} readOnly />
        </div>
        {/* Bank Name */}
        <div className="col-md-6 mb-3">
          <label className="form-label">Bank Name</label>
          <input type="text" className="form-control" value={bankName} readOnly />
        </div>
      </div>

      {/* Bank Branch */}
      <div className="row mb-3">
        <div className="col-md-6 mb-3">
          <label className="form-label">Bank Branch</label>
          <input type="text" className="form-control" value={bankBranch} readOnly />
        </div>
        {/* IFSC Code */}
        <div className="col-md-6 mb-3">
          <label className="form-label">IFSC Code</label>
          <input type="text" className="form-control" value={ifscCode} readOnly />
        </div>
      </div>

      {/* Account Type */}
      <div className="row mb-3">
        <div className="col-md-6 mb-3">
          <label className="form-label">Account Type</label>
          <input type="text" className="form-control" value={accountType} readOnly />
        </div>
      </div>

      {/* Buttons */}
      <div className="mb-3">
        
        <button className="btn btn-primary"  onClick={handleVisitedit}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default ProfilePersonalDetails;
