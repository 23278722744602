import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddEventTable from './AddEventTable';
import axios from 'axios';
import { MultiSelect } from 'react-multi-select-component';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

const EditEvent = () => {

    const navigate = useNavigate();

    const GotoAddEventPage = () => {
        navigate(`/admin/addevent`);
    };

    const { id } = useParams();


    const [title, setTitle] = useState('');
    const [teamsDropdown, setTeamsDropdown] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [membersDropdown, setMembersDropdown] = useState([]);
    const [selectedMember, setSelectedMember] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [eventAgenda, setEventAgenda] = useState('');
    const [eventImage, setEventImage] = useState('');
    const [eventPhotoID, setEvenetPhotoID] = useState([]);

    const userData = JSON.parse(localStorage.getItem('userData'));

    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(`https://officeinteriorschennai.com/api.php?vieweditevent=${id}`)
            .then(res => {
                if (res.status === 200) {
                    setData(res.data.data[0]);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [id]);


    const [selectedImages1, setSelectedImages1] = useState([]);

    useEffect(() => {
        if (data) {
            const teamNameArray = data.event_teamname ? data.event_teamname.split(',').map(team => team.trim()) : [];
            const teamMemberArray = data.event_teammember ? data.event_teammember.split(',').map(member => member.trim()) : [];
            setSelectedTeam(teamNameArray);
            setSelectedMember(teamMemberArray);
            setEventDate(data.event_date);
            setStartTime(data.start_time);
            setEndTime(data.end_time);
            setEventAgenda(data.event_reason);
            setTitle(data.event_title);
            setEvenetPhotoID(data.event_photoid);
            if (data.pvaluename) {
                const bannerImagesString = data.pvaluename;
                const imageFilenames = bannerImagesString.split(",");
                const urlPrefix = "https://officeinteriorschennai.com/assets/Events_photo/";
                const fullPaths = imageFilenames.map(filename => urlPrefix + filename);
                setSelectedImages1(fullPaths);
            }
        }
    }, [data]);


    // console.log(selectedImages1);


    // ---------------------------------  Teams Dropdown ----------------------------------

    useEffect(() => {
        const apiUrl = 'https://officeinteriorschennai.com/api.php?teamlist';
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                const data = response.data.data;
                setTeamsDropdown(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    const formattedTeamDropdown = teamsDropdown.map(team => ({
        label: team.department_name,
        value: team.department_id
    }));

    const handleSelectTeamChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        setSelectedTeam(selectedIds);
    };

    const formattedSelectedTeam = selectedTeam ? selectedTeam.join(',') : null;

    //------------------------  Members Dropdown ------------------------------


    useEffect(() => {
        const apiUrl = `https://officeinteriorschennai.com/api.php?memberdata=${formattedSelectedTeam}`;
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                const data = response.data.data;
                setMembersDropdown(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [formattedSelectedTeam]);


    const formattedMembersDropdown = membersDropdown.map(team => ({
        label: team.first_name,
        value: team.id
    }));

    const handleSelectMemberChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        setSelectedMember(selectedIds);
    };

    const formattedSelectedMembers = selectedMember ? selectedMember.join(',') : null;

    //-------------------------------------------------------------------------------

    const pickImage = (e) => {
        setEventImage(e.target.files[0]);
    };

    //--------------------------------------------------------------------------------


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedTeam.length > 0 && selectedMember.length > 0 && title.trim() !== '' && eventDate.trim() !== '' && startTime.trim() !== '' && endTime.trim() !== '' && eventAgenda.trim() !== '' && userData.userempid) {

            const formData = new FormData();

            formData.append('id', id);
            formData.append('event_teamname', selectedTeam);
            formData.append('event_teammember', selectedMember);
            formData.append('event_title', title);
            formData.append('event_date', eventDate);
            formData.append('start_time', startTime);
            formData.append('end_time', endTime);
            formData.append('event_reason', eventAgenda);
            formData.append('added_hr_id', userData.userempid);
            formData.append(' event_images', eventImage);

            try {
                const response = await fetch('https://officeinteriorschennai.com/api.php?editevent', {
                    method: 'POST',
                    body: formData,
                });

                const data = await response.json();

                if (data.status === 'success') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: `Event Added Successfully..`,
                    });
                    GotoAddEventPage();
                } else {
                    throw new Error(`Can't able to add event..`);
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Error: ${error.message}`,
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill in all the required fields.',
            });
        }
    };

    //--------------------------------------------------------------------------


    const [tableData, setTableData] = useState([]);

    const fetchTableData = async () => {

        const apiUrl = `https://officeinteriorschennai.com/api.php?eventlistempid=${userData.userempid}&&eventlistroleid=${userData.userrole}`;
        try {
            const response = await axios.get(apiUrl);
            const data = response.data.data;
            setTableData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchTableData();
    }, []);

    return (

        <div className="container mt-5" style={{ padding: '0px 110px' }}>

            <div style={{ boxShadow: '#0000007d 0px 0px 10px 1px', padding: '10px 50px' }}>
                <h2 style={{ textAlign: 'center' }}>Edit Event</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="eventtitle" className="form-label">
                            Event Title
                        </label>
                        <input
                            type='text'
                            className="form-control"
                            id="eventtitle"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        ></input>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="selectedTeam" className="form-label">
                            Select Teams
                        </label>
                        <MultiSelect
                            options={formattedTeamDropdown}
                            value={formattedTeamDropdown.filter(option => selectedTeam.includes(option.value))}
                            onChange={handleSelectTeamChange}
                            labelledBy="Select"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="selectedMember" className="form-label">
                            Select Members
                        </label>
                        <MultiSelect
                            options={formattedMembersDropdown}
                            value={formattedMembersDropdown.filter(option => selectedMember.includes(option.value))}
                            onChange={handleSelectMemberChange}
                            labelledBy="Select"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="eventDate" className="form-label">
                            Event Date
                        </label>
                        <input
                            type="date"
                            className="form-control"
                            id="eventDate"
                            value={eventDate}
                            onChange={(e) => setEventDate(e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="startTime" className="form-label">
                            Tentative Start Time
                        </label>
                        <input
                            type="time"
                            className="form-control"
                            id="startTime"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}

                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="endTime" className="form-label">
                            Tentative End Time
                        </label>
                        <input
                            type="time"
                            className="form-control"
                            id="endTime"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}

                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="eventAgenda" className="form-label">
                            Enter Event Agenda
                        </label>
                        <textarea
                            className="form-control"
                            id="eventAgenda"
                            rows="3"
                            value={eventAgenda}
                            onChange={(e) => setEventAgenda(e.target.value)}

                        ></textarea>
                    </div>

                    {/* <div className="col-md-6 mb-3">
                       
                        <input
                            type="file"
                            accept="image/*"
                            onChange={pickImage}
                            className="form-control"
                        />
                    </div> */}



{selectedImages1.length > 0 && (
    <div>
        <label className="form-label">Event Images</label>
        <div>
            {selectedImages1.map((image, index) => (
                <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}>
                    <img src={image} alt={`Image ${index}`} style={{ width: '200px', height: '200px' }} />
                </div>
            ))}
        </div>
    </div>
)}



                    <button type="submit" className="btn btn-primary mt-3">
                        Submit
                    </button>


                </form>
            </div>
        </div>
    );
};

export default EditEvent;