import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './Authentication'
import './Login.css';
import '../../../assets/admin/css/Login.css';
// import logoImage from '../assets/EPK_group_Logo.png';
import logoImage from '../../../assets/admin/assets/img/EPK_group_Logo.png';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


export default function Login() {

    const navigate = useNavigate();

    const {
        username,
        setLoggedInTime,
        setLoggedOutTime,
        setUsername,
        setUserepkempid,
        setUsermobile,
        setUserrole,
        setUserdepartment,
        setUserempid,
        setTotalHours,
        setUserimage,
        setUserdepartmentname,
        setUseralreadyLoggedIn,
    } = useAuth();

    const [employeeId, setEmployeeId] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [employeeIdError, setEmployeeIdError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // useEffect(() => {
    //     const userData = JSON.parse(localStorage.getItem('userData'));
    //     if (userData) {
    //         setUsername(userData.username);
    //         setUserepkempid(userData.userepkempid);
    //         setUsermobile(userData.usermobile);
    //         setUserrole(userData.userrole);
    //         setUserdepartment(userData.userdepartment);
    //         setUserempid(userData.userempid);
    //         setUserimage(userData.userimage);
    //         setLoggedInTime(userData.userempcheckintime);
    //         setLoggedOutTime(userData.userempcheckouttime);
    //         setTotalHours(userData.userempchecktotaltime);
    //         setUserdepartmentname(userData.userdepartmentname);
    //         setUseralreadyLoggedIn(userData.usercheckincurrentdate);
    //         // navigate('/admin');

    //     }
    // }, [navigate, setLoggedInTime, setLoggedOutTime, setTotalHours, setUsername, setUserepkempid, setUsermobile, setUserrole, setUserdepartment, setUserempid, setUserimage, setUserdepartmentname, setUseralreadyLoggedIn]);



    // ===================================================================

    const handleLogin = async () => {

        try {
            if (!employeeId) {
                setEmployeeIdError('Employee ID is required');
                return;
            } else {
                setEmployeeIdError('');
            }

            if (!password) {
                setPasswordError('Password is required');
                return;
            } else {
                setPasswordError('');
            }
           
            const apiUrl = `https://officeinteriorschennai.com/api.php?loginepkempid=${employeeId}&&loginpassword=${password}`;
            const response = await axios.get(apiUrl);

            const data = response.data;
            

            if (response.data.data !== 0) {

                // setUsername(data.username);
                // setUserepkempid(data.userepkempid);
                // setUsermobile(data.usermobile);
                // setUserrole(data.userrole);
                // setUserdepartment(data.userdepartment);
                // setUserempid(data.userempid);
                // setUserimage(data.userimage);
                // setLoggedInTime(data.userempcheckintime);
                // setLoggedOutTime(data.userempcheckouttime);
                // setTotalHours(data.userempchecktotaltime);
                // setUserdepartmentname(data.userdepartmentname);
                // setUseralreadyLoggedIn(data.usercheckincurrentdate);

                // // Save data to local storage
                localStorage.setItem('userData', JSON.stringify(data));
         

                localStorage.setItem('isAuthenticated', true);


                navigate('/admin');
                // Show success popup using SweetAlert
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Login successful!',
                    timer: 2000, // Specify the duration in milliseconds
                    showConfirmButton: false, // Hide the "OK" button
                });

            } else {
                // alert('Password or ID Incorrect');
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Password or ID Incorrect',
                });
            }
        } catch (error) {
            console.error('An error occurred:', error.message);
            // alert('An unexpected error occurred. Please try again later.');
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'An unexpected error occurred. Please try again later.',
            });
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };


    return (

        <div className="full-body">
            <div className="login-container">
                <div className="screen">


                    <div className="screen__content">


                        <div style={{ width: '320px', padding: '30px', paddingTop: '156px' }}>
                            <img src={logoImage} alt='' style={{ width: '100%', height: '92px' }} />



                            <div className="login__field">
                                <i className="login__icon fas fa-user"></i>
                                <input
                                    type="text"

                                    value={employeeId}
                                    onChange={(e) => setEmployeeId(e.target.value)}
                                    className="login__input"
                                    placeholder="User ID"
                                />

                                <p className="error-text">{employeeIdError}</p>
                            </div>


                            <div className="login__field">
                                <i className="login__icon fas fa-lock"></i>
                                <input
                                    type={passwordVisible ? 'text' : 'password'}

                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    className="login__input"
                                    placeholder="Password"
                                />
                                <button
                                    type="button"
                                    className="password-toggle-btn"
                                    onClick={togglePasswordVisibility}
                                    style={{background:'transparent', borderColor:'transparent'}}
                                >
                                    {passwordVisible ? <FontAwesomeIcon icon={faEye} style={{color:'white'}}/> : <FontAwesomeIcon icon={faEyeSlash}  style={{color:'white'}}/>}
                                </button>
                                <p className="error-text">{passwordError}</p>
                            </div>




                            <button className="button login__submit" type="submit" onClick={handleLogin}>
                                <span className="button__text">Log In Now</span>
                                <i className="button__icon fas fa-chevron-right"></i>
                            </button>
                        </div>


                    </div>



                    <div className="screen__background">
                        <span className="screen__background__shape screen__background__shape4"></span>
                        <span className="screen__background__shape screen__background__shape3"></span>
                        <span className="screen__background__shape screen__background__shape2"></span>
                        <span className="screen__background__shape screen__background__shape1"></span>
                    </div>
                </div>
            </div>
        </div>



    );
};
