import React from 'react';
import '../../assets/admin/css/Notification.css'

const Notification = ({ imageSrc, name, message, time }) => {
  return (
    <>
    
    
    <div className="notification">

      <div className="image-container">
        <img src={`https://officeinteriorschennai.com/${imageSrc}`} alt={name} className="user-image" />
      </div>
      <div className="content-container">
        <p className="user-name">{name}, <span className="message">{message}</span></p>
        
        <p className="time">{time}</p>
      </div>
    </div>
    </>
  );
};

export default Notification;
