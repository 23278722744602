import React from 'react';
import profileimg from "../../assets/admin/assets/img/profile.png";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import ProfilePersonalDetails from './ProfilePersonalDetails';


export default function Profile() {
  return (
    <>
    <div className='container mt-5'>
      <div class="row justify-content-md-center" style={{ margin: '20px',boxShadow:'0px 0px 13px 1px #525252' }}>
        <div className='row' style={{paddingTop:'13px'}}>

          <div className='col-md-2 shadow' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height:'13vh' }}>
            <div className='profile-img'>
              <img src={profileimg} alt='profile' />
            </div>
          </div>

          <div className='col-md-4' style={{display:'flex',alignItems:'center'}}>
            <div className='employee-detail'>
              <h5><strong style={{color:'#525252'}}>Name:</strong> Karthik</h5>
              <h5><strong style={{color:'#525252'}}>Role:</strong> Developer</h5>
              <h5><strong style={{color:'#525252'}}>D.O.B:</strong> 12-08-1992</h5>
              <h5><strong style={{color:'#525252'}}>Employee Id:</strong> EPK0001</h5>
            </div>
          </div>


          <div className='col-md-3 shadow' style={{display:'flex',alignItems:'center',background:'#81c381'}}>
            <div className='employee-present' >
              <h5><strong>No of days present</strong></h5>
              <h1>26</h1>
            </div>
          </div>
          <div className='col-md-3 shadow' style={{display:'flex',alignItems:'center',background:'#e45858'}}>
            <div className='leave-left' >
              <h5><strong>Leave left</strong></h5>
              <h1>1</h1>
            </div>
          </div>



        </div>
        <hr className='my-3' style={{ width: '95%' }} />

        <div className='row' style={{paddingBottom:'13px'}}>

          <div className='col-md-3'>
            <div className='mobile-num'>

              <h5><strong style={{color:'#525252'}}><FontAwesomeIcon icon={faPhone} /> Mobile Number</strong></h5>
              <p style={{fontSize:'17px',fontWeight:'500'}}>+91 9500137777</p>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='email'>
              <h5><strong style={{color:'#525252'}}><FontAwesomeIcon icon={faEnvelope} /> Email Id</strong></h5>
              <p style={{fontSize:'17px',fontWeight:'500'}}>abcd@epkgroup.in</p>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='address'>
              <h5><strong style={{color:'#525252'}}><FontAwesomeIcon icon={faMapMarkerAlt} /> Address</strong></h5>
              <p style={{fontSize:'17px',fontWeight:'500'}}>No:624, Anna Salai 4th floor, Khivraj Building, Near, Gemini Flyover, Tamil Nadu 600006</p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='emp-status'>
              <h5><strong style={{color:'#525252'}}><FontAwesomeIcon icon={faUser} /> Employee Status</strong></h5>
              <p style={{fontSize:'17px',fontWeight:'500'}}>ACTIVE</p>
            </div>
          </div>

        </div>



      </div>

      <ProfilePersonalDetails />
    </div>

    

    </>
  );
}
