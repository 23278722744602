import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../../assets/admin/css/Add_employee.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useNavigate, useParams } from 'react-router-dom';

const EditEmployeeForm = () => {

    const navigate = useNavigate();

    const Gotouser_profile = () => {
        navigate(`/admin/user_profile/${id}`);
    };


    const { id } = useParams();

    // -------------------------------------------------------------------------------------
    // State variables initialization
    const [employee, setEmployee] = useState({
        // Basic Details
        employeeId: '',
        empimage: '',
        first_name: '',
        last_name: '',
        gender: '',
        phone_no: '',
        whatsapp: '',
        email: '',
        dob: '',

        // Permanent Address
        address: '',
        city: '',
        state: '',
        pincode: '',

        // Current Address
        current_address: '',
        current_city: '',
        current_state: '',
        current_pincode: '',

        // Personal Details
        parents: '',
        proofs_image: '',
        doj: '',
        marital_status: '',
        pan_number: '',
        uan_number: '',
        emp_salary: '',
        empgrosssalary: '',
        emppf: '',
        spouse_name: '',

        // User Create
        role: '',
        department_name: [],
        designation: '',
        supervisor: '',
        user_id: '',
        password: '',
        emp_status: '',

        // Bank Account Details
        bank_accountnumber: '',
        bank_name: '',
        bank_branch: '',
        ifsc_code: '',
        ac_type: ''
    });

    // -------------------------------------------------------------------------------------

    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const response = await fetch(`https://officeinteriorschennai.com/api.php?viewemployeelist=${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const responseData = await response.json();

                console.log("this is responseData", responseData);
                const foundEmployee = responseData.data.find(employee => employee.id === id);

                if (foundEmployee) {
                    setEmployee(foundEmployee);
                } else {
                    throw new Error('Employee not found');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchEmployee();
    }, [id]);

    // -------------------------------------------------------------------------------------


    // console.log("this is employe list", employee)
    // console.log("This is employee supervisor", employee.supervisor)
    // console.log("marital_status --->", employee.marital_status)

    // -------------------------------------------------------------------------------------

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployee(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // -------------------------------------------------------------------------------------



    // Basic Details
    const [employeeId, setEmployeeId] = useState('');
    const [empimage, setEmpimage] = useState(null);
    const [proofsImage, setProofsImage] = useState(null);

    // -------------------------------------------------------------------------------------

    const handleEditEmployee = async (e) => {
        // Validation
        if (!validateFormedit()) {
            // If validation fails, display error messages or handle appropriately
            return;
        }
        e.preventDefault();

        // Perform field validation

        const formData = new FormData();

        formData.append('id', id);
        formData.append('first_name', employee.first_name);
        formData.append('last_name', employee.last_name);
        formData.append('gender', employee.gender);
        formData.append('phone_no', employee.phone_no);
        formData.append('whatsapp', employee.whatsapp);
        formData.append('email', employee.email);
        formData.append('dob', employee.dob);
        formData.append('address', employee.address);
        formData.append('city', employee.city);
        formData.append('state', employee.state);
        formData.append('pincode', employee.pincode);
        formData.append('current_address', employee.current_address);
        formData.append('current_city', employee.current_city);
        formData.append('current_state', employee.current_state);
        formData.append('current_pincode', employee.current_pincode);
        formData.append('parents', employee.parents);
        formData.append('doj', employee.doj);
        formData.append('marital_status', employee.marital_status);
        formData.append('pan_number', employee.pan_number);
        formData.append('uan_number', employee.uan_number);
        formData.append('emp_salary', employee.emp_salary);
        formData.append('emppf', employee.emppf);
        formData.append('empgrosssalary', employee.empgrosssalary);
        formData.append('spouse_name', employee.spouse_name);
        formData.append('role', employee.role);
        formData.append('department_name', employee.department_name);
        formData.append('designation', employee.designation);
        formData.append('supervisor', employee.supervisor);
        formData.append('user_id', employee.user_id);
        formData.append('password', employee.password);
        formData.append('emp_status', employee.emp_status);
        formData.append('bank_accountnumber', employee.bank_accountnumber);
        formData.append('bank_name', employee.bank_name);
        formData.append('bank_branch', employee.bank_branch);
        formData.append('ifsc_code', employee.ifsc_code);
        formData.append('ac_type', employee.ac_type);
        formData.append('loginepkempid', employeeId);


        // Append the new image if selected
        if (empimage) {
            formData.append('empimage', empimage);
        } else if (employee && employee.image) {
            formData.append('oldimageprofile', employee.image);
        }


        // Append the new image if selected
        if (proofsImage) {
            formData.append('proofs_image', proofsImage);
        } else if (employee && employee.proofs_image) {
            formData.append('oldimageproof', employee.proofs_image);
        }



        // formData.append('proofs_image', proofs_image);

        // formData.append('empimage', imagePreview);
        // formData.append('proofs_image', filePreview);





        try {
            const response = await fetch('https://officeinteriorschennai.com/api.php?editemployee', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            // console.log(data);


            if (data.status === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Form submitted successfully',
                });
                Gotouser_profile();

            } else {
                console.error('Failed to submit form: ', response.statusText);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to submit form',
                });
            }
        } catch (error) {
            console.error('Error submitting form: ', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error submitting form. Please try again later.',
            });
        }
    };


    // -------------------------------------------------------------------------------------

    const validateFormedit = () => {


        // Guard against undefined employee object
        if (!employee) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Employee data is missing',
            });
            return false;
        }

        // first name

        if (!employee.first_name || !employee.first_name.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a first name',
            });
            return false;
        }

        // last name

        if (!employee.last_name || !employee.last_name.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a last name',
            });
            return false;
        }

        // gender

        if (!employee.gender || !employee.gender.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please Select a gender',
            });
            return false;
        }

        // Validate phone number format
        const phonePattern = /^\d{10}$/;
        if (!employee.phone_no || !phonePattern.test(employee.phone_no.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a valid 10-digit phone number',
            });
            return false;
        }

        // Validate whatsapp number format
        const whatsappphonePattern = /^\d{10}$/;
        if (!employee.whatsapp || !whatsappphonePattern.test(employee.whatsapp.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a valid 10-digit whatsapp number',
            });
            return false;
        }

        // Validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!employee.email || !emailPattern.test(employee.email.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a valid email address',
            });
            return false;
        }

        // Check if date of birth is provided
        if (!employee.dob || !employee.dob.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a date of birth',
            });
            return false;
        }

        // Validate address fields
        if (!employee.address || !employee.address.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter an address',
            });
            return false;
        }

        if (!employee.city || !employee.city.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a city',
            });
            return false;
        }

        if (!employee.state || !employee.state.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a state',
            });
            return false;
        }

        if (!employee.pincode || !employee.pincode.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a pincode',
            });
            return false;
        }

        if (!employee.current_address || !employee.current_address.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a current address',
            });
            return false;
        }

        if (!employee.current_city || !employee.current_city.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a current city',
            });
            return false;
        }

        if (!employee.current_state || !employee.current_state.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a current state',
            });
            return false;
        }

        if (!employee.current_pincode || !employee.current_pincode.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a current pincode',
            });
            return false;
        }

        if (!employee.parents || !employee.parents.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter parent information',
            });
            return false;
        }

        // if (!proofs_image) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error',
        //         text: 'Please upload proof images',
        //     });
        //     return false;
        // }

        if (!employee.doj || !employee.doj.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a date of joining',
            });
            return false;
        }

        if (!employee.marital_status || !employee.marital_status.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select marital status',
            });
            return false;
        }

        if (!employee.pan_number || !employee.pan_number.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter PAN number',
            });
            return false;
        }

        if (!employee.uan_number || !employee.uan_number.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter UAN number',
            });
            return false;
        }

        if (!employee.emp_salary || !employee.emp_salary.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter employee salary',
            });
            return false;
        }

        if (!employee.emppf || !employee.emppf.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter employee PF',
            });
            return false;
        }


        if (!employee.empgrosssalary || !employee.empgrosssalary.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter employee grosssalary',
            });
            return false;
        }



        if (employee.marital_status.trim() === 'married' && (!employee.spouse_name || !employee.spouse_name.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter spouse name',
            });
            return false;
        }


        if (!employee.role || !employee.role.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter role',
            });
            return false;
        }

        if (!employee.designation || !employee.designation.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter designation',
            });
            return false;
        }

        if (!employee.supervisor || !employee.supervisor.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter supervisor name',
            });
            return false;
        }



        // Validate email format
        const user_idemailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!employee.user_id || !user_idemailPattern.test(employee.user_id.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a valid Office mail address',
            });
            return false;
        }

        if (!employee.password || !employee.password.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter password',
            });
            return false;
        }

        if (!employee.emp_status || !employee.emp_status.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select employee status',
            });
            return false;
        }

        if (!employee.bank_accountnumber || !employee.bank_accountnumber.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter bank account number',
            });
            return false;
        }

        if (!employee.bank_name || !employee.bank_name.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter bank name',
            });
            return false;
        }

        if (!employee.bank_branch || !employee.bank_branch.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter bank branch',
            });
            return false;
        }

        if (!employee.ifsc_code || !employee.ifsc_code.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter IFSC code',
            });
            return false;
        }

        if (!employee.ac_type || !employee.ac_type.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select account type',
            });
            return false;
        }

        // if (!employee.employeeId || !employee.employeeId.trim()) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error',
        //         text: 'Please enter employee ID',
        //     });
        //     return false;
        // }

        return true; // Return true if all validations pass
    };




    const boxStyles = {
        background: 'linear-gradient(90deg, rgb(240, 223, 223), rgb(234, 234, 234), rgb(222, 222, 222))',
        borderRadius: '10px',
        padding: '20px 25px 0px 25px',
        marginTop: '35px'
    };


    // -------------------------------------------------------------------------------------

    // Fetch employee ID
    useEffect(() => {
        const fetchEmployeeId = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?epkemployeeid');
                setEmployeeId(response.data.data);
            } catch (error) {
                console.error('Error fetching employee ID:', error);
            }
        };

        fetchEmployeeId();
    }, []);

    // // Fetch user roles
    const [userRoleDropdown, setUserRoleDropdown] = useState([]);

    useEffect(() => {
        const fetchUserRoleDropdown = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?userroletype');
                const data = response.data.data.map(user => ({ id: user.id, name: user.name }));
                setUserRoleDropdown([{ id: '', name: 'Select User Role' }, ...data]);
            } catch (error) {
                console.error('Error fetching user roles:', error);
            }
        };

        fetchUserRoleDropdown();
    }, []);

    // // Fetch user departments

    const [userDepartmentDropdown, setUserDepartmentDropdown] = useState([]);


    useEffect(() => {
        const fetchUserDepartmentDropdown = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?department');
                const data = response.data.data.map(department => ({ id: department.department_id, name: department.department_name }));
                setUserDepartmentDropdown([{ id: '', name: 'Select User Department' }, ...data]);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        fetchUserDepartmentDropdown();
    }, []);


    const [userSupervisorDropdown, setUserSupervisorDropdown] = useState([]);

    useEffect(() => {
        const fetchSupervisors = async () => {
            try {
                const response = await axios.get(`https://officeinteriorschennai.com/api.php?supervisorlist=${employee.department_id}`);
                const data = response.data.data.map(supervisor => ({ id: supervisor.id, name: supervisor.first_name }));
                setUserSupervisorDropdown([{ id: '', name: 'Select Supervisor' }, ...data]);
            } catch (error) {
                console.error('Error fetching supervisors:', error);
            }
        }
        fetchSupervisors();
    }, [employee.department_id]);


    // -------------------------------------------------------------------------------------




    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setEmpimage(file);

            const reader = new FileReader();
            reader.onload = function (e) {
                document.querySelector("#img").setAttribute("src", e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (employee && employee.image) {
            document.querySelector("#img").setAttribute("src", `https://officeinteriorschennai.com/${employee.image}`);
        }
    }, [employee]);

    // ==============================


    const handleProofChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProofsImage(file);

            const reader = new FileReader();
            reader.onload = function (e) {
                document.querySelector("#proofImg").setAttribute("src", e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (employee && employee.proofs_image && !proofsImage) {
            document.querySelector("#proofImg").setAttribute("src", `https://officeinteriorschennai.com/${employee.proofs_image}`);
        }
    }, [employee, proofsImage]);



    // ==================================================================

    return (
        <>



            <div className="container mt-4" style={{ display: 'grid', maxWidth: '1035px', margin: '0 auto', boxShadow: '0px 0px 10px 1px black', marginBottom: '15px', paddingBottom: '10px' }}>
                <h2 style={{ textAlign: 'center' }}>Edit Employee</h2>







                {/* ================================================================================================== */}
                <div style={boxStyles}>

                    {/* Basic Details */}
                    <h4>Basic details</h4>
                    <div className="row mb-3">
                        {/* Employee ID */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Employee ID</label>
                            <input type='text' className="form-control" value={employee.epkemployee_id} readOnly />
                        </div>
                        {/* Profile */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Profile</label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="form-control"
                            />
                            <img src="" alt="" id="img" style={{ height: '150px' }} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* First Name */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">First Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                value={employee.first_name}
                                onChange={(e) => setEmployee({ ...employee, first_name: e.target.value })}
                            />


                        </div>

                        {/* Last Name */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Last Name</label>
                            <input type="text" className="form-control" name="last_name" value={employee.last_name} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Gender */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Gender</label>
                            <select className="form-select" name="gender" value={employee.gender} onChange={handleChange}>
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="other">Other</option>
                            </select>
                        </div>

                        {/* Phone Number */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Phone Number</label>
                            <input type="text" className="form-control" name="phone_no" value={employee.phone_no} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Whatsapp Number */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Whatsapp Number</label>
                            <input type="text" className="form-control" name="whatsapp" value={employee.whatsapp} onChange={handleChange} />
                        </div>

                        {/* Personal Email Id */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Personal Email Id</label>
                            <input type="email" className="form-control" name="email" value={employee.email} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Birthday */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Birthday</label>
                            <input type="date" className="form-control" name="dob" value={employee.dob} onChange={handleChange} />
                        </div>


                    </div>

                </div>


                {/* ================================================================================================== */}
                <div style={boxStyles}>

                    {/* Current Address */}
                    <h4>Current Address</h4>
                    <div className="row mb-3">
                        {/* Current Address */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Current Address</label>
                            <input type="text" className="form-control" name="current_address" value={employee.current_address} onChange={handleChange} />
                        </div>

                        {/* Current City */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Current City</label>
                            <input type="text" className="form-control" name="current_city" value={employee.current_city} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Current State */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Current State</label>
                            <input type="text" className="form-control" name="current_state" value={employee.current_state} onChange={handleChange} />
                        </div>

                        {/* Current Pincode */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Current Pincode</label>
                            <input type="text" className="form-control" name="current_pincode" value={employee.current_pincode} onChange={handleChange} />
                        </div>
                    </div>

                </div>
                {/* ================================================================================================== */}



                <div style={boxStyles}>

                    {/* Permanent Address */}
                    <h4>Permanent Address</h4>



                    <div className="row mb-3">
                        {/* Permanent Address */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Permanent Address</label>
                            <input type="text" className="form-control" name="address" value={employee.address} onChange={handleChange} />
                        </div>

                        {/* Permanent City */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Permanent City</label>
                            <input type="text" className="form-control" name="city" value={employee.city} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Permanent State */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Permanent State</label>
                            <input type="text" className="form-control" name="state" value={employee.state} onChange={handleChange} />
                        </div>

                        {/* Permanent Pincode */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Permanent Pincode</label>
                            <input type="text" className="form-control" name="pincode" value={employee.pincode} onChange={handleChange} />
                        </div>
                    </div>

                </div>


                {/* ================================================================================================== */}
                <div style={boxStyles}>

                    {/* Personal Details */}
                    <h4>Personal Details</h4>
                    <div className="row mb-3">
                        {/* Father/Guardian Name */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Father/Guardian Name</label>
                            <input type="text" className="form-control" name="parents" value={employee.parents} onChange={handleChange} />
                        </div>



                        {/* Proof */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Proof</label>
                            {/* <input type="file" className="form-control" value={proofs_image} onChange={(e) => setProofs_image(e.target.value)} /> */}
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleProofChange}
                                className="form-control"
                            />

                            <img src="" alt="" id="proofImg" style={{ height: '150px' }} />

                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Date of Join */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Date of Join</label>
                            <input type="date" className="form-control" name="doj" value={employee.doj} onChange={handleChange} />
                        </div>

                        {/* Martial Status */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Martial Status</label>
                            <select className="form-select" name="marital_status" value={employee.marital_status} onChange={handleChange}>
                                <option value="">Select Martial Status</option>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                                <option value="divorced">Divorced</option>
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* PAN Number */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">PAN Number</label>
                            <input type="text" className="form-control" name="pan_number" value={employee.pan_number} onChange={handleChange} />
                        </div>
                        {/* UAN Number */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">UAN Number</label>
                            <input type="text" className="form-control" name="uan_number" value={employee.uan_number} onChange={handleChange} />
                        </div>
                    </div>


                    {/* Conditional Rendering for Spouse Name */}

                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Net Salary</label>
                            <input type="text" className="form-control" name="emp_salary" value={employee.emp_salary} onChange={handleChange} />
                        </div>

                        <div className="col-md-6 mb-3">
                            {/* Gross salary */}
                            <label className="form-label">Gross salary</label>
                            <input type="text" className="form-control" name="empgrosssalary" value={employee.empgrosssalary} onChange={handleChange} />
                        </div>
                    </div>



                    <div className="row mb-3">
                        {/* PF */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">PF</label>
                            <select className="form-select" name="emppf" value={employee.emppf} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="NA">NA</option>
                                <option value="Applicable">Applicable</option>
                            </select>
                        </div>



                        {/* Spouse Name */}
                        {employee.marital_status === 'married' && (
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Spouse Name</label>
                                <input type="text" className="form-control" name="spouse_name" value={employee.spouse_name} onChange={handleChange} />
                            </div>
                        )}

                    </div>


                </div>


                {/* ================================================================================================== */}
                <div style={boxStyles}>

                    {/* User Create */}
                    <h4>User Create</h4>
                    <div className="row mb-3">
                        {/* Select User Role */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Select User Role</label>
                            <select className="form-select" name="role" value={employee.role} onChange={handleChange}>
                                {userRoleDropdown.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>

                        {/* Select User Department */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Select User Department</label>
                            <select className="form-select" name="department_id" value={employee.department_id} onChange={handleChange}>
                                {userDepartmentDropdown.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>

                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Designation */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Designation</label>
                            <input type="text" className="form-control" name="department_name" value={employee.department_name} onChange={handleChange} />
                        </div>

                        {/* Select Supervisor */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Select Supervisor</label>
                            <select className="form-select" name="supervisor" value={employee.supervisor} onChange={handleChange}>
                                {userSupervisorDropdown.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>

                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Office Mail Id */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Office Mail Id</label>
                            <input disabled type="email" className="form-control" name="user_id" value={employee.user_id} onChange={handleChange} />
                        </div>

                        {/* Office Mail Password */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Office Mail Password</label>
                            <input type="password" className="form-control" name="password" value={employee.password} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Select Status */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Select Status</label>
                            <select className="form-select" name="emp_status" value={employee.emp_status} onChange={handleChange}>
                                <option value="">Select Status</option>
                                {/* Add options for status */}
                                <option value="Active">Active</option>
                                <option value="In-Active">Inactive</option>
                            </select>
                        </div>
                    </div>

                </div>
                {/* ================================================================================================== */}
                <div style={boxStyles}>
                    {/* Bank Account Details */}
                    <h4>Bank Account Details</h4>
                    <div className="row mb-3">
                        {/* Bank Account Number */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Bank Account Number</label>
                            <input type="text" className="form-control" name="bank_accountnumber" value={employee.bank_accountnumber} onChange={handleChange} />
                        </div>

                        {/* Bank Name */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Bank Name</label>
                            <input type="text" className="form-control" name="bank_name" value={employee.bank_name} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* Bank Branch */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Bank Branch</label>
                            <input type="text" className="form-control" name="bank_branch" value={employee.bank_branch} onChange={handleChange} />
                        </div>

                        {/* IFSC Code */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">IFSC Code</label>
                            <input type="text" className="form-control" name="ifsc_code" value={employee.ifsc_code} onChange={handleChange} />
                        </div>
                    </div>

                    {/* Select Account Type */}
                    <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Select Account Type</label>
                            <select className="form-select" name="ac_type" value={employee.ac_type} onChange={handleChange}>
                                <option value="">Select Account Type</option>
                                {/* Add options for account types */}
                                <option value="Savings">Savings</option>
                                <option value="Current">Current</option>
                            </select>
                        </div>
                    </div>

                </div>
                {/* ================================================================================================== */}







                {/* Buttons */}
                <div className="mb-3" style={{ marginTop: '30px' }}>
                    <button className="btn btn-secondary me-2" onClick={Gotouser_profile}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={handleEditEmployee}>
                        Save
                    </button>
                </div>

            </div>
        </>
    );
};

export default EditEmployeeForm;
