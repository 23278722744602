import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddEventTable from './AddEventTable';
import axios from 'axios';
import { MultiSelect } from 'react-multi-select-component';
import Swal from 'sweetalert2';
import { FaTimes } from 'react-icons/fa'; // Importing the FaTimes icon from React Icons

const AddEvent = () => {
    const [title, setTitle] = useState('');
    const [teamsDropdown, setTeamsDropdown] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [membersDropdown, setMembersDropdown] = useState([]);
    const [selectedMember, setSelectedMember] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [eventAgenda, setEventAgenda] = useState('');
    const [eventImages, setEventImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);

    const userData = JSON.parse(localStorage.getItem('userData'));

    // ---------------------------------  Teams Dropdown ----------------------------------

    useEffect(() => {
        const apiUrl = 'https://officeinteriorschennai.com/api.php?teamlist';
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                const data = response.data.data;
                setTeamsDropdown(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    const formattedTeamDropdown = teamsDropdown.map(team => ({
        label: team.department_name,
        value: team.department_id
    }));

    const handleSelectTeamChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        setSelectedTeam(selectedIds);
    };

    const formattedSelectedTeam = selectedTeam ? selectedTeam.join(',') : null;

    //------------------------  Members Dropdown ------------------------------

    useEffect(() => {
        const apiUrl = `https://officeinteriorschennai.com/api.php?memberdata=${formattedSelectedTeam}`;
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                const data = response.data.data;
                setMembersDropdown(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [formattedSelectedTeam]);


    const formattedMembersDropdown = membersDropdown.map(team => ({
        label: team.first_name,
        value: team.id
    }));

    const handleSelectMemberChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        setSelectedMember(selectedIds);
    };

    const formattedSelectedMembers = selectedMember ? selectedMember.join(',') : null;

    //-------------------------------------------------------------------------------

    const pickImage = (e) => {
        const files = Array.from(e.target.files);
        const filteredFiles = files.filter(file => !eventImages.find(img => img.name === file.name)); // Filter out duplicates
        setEventImages([...eventImages, ...filteredFiles]);
        const previews = filteredFiles.map(file => URL.createObjectURL(file));
        setImagePreviews([...imagePreviews, ...previews]);
    };

    const cancelImage = (index) => {
        const updatedImages = [...eventImages];
        updatedImages.splice(index, 1);
        setEventImages(updatedImages);

        const updatedPreviews = [...imagePreviews];
        updatedPreviews.splice(index, 1);
        setImagePreviews(updatedPreviews);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedTeam.length > 0 && selectedMember.length > 0 && title.trim() !== '' && eventDate.trim() !== '' && startTime.trim() !== '' && endTime.trim() !== '' && eventAgenda.trim() !== '' && userData.userempid) {
            const formData = new FormData();
            formData.append('event_teamname', selectedTeam);
            formData.append('event_teammember', selectedMember);
            formData.append('event_title', title);
            formData.append('event_date', eventDate);
            formData.append('start_time', startTime);
            formData.append('end_time', endTime);
            formData.append('event_reason', eventAgenda);
            formData.append('added_hr_id', userData.userempid);
            eventImages.forEach(image => formData.append('event_images[]', image));
            try {
                const response = await fetch('https://officeinteriorschennai.com/api.php?eventinsert', {
                    method: 'POST',
                    body: formData,
                });

                const data = await response.json();

                if (data.status === 'success') {

                    setTitle('');
                    setSelectedTeam([]);
                    setSelectedMember([]);
                    setEventDate('');
                    setStartTime('');
                    setEndTime('');
                    setEventAgenda('');
                    setEventImages([]);
                    setImagePreviews([]);

                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: `Event Added Successfully..`,
                    });
                    fetchTableData();
                } else {
                    throw new Error(`Can't able to add event..`);
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Error: ${error.message}`,
                });
            }

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill in all the required fields.',
            });
        }
    };

    //--------------------------------------------------------------------------

    const [tableData, setTableData] = useState([]);

    const fetchTableData = async () => {

        const apiUrl = `https://officeinteriorschennai.com/api.php?eventlistempid=${userData.userempid}&&eventlistroleid=${userData.userrole}`;
        try {
            const response = await axios.get(apiUrl);
            const data = response.data.data;
            setTableData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchTableData();
    }, []);


    return (

        <div className="container mt-5" style={{ padding: '0px 110px 40px' }}>

            <div style={{ boxShadow: '#0000007d 0px 0px 10px 1px', padding: '10px 50px' }}>
                <h2 style={{ textAlign: 'center' }}>Add Event</h2>


                <form onSubmit={handleSubmit}>

                    <div className="mb-3">
                        <label htmlFor="eventtitle" className="form-label">
                            Event Title
                        </label>
                        <input
                            type='text'
                            className="form-control"
                            id="eventtitle"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        ></input>
                    </div>


                    <div className="mb-3">
                        <label htmlFor="selectedTeam" className="form-label">
                            Select Teams
                        </label>
                        <MultiSelect
                            options={formattedTeamDropdown}
                            value={formattedTeamDropdown.filter(option => selectedTeam.includes(option.value))}
                            onChange={handleSelectTeamChange}
                            labelledBy="Select"
                        />
                    </div>


                    <div className="mb-3">
                        <label htmlFor="selectedMember" className="form-label">
                            Select Members
                        </label>
                        <MultiSelect
                            options={formattedMembersDropdown}
                            value={formattedMembersDropdown.filter(option => selectedMember.includes(option.value))}
                            onChange={handleSelectMemberChange}
                            labelledBy="Select"
                        />
                    </div>


                    <div className="mb-3">
                        <label htmlFor="eventDate" className="form-label">
                            Event Date
                        </label>
                        <input
                            type="date"
                            className="form-control"
                            id="eventDate"
                            value={eventDate}
                            onChange={(e) => setEventDate(e.target.value)}
                            required
                        />
                    </div>


                    <div className="mb-3">
                        <label htmlFor="startTime" className="form-label">
                            Tentative Start Time
                        </label>
                        <input
                            type="time"
                            className="form-control"
                            id="startTime"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}

                        />
                    </div>


                    <div className="mb-3">
                        <label htmlFor="endTime" className="form-label">
                            Tentative End Time
                        </label>
                        <input
                            type="time"
                            className="form-control"
                            id="endTime"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}

                        />
                    </div>



                    <div className="mb-3">
                        <label htmlFor="eventAgenda" className="form-label">
                            Enter Event Agenda
                        </label>
                        <textarea
                            className="form-control"
                            id="eventAgenda"
                            rows="3"
                            value={eventAgenda}
                            onChange={(e) => setEventAgenda(e.target.value)}

                        ></textarea>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Event Images</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={pickImage}
                            className="form-control"
                            multiple
                            
                        />
                        <div className="mt-2">
                            {imagePreviews.map((preview, index) => (
                                <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                                    <img src={preview} alt={`preview-${index}`} style={{ position: 'relative', maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} />
                                    <FaTimes className="cancel-icon" style={{ position: 'absolute', top: '0px', right: '10px', color: 'red' }} onClick={() => cancelImage(index)} />
                                </div>
                            ))}
                        </div>
                    </div>


                    <button type="submit" className="btn btn-primary">
                        Add Event
                    </button>


                </form>
            </div>

            {/* <AddEventTable tableData={tableData} setTableData={setTableData} fetchTableData={fetchTableData} /> */}


        </div>
    );
};

export default AddEvent;
