import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddMeetingTable from './AddMeetingTable';
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import Swal from 'sweetalert2';

const AddMeeting = () => {

    const [teamsDropdown, setTeamsDropdown] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [membersDropdown, setMembersDropdown] = useState([]);
    const [selectedMember, setSelectedMember] = useState('');
    const [meetingDate, setMeetingDate] = useState('');
    const [title, setTitle] = useState('');

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');


    const [meetingAgenda, setMeetingAgenda] = useState('');
    const [remarks, setRemarks] = useState('');

    const userData = JSON.parse(localStorage.getItem('userData'));

    // ---------------------------------  Teams Dropdown ----------------------------------

    useEffect(() => {
        const apiUrl = 'https://officeinteriorschennai.com/api.php?teamlist';
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                const data = response.data.data;
                setTeamsDropdown(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    const formattedTeamDropdown = teamsDropdown.map(team => ({
        label: team.department_name,
        value: team.department_id
    }));

    const handleSelectTeamChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        setSelectedTeam(selectedIds);
    };

    const formattedSelectedTeam = selectedTeam ? selectedTeam.join(',') : null;


    //------------------------  Members Dropdown ------------------------------


    useEffect(() => {
        const apiUrl = `https://officeinteriorschennai.com/api.php?memberdata=${formattedSelectedTeam}`;
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                const data = response.data.data;
                setMembersDropdown(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [formattedSelectedTeam]);


    const formattedMembersDropdown = membersDropdown.map(team => ({
        label: team.first_name,
        value: team.id
    }));

    const handleSelectMemberChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        setSelectedMember(selectedIds);
    };

    const formattedSelectedMembers = selectedMember ? selectedMember.join(',') : null;


    //-------------------------   Handle Submit -------------------------------

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            selectedTeam.length > 0 &&
            selectedMember.length > 0 &&
            title.trim() !== '' &&
            meetingDate.trim() !== '' &&
            startTime.trim() !== '' &&
            endTime.trim() !== '' &&
            meetingAgenda.trim() !== '' &&
            remarks.trim() !== '' &&
            userData &&
            userData.userempid
        ) {
            const formData = new FormData();
            formData.append('meeting_teamname', selectedTeam);
            formData.append('meeting_teammember', selectedMember);
            formData.append('meeting_title', title);
            formData.append('meeting_date', meetingDate);
            formData.append('start_time', startTime);
            formData.append('end_time', endTime);
            formData.append('meeting_reason', meetingAgenda);
            formData.append('meeting_remarks', remarks);
            formData.append('added_hr_id', userData.userempid);

            try {
                const response = await fetch('https://officeinteriorschennai.com/api.php?meetinginsert', {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                if (data.status === 'success') {
                    setSelectedTeam([]);
                    setSelectedMember([]);
                    setMeetingDate('');
                    setStartTime('');
                    setEndTime('');
                    setMeetingAgenda('');
                    setTitle('');
                    setRemarks('');
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: `Meeting Added Successfully..`,
                    });
                    fetchTableData();
                } else {
                    throw new Error(`Can't able to add Meeting..`);
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Error: ${error.message}`,
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill in all the required fields.',
            });
        }
    };

    const [tableData, setTableData] = useState([]);

    const fetchTableData = async () => {
        const apiUrl = `https://officeinteriorschennai.com/api.php?meetinglistempid=${userData.userempid}&&meetinglistroleid=${userData.userrole}`;
        try {
            const response = await axios.get(apiUrl);
            const data = response.data.data;
            setTableData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    return (
        <div className="container mt-5" style={{ padding: '0px 110px 40px' }}>
            <div style={{ boxShadow: '#0000007d 0px 0px 10px 1px', padding: '10px 50px' }}>
                <h2 style={{ textAlign: 'center' }}>Add Meeting</h2>


                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="meetingtitle" className="form-label">
                            Meeting Title
                        </label>
                        <input
                            type='text'
                            className="form-control"
                            id="meetingtitle"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}

                        ></input>
                    </div>

                    {/* Select Teams Dropdown */}
                    <div className="mb-3">
                        <label htmlFor="selectedTeam" className="form-label">
                            Select Teams
                        </label>
                        <MultiSelect
                            options={formattedTeamDropdown}
                            value={formattedTeamDropdown.filter(option => selectedTeam.includes(option.value))}
                            onChange={handleSelectTeamChange}
                            labelledBy="Select"
                        />
                    </div>

                    {/* Select Members Dropdown */}
                    <div className="mb-3">
                        <label htmlFor="selectedMember" className="form-label">
                            Select Members
                        </label>
                        <MultiSelect
                            options={formattedMembersDropdown}
                            value={formattedMembersDropdown.filter(option => selectedMember.includes(option.value))}
                            onChange={handleSelectMemberChange}
                            labelledBy="Select"
                        />
                    </div>

                    {/* Meeting Date */}
                    <div className="mb-3">
                        <label className="form-label">Meeting Date</label>
                        <input type="date" className="form-control" value={meetingDate} onChange={(e) => setMeetingDate(e.target.value)} />
                    </div>

                    {/* Tentative Start Time */}
                    <div className="mb-3">
                        <label htmlFor="startTime" className="form-label">
                            Tentative Start Time
                        </label>
                        <input
                            type="time"
                            className="form-control"
                            id="startTime"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}

                        />
                    </div>

                    {/* Tentative End Time */}
                    <div className="mb-3">
                        <label htmlFor="endTime" className="form-label">
                            Tentative End Time
                        </label>
                        <input
                            type="time"
                            className="form-control"
                            id="endTime"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}

                        />
                    </div>

                    {/* Meeting Agenda */}
                    <div className="mb-3">
                        <label htmlFor="meetingAgenda" className="form-label">
                            Enter Meeting Agenda
                        </label>
                        <textarea
                            className="form-control"
                            id="meetingAgenda"
                            rows="3"
                            value={meetingAgenda}
                            onChange={(e) => setMeetingAgenda(e.target.value)}

                        ></textarea>
                    </div>

                    {/* Remarks */}
                    <div className="mb-3">
                        <label htmlFor="remarks" className="form-label">
                            Remarks
                        </label>
                        <textarea
                            className="form-control"
                            id="remarks"
                            rows="3"
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        ></textarea>
                    </div>

                    {/* Submit Button */}
                    <button type="submit" className="btn btn-primary">
                        Add Meeting
                    </button>
                </form>
            </div>

            {/* <AddMeetingTable tableData={tableData} setTableData={setTableData} fetchTableData={fetchTableData} /> */}

        </div>
    );
};

export default AddMeeting;