import React, { useEffect, useState } from 'react';
import '../../assets/admin/css/Admindashboard.css';
import employee from '../../assets/admin/assets/img/employee.png';
import permission from "../../assets/admin/assets/img/absent.png";
import slow from "../../assets/admin/assets/img/late.png";
import { useNavigate } from 'react-router-dom';


export default function TeamLeads() {
  const navigate = useNavigate();

  const handleVisitattendancetable = () => {
    navigate('/admin/attendancerequest');
  };

  const handleVisitleavetable = () => {
    navigate('/admin/leaverequesttl');
  };

  const handleVisitpermissiontable = () => {
    navigate('/admin/permissionrequesttl');
  };

  const handleVisithalfdaytable = () => {
    navigate('/admin/halfdayrequestTL');
  };

  const dataBoxStyle = {
    cursor: 'pointer',
  };

  


  return (
    <div>


      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10%', paddingLeft: '10%', paddingTop: '5%', marginBottom: '40px' }}>

        <h1 >TL Approval</h1>
     
      </div>


    



      <div className='Tlapproval-dashboard'>


        

        <div className='data-box' onClick={handleVisitleavetable} style={dataBoxStyle}>
          <div className='box-content'>
            <span>
              <span className='img-alignment'>
                <img src={employee} alt='Ontime' className='dash-img' />
              </span>
              <h4 className='dash-title'>Leave Request</h4>
              <h4 className='dash-values'></h4>
            </span>
          </div>
        </div>


        <div className='data-box' onClick={handleVisitpermissiontable} style={dataBoxStyle}>
          <div className='box-content'>
            <span>
              <span className='img-alignment'>
                <img src={permission} alt='permission' className='dash-img' />
              </span>
              <h4 className='dash-title'>Permission Request</h4>
              <h4 className='dash-values'></h4>
            </span>
          </div>
        </div>

        <div className='data-box' onClick={handleVisithalfdaytable} style={dataBoxStyle}>
          <div className='box-content'>
            <span>
              <span className='img-alignment'>
                <img src={slow} alt='permission' className='dash-img' />
              </span>
              <h4 className='dash-title'>Half Day Request</h4>
              <h4 className='dash-values'></h4>
            </span>
          </div>
        </div>
      </div>

    


    </div>
  );
}