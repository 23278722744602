import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import paylogo from '../../assets/admin/assets/img/EPK_group_Logo.png';
import jsPDF from 'jspdf';
import numberToWords from 'number-to-words';
import 'jspdf-autotable';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function PaySlipEmp() {




    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const cellStyle = {
        border: '1px solid #8e8e8e',
    };


    // const currentDate = new Date();
    // const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const { pid } = useParams();
    const [loading, setLoading] = useState(true);
    const [attendanceData, setAttendanceData] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            try {

                const response = await axios.get(`https://officeinteriorschennai.com/api.php?viewsingleemppayslippdf=${pid}`);

                const { data } = response.data;
                setAttendanceData(data);
                // console.log('--------------', data);
                // console.log("API Response", data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };

        fetchData();
    }, []);


    const currentDate = new Date();
    //const monthval=12;
    const months = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];


    // Assuming attendanceData is defined elsewhere in your code
    const payslipmonthyearvalue = attendanceData[0]?.payslipmonthyear;
    let monthval = '';
    let currentMonth = '';
    let currentYear = '';

    if (payslipmonthyearvalue) {
        const [year, month] = payslipmonthyearvalue.split('-');
        monthval = month;
        currentYear = year;
        currentMonth = months[parseInt(monthval) - 1]; // Parse the monthval to integer before subtracting 1

    } else {
        console.error("payslipmonthyear is not defined or is empty");
    }


    const [employeeData, setEmployeeData] = useState({
        // uanNumber: '123456789123',
        // employeeId: 'EPK0005',
        // pfNumber: 'MHBAN00000640000000125',
        // dateOfJoining: '26-12-2022',
        // department: 'IT',
        // dateOfConfirmation: '26-12-2022',
    });

    useEffect(() => {
        if (attendanceData.length > 0) {
            setEmployeeData(prevState => ({
                ...prevState,
                pan: attendanceData[0]?.pan_number,
                uanNumber: attendanceData[0]?.uan_number,
                currentDesignation: attendanceData[0]?.emp_department_name,
                employeeName: attendanceData[0]?.emp_name,
                lop: attendanceData[0]?.emp_lop,
                workingdays: attendanceData[0]?.total_days_in_month,
            }));
        }
    }, [attendanceData]);


    // const [daysDetails, setDaysDetails] = useState({
    //     workingDays: 27,
    //     daysWorked: 26,
    //     lopDays: 1,
    //     arrearDays: 0,
    // });

    const tableData = [
        { description: 'Basic', amount: Math.round(attendanceData[0]?.basicda_amount), deductionDescription: 'Provident Fund', deductionAmount: attendanceData[0]?.emp_pf },
        { description: 'HRA', amount: Math.round(attendanceData[0]?.hra_amount), deductionDescription: '', deductionAmount: '' },
        { description: 'Transport Allowance', amount: attendanceData[0]?.transportallowance_amount, deductionDescription: '', deductionAmount: '' },
        { description: 'Conveyance Allowance', amount: attendanceData[0]?.conveyanceallowance_amount, deductionDescription: '', deductionAmount: '' },
        { description: 'Other Allowance', amount: Math.round(attendanceData[0]?.otherallowances_amount), deductionDescription: '', deductionAmount: '' },
        { description: 'Gross Pay', amount: attendanceData[0]?.empsalaryoverall_salary, deductionDescription: 'Total Deductions', deductionAmount: attendanceData[0]?.emp_pf },
        { description: '', amount: '', deductionDescription: 'Net Pay', deductionAmount: attendanceData[0]?.totalnetpay_amount },
    ];

    // console.log(attendanceData[0]?.totalnetpay_amount);

    // Function to convert numbers to words
    function numberToWords(number) {
        const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
        const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];

        if (number === 0) return 'Zero';

        let words = '';

        if (number >= 1000) {
            words += numberToWords(Math.floor(number / 1000)) + ' Thousand ';
            number %= 1000;
        }

        if (number >= 100) {
            words += ones[Math.floor(number / 100)] + ' Hundred ';
            number %= 100;
        }

        if (number >= 20) {
            words += tens[Math.floor(number / 10)] + ' ';
            number %= 10;
        } else if (number >= 11) {
            words += teens[number - 11] + ' ';
            number = 0;
        }

        if (number > 0) {
            words += ones[number] + ' ';
        }

        return words.trim();
    }

    console.log("attendanceData", attendanceData[0]?.totalnetpay_amount);

    const salary = attendanceData[0]?.totalnetpay_amount;
    const salaryInWords = numberToWords(salary);
    // console.log("Salary in words:", salaryInWords);

    // const salary = typeof attendanceData[0]?.totalnetpay_amount !== 'undefined' && attendanceData[0]?.totalnetpay_amount !== null ? attendanceData[0]?.totalnetpay_amount : '';

    // const totalNetPayable = typeof salary === 'number' ? Number(salary.toFixed(2)) : 0; // Assuming a default value of 0 if salary is not a valid number

    // const getTotalNetPayableInWords = (amount) => {
    //     const words = numberToWords.toWords(amount);
    //     return `(Rupees ${words} Only)`;
    // };




    // ========== PDF ============
    const pdfContainerRef = useRef(null);

    const handleExportToPDF = () => {
        const doc = new jsPDF();

        // Image
        const imgData = paylogo;  // Replace with the actual base64-encoded image data or use the paylogo variable if it contains base64 data

        // Set the image position and size
        const imgWidth = 70; // Adjust as needed
        const imgHeight = 30; // Adjust as needed
        const imgX = 15; // Adjust as needed
        const imgY = 15; // Adjust as needed


        // Add the image to the PDF
        doc.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight);

        // Content
        const EPKGroupX = imgX + imgWidth + 70; // Adjust the spacing between the image and content
        const contentX = imgX + imgWidth + 13; // Adjust the spacing between the image and content
        const currentMonthX = imgX + imgWidth + 20; // Adjust the spacing between the image and content
        const contentY = 20; // Adjust as needed

        doc.setFontSize(18);
        doc.text('EPK Group', contentX, contentY, { align: 'left', fontWeight: 'bold', fontSize: '30px' });
        doc.setFontSize(12);
        doc.text('No:624, Anna Salai 4th floor, Khivraj Building, Near, \nGemini Flyover, Tamil Nadu 600006', contentX, contentY + 10);
        doc.setFont('helvetica', 'bold');

        doc.text(`Salary Slip for the Month of ${currentMonth} - ${currentYear}`, contentX, contentY + 20);

        // Add padding at the bottom of the page





        {/* =========================================================================================================== */ }
        // Add two empty lines
        doc.text('', 15, 45);
        doc.text('', 15, 50);

        // Employee details
        const employeeDetailsHeaders = [];

        const employeeDetailsData = [
            ['Employee Name', employeeData.employeeName, 'UAN Number', employeeData.uanNumber],
            ['Desigination', employeeData.currentDesignation, 'PAN Number', employeeData.pan],
            ['No of working days', employeeData.workingdays, 'LOP day', employeeData.lop],
            // ['Current Location', employeeData.currentLocation, 'Date of Confirmation', employeeData.dateOfConfirmation],
        ];

        // Calculate the width for employeeDetailsData table (70% of page width)
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        const tableWidth = pageWidth * 0.9;
        const colWidths = [tableWidth * 0.22, tableWidth * 0.22, tableWidth * 0.2, tableWidth * 0.32]; // Adjust widths as needed

        doc.autoTable({
            head: employeeDetailsHeaders,
            body: employeeDetailsData,
            startY: 55, // Adjust startY to leave space after empty lines
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], },
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                cellStyles: { border: '1px solid black' },
                margin: { top: 40 }, // Add top padding of 20px
                alternateRowStyles: { fillColor: [255, 255, 255] }
            },
            didParseCell: function (data) {
                // Check if the content of the cell contains specific keys to be bolded
                const keysToBold = ['Employee Name', 'UAN Number', 'Desigination', 'PAN Number', 'No of working days', 'LOP day'];

                if (keysToBold.includes(data.cell.raw.toString())) {
                    // Apply bold styling to the text
                    data.cell.styles.fontStyle = 'bold';
                }
            },
            tableWidth: 'auto', // Set the width of the table automatically based on colWidths
            columnStyles: {
                0: { columnWidth: colWidths[0] },
                1: { columnWidth: colWidths[1] },
                2: { columnWidth: colWidths[2] },
                3: { columnWidth: colWidths[3] }
            }
        });

        // Add horizontal line
        // Set the line color
        doc.setDrawColor(199, 199, 199);  // RGB color for #c7c7c7

        // Draw a horizontal line
        const lineY = doc.autoTable.previous.finalY + 10;
        doc.setLineWidth(1);  // Set line thickness to 2px
        doc.line(15, lineY, 195, lineY);

        {/* =========================================================================================================== */ }

        {/* =========================================================================================================== */ }
        // Additional information
        // const additionalInfoData = [
        //     ['No.Of Working Days', daysDetails.workingDays, 'No of Days Worked', daysDetails.daysWorked, 'LOP Days', daysDetails.lopDays],
        //     // ['Arrear Days', daysDetails.arrearDays, '', '']
        // ];


        // doc.autoTable({
        //     body: additionalInfoData,
        //     startY: doc.autoTable.previous.finalY + 10,
        //     headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
        //     bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], cellStyles: { border: '1px solid black', fillColor: [255, 255, 255] } },
        //     didParseCell: function (data) {
        //         // Check if the content of the cell and data.cell object are defined
        //         if (data.cell && data.cell.raw) {
        //             // Check if the content of the cell contains specific keywords
        //             const keywordsToBold = ['No.Of Working Days', 'No of Days Worked', 'LOP Days', 'Arrear Days'];

        //             if (keywordsToBold.includes(data.cell.raw.toString())) {
        //                 // Apply bold styling to the text
        //                 data.cell.styles.fontStyle = 'bold';
        //             }
        //         }
        //     },

        // });

        {/* =========================================================================================================== */ }

        {/* =========================================================================================================== */ }

        // Table headers
        const earningsDeductionsHeaders = [
            [{ content: 'Earnings', colSpan: 2, styles: { halign: 'center', valign: 'middle', cellWidth: 45  } }, { content: 'Deductions', colSpan: 2, styles: { halign: 'center', valign: 'middle', cellWidth: 55 } }],
            ['Description', 'Amount', 'Description', 'Amount'],
        ];

       
        // Draw the table manually with borders
        const tableDataWithBorders = tableData.map(row => [' ' + row.description, ' ' + row.amount, ' ' + row.deductionDescription, ' ' + row.deductionAmount]);

        doc.autoTable({
            head: earningsDeductionsHeaders,
            body: tableDataWithBorders,
            startY: doc.autoTable.previous.finalY + 10,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.5 },
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                lineWidth: 0.5, cellStyles:
                    { border: '1px solid black' }
            },

            didParseCell: function (data) {
                if (data.column.index === 1 || data.column.index === 3) { // Check if it's the 'Amount' column
                    data.cell.styles.halign = 'right'; // Align content to the right
                }
            }
        });

        {/* =========================================================================================================== */ }

        {/* =========================================================================================================== */ }
        // In words
        doc.setFontSize(10);
        doc.setFillColor(233, 247, 231);
        // doc.text(`Total Net Payable ${totalNetPayable.toFixed(2)} ${getTotalNetPayableInWords(totalNetPayable)}`, 15, doc.autoTable.previous.finalY + 10);
        doc.text(`Total Net Payable ${salary} - ${salaryInWords}`, 15, doc.autoTable.previous.finalY + 10);

        // Add three empty lines
        doc.text('', 15, doc.autoTable.previous.finalY + 20);
        doc.text('', 15, doc.autoTable.previous.finalY + 25);
        doc.text('', 15, doc.autoTable.previous.finalY + 30);

        // Centered text
        doc.setFontSize(9);
        const centeredText = "-- This is a system generated payslip, hence the signature is not required. --";

        // Calculate the x-coordinate for centering
        const textWidth = doc.getTextDimensions(centeredText).w;
        const centerX = (doc.internal.pageSize.width - textWidth) / 2;

        // Place the centered text in the middle of the page
        doc.text(centeredText, centerX, doc.autoTable.previous.finalY + 35);
        {/* =========================================================================================================== */ }
        // Save the PDF
        doc.save(`PaySlip For ${currentMonth} - ${currentYear}.pdf`);
    };

    // ======================










    return (
        <>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10.5rem', marginTop: '50px', marginBottom: '-47px' }}>
                <button className="btn btn-primary" onClick={handleExportToPDF}>
                    Download
                </button>


            </div>
            <div className="container" style={{ width: '75%', marginTop: '55px', marginBottom: '2px', border: '1px solid grey' }} ref={pdfContainerRef}>
                <div className="row" style={{ backgroundColor: '#f7f8fa', padding: '22px' }}>
                    <div className="col-5">
                        <img src={paylogo} alt='' style={{ width: '86%', height: '108px' }} />
                    </div>
                    <div className="col-7" style={{ borderLeft: '2px solid #000' }}>
                        <span style={{ display: 'flex', flexDirection: 'column' }}>
                            <h4>EPK Group</h4>
                            <p style={{ display: 'flex', justifyContent: 'center', fontSize: '19px', fontWeight: '400' }}>
                                No:624,Anna Salai 4th floor,Khivraj Building, Near, Gemini Flyover, Tamil Nadu 600006
                            </p>
                            <h5>Salary Slip for the Month of {currentMonth} - {currentYear}</h5>
                        </span>
                    </div>
                </div>
                {/* =========================================================================================================== */}
                <div className="row" style={{ paddingTop: '20px' }}>
                    <div className="col-6" style={{width:'42%'}}>
                        <h6><span style={{color:'bold', fontWeight:'bold'}}>Employee Name:</span> {attendanceData[0]?.emp_name}</h6>
                        {/* <h6>Employee ID: {employeeData.employeeId}</h6> */}
                        <h6><span style={{color:'bold', fontWeight:'bold'}}>Designation:</span> {attendanceData[0]?.emp_department_name}</h6>
                        <h6><span style={{color:'bold', fontWeight:'bold'}}>No of Working Days:</span> {attendanceData[0]?.total_days_in_month}</h6>
                        {/* <h6>Current Location: {employeeData.currentLocation}</h6> */}
                    </div>
                    <div className="col-6">
                        <h6><span style={{color:'bold', fontWeight:'bold'}}>UAN number:</span> {attendanceData[0]?.uan_number}</h6>
                        <h6><span style={{color:'bold', fontWeight:'bold'}}>PAN Number:</span> {attendanceData[0]?.pan_number}</h6>
                        <h6><span style={{color:'bold', fontWeight:'bold'}}>LOP days  :</span> {attendanceData[0]?.emp_lop}</h6>
                        {/* <h6>Employee Name: {employeeData.employeeName}</h6>
                        <h6>Date Of Joining: {employeeData.dateOfJoining}</h6>
                        <h6>Department: {employeeData.department}</h6> */}
                        {/* <h6>Date of Confirmation: {employeeData.dateOfConfirmation}</h6> */}
                    </div>
                </div>
                {/* =========================================================================================================== */}

                {/* <hr style={{ marginTop: '20px', marginBottom: '20px', paddingBottom: '8px' }} /> */}

                {/* =========================================================================================================== */}
                {/* <div className="row">
                    <div className="col-4">
                        <h6>No of working Days: {daysDetails.workingDays}</h6>
                    </div>
                    <div className="col-4">
                        <h6>No of Days Worked: {daysDetails.daysWorked}</h6>
                    </div>
                    <div className="col-4">
                        <h6>LOP Days: {daysDetails.lopDays}</h6>
                    </div>
                    <div className="col-4"> */}
                {/* <h6>Arrear Days: {daysDetails.arrearDays}</h6> */}
                {/* </div>
                </div> */}
                {/* =========================================================================================================== */}

                <hr style={{ marginTop: '20px', marginBottom: '20px', paddingBottom: '8px', color: 'white' }} />
                {/* =========================================================================================================== */}

                <table className="table" style={tableStyle}>
                    <thead>
                        <tr>
                            <th colSpan="2" scope="col" style={{ textAlign: 'center', border: '1px solid #8e8e8e' }}>
                                Earnings
                            </th>
                            <th colSpan="2" scope="col" style={{ textAlign: 'center', border: '1px solid #8e8e8e' }}>
                                Deduction
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={{...cellStyle, width:'25%'}}>Description</th>
                            <th style={{...cellStyle, textAlign: 'right', width:'12%'}}>Amount</th>
                            <th style={{...cellStyle, width:'25%'}}>Description</th>
                            <th style={{...cellStyle, textAlign: 'right', width:'25%'}}>Amount</th>
                        </tr>
                        {tableData.map((row, index) => (
                            <tr key={index}>
                                <td style={cellStyle}>{row.description}</td>
                                <td style={{...cellStyle, textAlign: 'right'}}>{row.amount}</td>
                                <td style={cellStyle}>{row.deductionDescription}</td>
                                <td style={{...cellStyle, textAlign: 'right'}}>{row.deductionAmount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p style={{ fontSize: '14px', padding: '8px 0px', fontWeight: '500', backgroundColor: '#e9f7e7', }}>
                    <span style={{ color: 'rgb(2, 156, 16)', fontWeight:'bold' }}>|</span>
                    Total Net Payable <span style={{color:'rgb(2, 156, 16)', fontWeight:'bold'}}>{salary}</span> - {salaryInWords}
                    {/* {getTotalNetPayableInWords(totalNetPayable)} */}
                </p>
            </div>

        </>
    );
}
