import React, { useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ReactPaginate from 'react-paginate';
import { useEffect } from 'react';
import axios from 'axios';
import { ScaleLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowLeft, faChevronLeft, faChevronRight, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';



export default function YearlyAttendance(props) {



    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState(''); //search filter
    const componentRef = React.useRef();  //download options
    const [loading, setLoading] = useState(true);




    const [attendanceData, setAttendanceData] = useState([]); // State to store attendance data



    // ===================================================================================


    // ===================================================================================

    // ===================

    // Retrieve userData from local storage
    const userData = JSON.parse(localStorage.getItem('userData'));




    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); // Default to current month
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        fetchData();
    }, [currentMonth, currentYear]); // Refetch data when month or year changes

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://officeinteriorschennai.com/api.php?lopmonth=${currentMonth}&lopyear=${currentYear}`);
            const { data } = response.data;
            data.sort((a, b) => a.first_name.localeCompare(b.first_name));
            setAttendanceData(data);
            console.log("response data", data)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching attendance data:', error);
        }
    };

    const handlePrevMonth = () => {
        if (currentMonth === 1) {
            setCurrentMonth(12);
            setCurrentYear(prevYear => prevYear - 1);
        } else {
            setCurrentMonth(prevMonth => prevMonth - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonth === 12) {
            setCurrentMonth(1);
            setCurrentYear(prevYear => prevYear + 1);
        } else {
            setCurrentMonth(prevMonth => prevMonth + 1);
        }
    };


    // download options
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });



    const handleExportCSV = () => {
        const csvData = attendanceData.map(
            ({

                first_name,
                // days_present,


                days_late,
                days_permission,
                days_halfday,
                days_absent,
                days_leave,
                lop,
            }, index) => ({
                'S.No': index + 1,
                first_name,
                // days_present,
                days_late,
                days_permission,
                days_halfday,
                days_absent,
                days_leave,
                lop,
            })
        );

        const headers = [
            { label: 'S.No', key: 'S.No' },
            { label: 'Name', key: 'first_name' },
            // { label: 'Days Present', key: 'days_present' },

            { label: 'LA', key: 'days_late' },
            { label: 'PR', key: 'days_permission' },
            { label: 'HL', key: 'days_halfday' },
            { label: 'L', key: 'days_leave' },
            { label: 'A', key: 'days_absent' },
            { label: 'Overall LOP', key: 'lop' },
        ];

        const csvReport = {
            data: csvData,
            headers: headers,
            filename: 'monthly_attendance.csv',
        };

        // Use CSVLink for CSV export
        return <CSVLink {...csvReport}><i className="fas fa-file-csv" style={{ fontSize: '25px', color: '#212529' }}></i></CSVLink>;
    };



    const handleExportPDF = () => {
        const unit = 'pt';
        const size = 'A4';

        const doc = new jsPDF('landscape', unit, size);

        const data = attendanceData.map(
            ({

                first_name,
                // days_present,


                days_late,
                days_permission,
                days_halfday,
                days_absent,
                days_leave,
                lop,
            }, index) => [
                    index + 1,
                    first_name,
                    // 
                    days_late,
                    days_permission,
                    days_halfday,
                    days_leave,
                    days_absent,
                    lop,
                ]
        );

        const columnStyles = {
            0: { halign: 'center' }, // S.No
            1: { halign: 'left' },
            2: { halign: 'center' }, // In Time
            3: { halign: 'center' }, // Out Time
            4: { halign: 'center' }, // P / A / HL
            5: { halign: 'center' }, // LA
            6: { halign: 'center' }, // Permission
            // 7: { halign: 'center' }, // OnDuty
            7: { halign: 'center' }, // leave
            8: { halign: 'center' }  // Total Hours
        };


        doc.autoTable({
            head: [
                ['S.No',
                    'Name',
                    // days_present,
                    'LA',
                    'PR',
                    'HL',
                    'L',
                    'A',
                    'Overall LOP'
                ],
            ],
            body: data,
            headStyles: { fontStyle: 'bold', halign: 'center' }, // Styles for thead
            bodyStyles: { halign: 'center' }, // Styles for tbody
            columnStyles: columnStyles,
        });

        doc.save('monthly_attendance.pdf');
    };
    // download options end


    // search filter
    const filteredData = attendanceData.filter((row) =>
        Object.values(row).some(
            (value) =>
                (typeof value === 'string' || typeof value === 'number') &&
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // pagination start

    const filteredAttendanceData = filteredData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    // pagination end

    // search filter end


    // internal styles start

    const myStyles = {
        color: 'white',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        borderRadius: '21px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'

    };

    const myStyles1 = {
        color: 'black',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        borderRadius: '21px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'

    };

    // internal styles end

    // =============================================================================
    const [employeeData, setEmployeeData] = useState([]);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.get('https://officeinteriorschennai.com/api.php?employeelist');
    //             setEmployeeData(response.data.data);
    //             console.log("setEmployeeData", response.data.data)
    //             setLoading(false);
    //         } catch (error) {
    //             console.log(error.message);
    //         }
    //     };
    //     fetchData();
    // }, []);

    const handleVisitProfile = (id) => {
        // Use navigate function to navigate to the '/profile' route
        navigate(`/admin/attendance_emps/${id}`);
    };
    const navigate = useNavigate();

    return (
        <>

            {loading ? (
                <div style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#f6f6f6'
                }}>
                    <ScaleLoader color="#36d7b7" />
                </div>
            ) : (
                <div style={{ display: 'grid', maxWidth: '1035px', margin: '0 auto', paddingTop: '7vh' }}>

                    <h3 style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>
                        {`${new Date(currentYear, currentMonth - 1).toLocaleString('default', { month: 'long' })} ${currentYear} Attendance Count`}
                    </h3>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '10px' }}>



                        <div>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setCurrentPage(0);
                                }
                                }
                                style={myStyles1}

                            />
                            <span>
                                <button style={{ fontSize: '22px', padding: '3px 13px', backgroundColor: '#1a252f', color: 'white', borderRadius: '20px' }} onClick={handlePrevMonth}><FontAwesomeIcon icon={faChevronLeft} /></button>
                                <button style={{ fontSize: '22px', padding: '3px 13px', backgroundColor: '#1a252f', color: 'white', borderRadius: '20px' }} onClick={handleNextMonth}><FontAwesomeIcon icon={faChevronRight} /></button>

                            </span>
                        </div>


                        {/* =============================================== */}
                        {/* <div style={{display:'flex', alignItems:'center', flexDirection:'row-reverse',gap:'2rem'}}>
                           
                            <h2>{formatMonthYear(currentDate)}</h2>
                            <div style={{display:'flex',gap:'1rem'}}>
                             
                                <button style={{fontSize:'22px',padding:'3px 16px', backgroundColor:'#1a252f', color:'white'}} onClick={() => handleMonthChange(-1)}> <FontAwesomeIcon icon={faChevronLeft} /></button>

                              
                                <button style={{fontSize:'22px',padding:'3px 16px', backgroundColor:'#1a252f', color:'white'}} onClick={() => handleMonthChange(1)}><FontAwesomeIcon icon={faChevronRight} /></button>
                            </div>
                          
                        </div> */}
                        {/* =============================================== */}





                        <div>

                            <button style={myStyles}>{handleExportCSV()}</button>

                            <button style={myStyles} onClick={handleExportPDF}><i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                            <button style={myStyles} onClick={handlePrint}><i className="fas fa-print" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                        </div>
                    </div>
                    <div ref={componentRef}>
                        <table className="table table-dark table-striped">
                            <thead style={{ textAlign: 'center' }}>

                                <tr>
                                    <th scope="col" style={{ width: '5%' }}>S.No</th>
                                    <th scope="col" style={{ width: '11.33%' }}>Name</th>
                                    <th scope="col" style={{ width: '8.33%' }}>LA</th>
                                    <th scope="col" style={{ width: '8.33%' }}>PR</th>
                                    <th scope="col" style={{ width: '8.33%' }}>HL</th>
                                    <th scope="col" style={{ width: '8.33%' }}>L</th>
                                    <th scope="col" style={{ width: '8.33%' }}>A</th>
                                    <th scope="col" style={{ width: '8.33%' }}>Overall LOP</th>
                                    <th scope="col" style={{ width: '8.33%' }}>Gross Salary</th>
                                    <th scope="col" style={{ width: '8.33%' }}>PF</th>
                                    <th scope="col" style={{ width: '8.33%' }}>W-Days A-Days</th>
                                    <th scope="col" style={{ width: '8.33%' }}>Net Salary</th>
                                </tr>

                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {

                                    filteredAttendanceData.length === 0 ? (
                                        <tr>
                                            <td colSpan="8" style={{ textAlign: 'center' }}>No search data found</td>
                                        </tr>
                                    ) : (

                                        // ================

                                        filteredAttendanceData.map((row, index) => {
                                            const serialNumber = currentPage * itemsPerPage + index + 1;
                                            return (

                                                <tr key={row.id}>
                                                    <th scope="row">{serialNumber}</th>
                                                    <td style={{
                                                        cursor: 'pointer',
                                                        textAlign: 'left'

                                                    }} onClick={() => handleVisitProfile(row.id)}
                                                        title="Click to know more"
                                                    >{row.first_name}</td>
                                                    {/* <td>{row.days_present}</td> */}
                                                    <td>{row.days_late}</td>
                                                    <td>{row.days_permission}</td>
                                                    <td>{row.days_halfday}</td>
                                                    <td>{row.days_leave}</td>
                                                    <td>{row.days_absent}</td>
                                                    <td>{row.lop}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                            );
                                        })

                                        // ================

                                    )}

                                {/* ============= */}


                            </tbody>
                        </table>
                    </div>


                    {/* Pagination */}
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <ReactPaginate
                            previousLabel={<span aria-hidden="true">&laquo;</span>}
                            nextLabel={<span aria-hidden="true">&raquo;</span>}
                            breakLabel={<span>...</span>}
                            breakClassName={'page-item disabled'}
                            breakLinkClassName={'page-link'}
                            pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            disabledClassName={'disabled'}
                            activeLinkClassName={'bg-dark text-white'}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
