import React, { useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ReactPaginate from 'react-paginate';
import { useEffect } from 'react';
import axios from 'axios';
import { ScaleLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';



export default function MonthlyAttendance(props) {



    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState(''); //search filter
    const componentRef = React.useRef();  //download options
    const [loading, setLoading] = useState(true);



    // Sample data in array format
    // const attendanceData = [
    //     { id: 1, name: 'Annapriya', daysPresent: 20, daysAbsent: 5, halfDays: 2, late: 3, permission: 1, onDuty: 0 },
    //     { id: 2, name: 'Roobun', daysPresent: 18, daysAbsent: 7, halfDays: 1, late: 2, permission: 0, onDuty: 0 },
    //     { id: 3, name: 'Vivek', daysPresent: 20, daysAbsent: 5, halfDays: 2, late: 3, permission: 1, onDuty: 0 },
    //     { id: 4, name: 'seetha', daysPresent: 18, daysAbsent: 7, halfDays: 1, late: 2, permission: 0, onDuty: 0 },
    //     { id: 5, name: 'kathik', daysPresent: 20, daysAbsent: 5, halfDays: 2, late: 3, permission: 1, onDuty: 0 },
    //     { id: 6, name: 'Balaji', daysPresent: 18, daysAbsent: 7, halfDays: 1, late: 2, permission: 0, onDuty: 3 },
    //     { id: 7, name: 'venkatesh', daysPresent: 18, daysAbsent: 7, halfDays: 1, late: 2, permission: 0, onDuty: 3 },
    //     { id: 8, name: 'jabastin', daysPresent: 18, daysAbsent: 7, halfDays: 1, late: 2, permission: 0, onDuty: 3 },

    // ];

    const [attendanceData, setAttendanceData] = useState([]); // State to store attendance data

    // ===================

    // Retrieve userData from local storage
    const userData = JSON.parse(localStorage.getItem('userData'));

    useEffect(() => {
        // Fetch data from the API when the component mounts
        const fetchData = async () => {
            try {
                // const userempid = 37;
                // const userrole = 1;

                const userempid = userData.userempid;
                const userrole = userData.userrole;


                const response = await axios.get(`https://officeinteriorschennai.com/api.php?monthlyemployeeattendancepresentcount=${userrole}&monthlyloginempid=${userempid}`);
                // Assuming the API response contains a data property with an array of employee data
                const { data } = response.data;

                // Sort the data based on the id property
                // data.sort((a, b) => a.id - b.id);
                data.sort((a, b) => {
                    const firstNameA = a.first_name.toUpperCase();
                    const firstNameB = b.first_name.toUpperCase();

                    if (firstNameA < firstNameB) {
                        return -1;
                    }
                    if (firstNameA > firstNameB) {
                        return 1;
                    }
                    return 0;
                });

                // Set the sorted data to the state
                setAttendanceData(data);
                // console.log("API Response", data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };

        fetchData();
    }, []);


    // download options
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });



    const handleExportCSV = () => {
        const csvData = attendanceData.map(
            ({

                first_name,
                days_present,
                days_leave,
                days_absent,
                days_halfday,
                days_late,
                days_permission,
                // days_onduty,
            }, index) => ({
                'S.No': index + 1,
                first_name,
                days_present,
                days_leave,
                days_absent,
                days_halfday,
                days_late,
                days_permission,
                // days_onduty,
            })
        );

        const headers = [
            { label: 'S.No', key: 'S.No' },
            { label: 'Name', key: 'first_name' },
            { label: 'P', key: 'days_present' },
            { label: 'L', key: 'days_leave' },
            { label: 'A', key: 'days_absent' },
            { label: 'HL', key: 'days_halfday' },
            { label: 'LA', key: 'days_late' },
            { label: 'PR', key: 'days_permission' },
            // { label: 'On Duty', key: 'days_onduty' },
        ];

        const csvReport = {
            data: csvData,
            headers: headers,
            filename: 'monthly_attendance.csv',
        };

        // Use CSVLink for CSV export
        return <CSVLink {...csvReport}><i className="fas fa-file-csv" style={{ fontSize: '25px', color: '#212529' }}></i></CSVLink>;
    };



    const handleExportPDF = () => {
        const unit = 'pt';
        const size = 'A4';

        const doc = new jsPDF('landscape', unit, size);

        const data = attendanceData.map(
            ({

                first_name,
                days_present,
                days_leave,
                days_absent,
                days_halfday,
                days_late,
                days_permission,
                // days_onduty,
            }, index) => [
                    index + 1,
                    first_name,
                    days_present,
                    days_leave,
                    days_absent,
                    days_halfday,
                    days_late,
                    days_permission,
                    // days_onduty,
                ]
        );


        const columnStyles = {
            0: { halign: 'center' }, // S.No
            1: { halign: 'left' },
            2: { halign: 'center' }, // In Time
            3: { halign: 'center' }, // Out Time
            4: { halign: 'center' }, // P / A / HL
            5: { halign: 'center' }, // LA
            6: { halign: 'center' }, // Permission
            // 7: { halign: 'center' }, // OnDuty
            7: { halign: 'center' }, // leave
            8: { halign: 'center' }  // Total Hours
        };

        doc.autoTable({
            head: [
                ['S.No', 'Name', 'P', 'L', 'A', 'HL', 'LA', 'PR'],
            ],
            body: data,
            headStyles: { fontStyle: 'bold', halign: 'center' }, // Styles for thead
            bodyStyles: { halign: 'center' }, // Styles for tbody
            columnStyles: columnStyles,
        });

        doc.save('monthly_attendance.pdf');
    };
    // download options end


    // search filter
    const filteredData = attendanceData.filter((row) =>
        Object.values(row).some(
            (value) =>
                (typeof value === 'string' || typeof value === 'number') &&
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // pagination start

    const filteredAttendanceData = filteredData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    // pagination end

    // search filter end


    // internal styles start

    const myStyles = {
        color: 'white',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        borderRadius: '21px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'

    };

    const myStyles1 = {
        color: 'black',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        borderRadius: '21px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'

    };

    // internal styles end

    // =============================================================================
    const [employeeData, setEmployeeData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://officeinteriorschennai.com/api.php?employeelist');
                setEmployeeData(response.data.data);
                setLoading(false);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchData();
    }, []);

    const handleVisitProfile = (id) => {
        // Use navigate function to navigate to the '/profile' route
        navigate(`/admin/attendance_emps/${id}`);
    };
    const navigate = useNavigate();

    return (
        <>

            {loading ? (
                <div style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#f6f6f6'
                }}>
                    <ScaleLoader color="#36d7b7" />
                </div>
            ) : (
                <div style={{ display: 'grid', maxWidth: '1035px', margin: '0 auto', paddingTop: '7vh' }}>

                    <h3 style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>Monthly Attendance Count</h3>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '10px' }}>
                        <div>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setCurrentPage(0);
                                }
                                }
                                style={myStyles1}

                            />
                        </div>
                        <div>
                            <button style={myStyles}>{handleExportCSV()}</button>

                            <button style={myStyles} onClick={handleExportPDF}><i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                            <button style={myStyles} onClick={handlePrint}><i className="fas fa-print" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                        </div>
                    </div>
                    <div ref={componentRef}>
                        <table className="table table-dark table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: '5%', textAlign: 'center' }}>S.No</th>
                                    <th scope="col" style={{ width: '20%', textAlign: 'center' }}>Name</th>
                                    <th scope="col" style={{ width: '12%', textAlign: 'center' }}>P</th>
                                    <th scope="col" style={{ width: '12%', textAlign: 'center' }}>L</th>
                                    <th scope="col" style={{ width: '12%', textAlign: 'center' }}>A</th>
                                    <th scope="col" style={{ width: '12%', textAlign: 'center' }}>HL</th>
                                    <th scope="col" style={{ width: '12%', textAlign: 'center' }}>LA</th>
                                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>PR</th>
                                    {/* <th scope="col">On Duty</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    filteredAttendanceData.length === 0 ? (
                                        <tr>
                                            <td colSpan="8" style={{ textAlign: 'center' }}>No search data found</td>
                                        </tr>
                                    ) : (

                                        // ================

                                        filteredAttendanceData.map((row, index) => {
                                            const serialNumber = currentPage * itemsPerPage + index + 1;
                                            return (

                                                <tr key={row.id}>
                                                    <th scope="row" style={{ textAlign: 'center' }}>{serialNumber}</th>
                                                    <td style={{
                                                        cursor: 'pointer',
                                                        textAlign: 'left'

                                                    }} onClick={() => handleVisitProfile(row.id)}
                                                        title="Click to know more"
                                                    >{row.first_name}</td>
                                                    <td style={{ textAlign: 'center' }}>{row.days_present}</td>
                                                    <td style={{ textAlign: 'center' }}>{row.days_leave}</td>
                                                    <td style={{ textAlign: 'center' }}>{row.days_absent}</td>
                                                    <td style={{ textAlign: 'center' }}>{row.days_halfday}</td>
                                                    <td style={{ textAlign: 'center' }}>{row.days_late}</td>
                                                    <td style={{ textAlign: 'center' }}>{row.days_permission}</td>
                                                    {/* <td>{row.days_onduty}</td> */}
                                                </tr>
                                            );
                                        })

                                        // ================

                                    )}

                                {/* ============= */}


                            </tbody>
                        </table>
                    </div>


                    {/* Pagination */}
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <ReactPaginate
                            previousLabel={<span aria-hidden="true">&laquo;</span>}
                            nextLabel={<span aria-hidden="true">&raquo;</span>}
                            breakLabel={<span>...</span>}
                            breakClassName={'page-item disabled'}
                            breakLinkClassName={'page-link'}
                            pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            disabledClassName={'disabled'}
                            activeLinkClassName={'bg-dark text-white'}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
