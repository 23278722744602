// useNotificationData.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useNotificationData = () => {
  const [displayedNotifications, setDisplayedNotifications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://officeinteriorschennai.com/api.php?employeelist');
        setDisplayedNotifications(response.data.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  // Return only the first three notifications
  const firstThreeNotifications = displayedNotifications.slice(0, 3);

  return { displayedNotifications, firstThreeNotifications };
};

export default useNotificationData;
