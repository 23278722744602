
import React, { useRef, useState } from 'react';
import paylogo from '../../assets/admin/assets/img/EPK_group_Logo.png';
import numberToWords from 'number-to-words';
import jsPDF from 'jspdf';


const PaySlipEmp1 = () => {
    const [payslipData] = useState({
        employeeName: 'John Doe',
        designation: 'PROJECTMANGER',
        dateofjoining: '01/10/2022',
        PayPeriod: 'January 2023',
        PayDate: '07/02/2023',
    });



    const Netpay = {
        border: '3px solid #e2e2e2',
        padding: '10px 22px',
        paddingLeft: '21px',
        paddingRight: '21px',
        textAlign: 'center'
    };

    const earningsData = [
        { type: 'Basic', amount: '21,411.00', ytd: '84,181.00' },
        { type: 'House Rent Allowance', amount: '10,706.00', ytd: '42,091.00' },
        { type: 'Fixed Allowance', amount: '9,849.00', ytd: '38,724.00' },
        { type: 'Leave Encashment', amount: '3,250.00', ytd: '13,000.00' },
        { type: 'Gross Earnings', amount: '45,216.00', ytd: '' },
    ];

    const deductionsData = [
        { type: 'EPF Contribution', amount: '1,800.00', ytd: '7,200.00' },
        { type: 'Total Deductions', amount: '1,800.00', ytd: '' },
    ];





    const totalNetPayable = 43416.00; // Assuming this value is derived from your data

    const getTotalNetPayableInWords = (amount) => {
        // Implement a function to convert the amount to words
        // You can use external libraries like 'number-to-words' or implement your own logic
        // Here's a simple example (you might need to adjust it based on your requirements)
        const words = numberToWords.toWords(amount);
        return `(Rupees ${words} Only)`;
    };


    // ====================


    const componentRef = useRef();

    const downloadPDF = () => {
        const pdf = new jsPDF('p', 'pt', 'a4');
    
        // Set a smaller font size for the entire content
        const smallerFontSize = 6; // You can adjust this value
    
        pdf.html(componentRef.current, {
            callback: (pdf) => {
                // Set the font size before saving
                pdf.setFontSize(smallerFontSize);
    
                console.log(pdf.output());
                pdf.save('employee_payslip.pdf');
            },
        });
    };
    


    return (
        <>
        
        <div>
                <button onClick={downloadPDF} style={{backgroundColor:'#0d6efd',color:'white',borderRadius:'10px'}}>Download PDF</button>
            </div>
        
        <div ref={componentRef} style={{ padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:'50%'  }}>
                <div>
                    <h2 style={{fontSize:'30px'}}> EPK GROUP</h2>
                    <h5 style={{fontSize:'16px'}}>No: 624, Anna Salai 4th floor, Khivraj<br /> Building,Tamil Nadu 600006</h5>
                </div>
                <div >
                    <img src={paylogo} alt="logo" style={{ width: '217px', height: '96px' }} />
                </div>

            </div>
            <hr style={{ marginTop: '3px', marginBottom: '5px',width:'50%' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:'50%' }}>
                <div>
                    <h3 style={{fontSize:'20px'}}>Payslip for the month of January 2023</h3>
                    <h4 style={{fontSize:'17px'}}>Employee Pay Summary</h4>
                    <table>
                        <tr>
                            <td><span style={{ color: 'grey', fontWeight: 'bold',fontSize:'13px' }}>Employee Name</span></td>
                            <td style={{ paddingLeft: '30px', fontWeight: 'bold',fontSize:'13px' }}>{payslipData.employeeName}</td>
                        </tr>
                        <tr>
                            <td><span style={{ color: 'grey', fontWeight: 'bold',fontSize:'13px' }}>Designation</span></td>
                            <td style={{ paddingLeft: '30px', fontWeight: 'bold',fontSize:'13px' }}>{payslipData.designation}</td>
                        </tr>
                        <tr>
                            <td><span style={{ color: 'grey', fontWeight: 'bold',fontSize:'13px' }}>Date of Joining</span></td>
                            <td style={{ paddingLeft: '30px', fontWeight: 'bold',fontSize:'13px' }}>{payslipData.dateofjoining}</td>
                        </tr>
                        <tr>
                            <td><span style={{ color: 'grey', fontWeight: 'bold',fontSize:'13px' }}>Pay Period</span></td>
                            <td style={{ paddingLeft: '30px', fontWeight: 'bold',fontSize:'13px' }}>{payslipData.PayPeriod}</td>
                        </tr>
                        <tr>
                            <td><span style={{ color: 'grey', fontWeight: 'bold',fontSize:'13px' }}>Pay Date</span></td>
                            <td style={{ paddingLeft: '30px', fontWeight: 'bold',fontSize:'13px' }}>{payslipData.PayDate}</td>
                        </tr>
                    </table>
                </div>
                <div style={Netpay}>
                    <h5 style={{ color: 'grey',fontSize:'19px' }}>Employee Net Pay</h5>
                    <h1 style={{ color: '#029c10',fontSize:'32px'  }}>{totalNetPayable}</h1>
                    <h5 style={{ color: 'grey',fontSize:'13px'  }}>Paid Days : 29.5 | LOP Days : 1.5</h5>
                </div>
            </div>
            <hr style={{ marginTop: '5px', marginBottom: '5px', paddingBottom: '8px',width:'50%' }} />

            {/* ================= */}
            <div style={{ display: 'flex', justifyContent: 'space-between',width:'50%' }}>
                <div style={{ width: '48%' }}>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead style={{ backgroundColor: '#f5f5f5' }}>
                            <tr>
                                <th style={{ width: '33.33%', padding: '0px 8px', border: '1px solid #ddd' }}>Earnings</th>
                                <th style={{ width: '33.33%', padding: '0px 8px', border: '1px solid #ddd' }}>Amount</th>
                                <th style={{ width: '33.33%', padding: '0px 8px', border: '1px solid #ddd' }}>YTD</th>
                            </tr>
                        </thead>
                        <tbody>
                            {earningsData.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '0px 8px', border: '1px solid #ddd', fontWeight: index === earningsData.length - 1 ? 'bold' : 'normal' }}>{item.type}</td>
                                    <td style={{ padding: '0px 8px', border: '1px solid #ddd', fontWeight: index === earningsData.length - 1 ? 'bold' : 'normal' }}>{item.amount}</td>
                                    <td style={{ padding: '0px 8px', border: '1px solid #ddd', fontWeight: index === earningsData.length - 1 ? 'bold' : 'normal' }}>{item.ytd}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div style={{ width: '48%' }}>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead style={{ backgroundColor: '#f5f5f5' }}>
                            <tr>
                                <th style={{ width: '33.33%', padding: '0px 8px', border: '1px solid #ddd' }}>Deductions</th>
                                <th style={{ width: '33.33%', padding: '0px 8px', border: '1px solid #ddd' }}>Amount</th>
                                <th style={{ width: '33.33%', padding: '0px 8px', border: '1px solid #ddd' }}>YTD</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deductionsData.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '0px 8px', border: '1px solid #ddd', fontWeight: index === deductionsData.length - 1 ? 'bold' : 'normal' }}>{item.type}</td>
                                    <td style={{ padding: '0px 8px', border: '1px solid #ddd', fontWeight: index === deductionsData.length - 1 ? 'bold' : 'normal' }}>{item.amount}</td>
                                    <td style={{ padding: '0px 8px', border: '1px solid #ddd', fontWeight: index === deductionsData.length - 1 ? 'bold' : 'normal' }}>{item.ytd}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* ===================== */}

            <div>

                <p style={{ fontSize: '14px', padding: '8px 0px', fontWeight: '500', backgroundColor: '#e9f7e7',width:'50%' }}><span style={{ color: 'rgb(2, 156, 16)'}}>|</span> Total Net Payable {totalNetPayable.toFixed(2)} {getTotalNetPayableInWords(totalNetPayable)}</p>
            </div>

            <p>**Total Net Payable = Gross Earnings - Total Deductions</p>


        </div>

        </>
        
    );
};

export default PaySlipEmp1;
