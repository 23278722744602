import React, { useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap styles
import '../../assets/admin/css/emptotaldays.css'
import { UserContext } from "./Context";
import { useContext } from "react";
import { useEffect } from "react";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import axios from "axios";
import { ScaleLoader } from 'react-spinners';

export default function Emptotaldays(props) {

  const [present, setPresent] = useState(20);
  const [absent, setAbsent] = useState(5);
  const [halfDays, setHalfDays] = useState(2);
  const [late, setLate] = useState(3);
  const [permission, setPermission] = useState(1);
  const [onDuty, setOnDuty] = useState(0);
  const [loading, setLoading] = useState(true);


  // ================================================================================================================
  // leave request start 

  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

  // Open leave request modal
  const openLeaveModal = () => {
    setIsLeaveModalOpen(true);
  };

  // Close leave request modal
  const closeLeaveModal = () => {
    setIsLeaveModalOpen(false);
  };

  // ==================================================================

  // Retrieve userData from local storage
  const userData = JSON.parse(localStorage.getItem('userData'));

  const userrole = userData?.userrole || '';


  const userempid = userData?.userempid || '';
  const username = userData?.username || '';
  const useremail = userData?.useremail || '';

  const [leaveFormData, setLeaveFormData] = useState({
    emp_id: userempid,
    emp_name: username,
    emp_email: useremail,
    request_type: "",
    request_category: "",
    from_date: "",
    to_date: "",
    permission_date: "",
    permission_timefrom: "",
    permission_timeto: "",
    leave_reason: "",
    proof: "",
  });
  const [leaverequesttype, setLeaveRequestType] = useState([]);
  const [leaverequestcategory, setLeaveRequestCategory] = useState([]);


  useEffect(() => {
    // Fetch leave request types
    fetch('https://officeinteriorschennai.com/api.php?leaverequesttype')
      .then(response => response.json())
      .then(data => {
        // console.log("Fetched leave request types:", data);
        setLeaveRequestType(data.data);
      })
      .catch(error => console.error("Error fetching leave request types:", error));

    // Fetch leave request categories
    fetch('https://officeinteriorschennai.com/api.php?leaverequestcategory')
      .then(response => response.json())
      .then(data => {
        // console.log("Fetched leave request categories:", data);
        setLeaveRequestCategory(data.data);
      })
      .catch(error => console.error("Error fetching leave request categories:", error));
  }, []);



  // =================================================.....

  // Function to handle input changes in Leave Request modal
  const handleLeaveInputChange = (e) => {
    const { name, value } = e.target;
    setLeaveFormData({ ...leaveFormData, [name]: value });
  };


  const handleProofChange = (e) => {
    const file = e.target.files[0];
    setLeaveFormData({ ...leaveFormData, proof: file });
  };

  // =================================================.....

  const handleLeaveSubmit = async (e) => {
    e.preventDefault();

    // Perform field validation
    if (!leaveFormData.request_type || !leaveFormData.request_category) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill in all the required fields.',
      });
      return;
    }

    // Depending on the user's selection, validate additional fields
    switch (leaveFormData.request_type) {
      case '1': // Leave
        if (!leaveFormData.from_date || !leaveFormData.to_date || !leaveFormData.leave_reason) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Please fill in all the required fields for leave request.',
          });
          return;
        }
        break;
      case '2': // Half Day
        if (!leaveFormData.permission_date || !leaveFormData.permission_timefrom || !leaveFormData.permission_timeto) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Please fill in all the required fields for half day request.',
          });
          return;
        }
        break;
      case '3': // Permission
        if (!leaveFormData.permission_date || !leaveFormData.permission_timefrom || !leaveFormData.permission_timeto) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Please fill in all the required fields for permission request.',
          });
          return;
        }
        break;
      default:
        break;
    }


    // Add your logic to handle leave request submission here
    // console.log("Leave Request submitted:", leaveFormData);
    setIsLeaveModalOpen(false); // Close the modal after submission

    const formDataToSend = new FormData();
    formDataToSend.append('emp_id', formData.emp_id);
    formDataToSend.append('emp_name', formData.emp_name);
    formDataToSend.append('emp_email', formData.emp_email);
    formDataToSend.append('request_type', leaveFormData.request_type);
    formDataToSend.append('request_category', leaveFormData.request_category);
    formDataToSend.append('from_date', leaveFormData.from_date);
    formDataToSend.append('to_date', leaveFormData.to_date);
    formDataToSend.append('permission_date', leaveFormData.permission_date);
    formDataToSend.append('permission_timefrom', leaveFormData.permission_timefrom);
    formDataToSend.append('permission_timeto', leaveFormData.permission_timeto);
    formDataToSend.append('leave_reason', leaveFormData.leave_reason);
    formDataToSend.append('proof', leaveFormData.proof);


    try {

      const response = await fetch('https://officeinteriorschennai.com/api.php?insertleaverequest', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        // console.log("Form submitted successfully");
        setLeaveFormData({
          request_type: "",
          request_category: "",
          from_date: "",
          to_date: "",
          permission_date: "",
          permission_timefrom: "",
          permission_timeto: "",
          leave_reason: "",
          proof: "",
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Form submitted successfully',
        });

      } else {
        console.error("Failed to submit form");
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to submit form',
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error submitting form. Please try again later.',
      });
    }
  };

  // const showDateFields = leaveFormData.request_type === "Leave";


  // leave request end
  // =========================================================================================================

  // =========================================================================================================

  const [isModalOpen, setIsModalOpen] = useState(false);




  const [formData, setFormData] = useState({
    emp_id: userempid,
    emp_name: username,
    emp_email: useremail,
    request_type: "", // Changed from requestType
    request_date: "", // Changed from date
    request_fromtime: "", // Changed from timeFrom1
    request_totime: "", // Changed from timeTo1
    request_location: "", // No change
    request_reason: "", // Changed from message
  });

  const [requestTypes, setRequestTypes] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    // Fetch request types
    fetch('https://officeinteriorschennai.com/api.php?attendancerequesttype')
      .then(response => response.json())
      .then(data => {
        // console.log("Fetched request types:", data);
        setRequestTypes(data.data);
      })
      .catch(error => console.error("Error fetching request types:", error));

    // Fetch locations
    fetch('https://officeinteriorschennai.com/api.php?attendancerequestlocation')
      .then(response => response.json())
      .then(data => {
        // console.log("Fetched location types:", data);
        setLocations(data.data);
      })
      .catch(error => console.error("Error fetching locations:", error));
  }, []);

  const handleApplyClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.request_type ||
      !formData.request_location ||
      !formData.request_date ||
      !formData.request_fromtime ||
      // !formData.request_totime ||
      !formData.request_reason
    ) {
      console.error("All fields are required");
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill in all the required fields.',
      });
      return;
    }



    // console.log("Form submitted:", formData);
    // setIsModalOpen(false);
    // setFormData({
    //   request_type: "",
    //   request_date: "",
    //   request_fromtime: "",
    //   request_totime: "",
    //   request_location: "",
    //   request_reason: ""
    // });

    // console.log(formData)

    // console.log(formData)
    const formDataToSend = new FormData();

    formDataToSend.append('emp_id', formData.emp_id);
    formDataToSend.append('emp_name', formData.emp_name);
    formDataToSend.append('emp_email', formData.emp_email);
    formDataToSend.append('request_type', formData.request_type);
    formDataToSend.append('request_location', formData.request_location);
    formDataToSend.append('request_date', formData.request_date);
    formDataToSend.append('request_fromtime', formData.request_fromtime);
    formDataToSend.append('request_totime', formData.request_totime);
    formDataToSend.append('request_reason', formData.request_reason);

    try {

      const response = await fetch('https://officeinteriorschennai.com/api.php?insertattendancerequest', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        // console.log("Form submitted successfully");
        setIsModalOpen(false);
        setFormData({
          ...formData,
          request_type: "",
          request_date: "",
          request_fromtime: "",
          request_totime: "",
          request_location: "",
          request_reason: ""
        });

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Form submitted successfully',
        });
      } else {
        console.error("Failed to submit form");

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to submit form',
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error submitting form. Please try again later.',
      });
    }
  };


  // =========================================================================================================

  // =========================================================================================================
  // pending request


  // State for the pending requests modal
  const [isPendingModalOpen, setIsPendingModalOpen] = useState(false);
  const [pendingRequests] = useState([
    { id: 1, message: "Pending request 1", date: "2023-01-01" },
    { id: 2, message: "Pending request 2", date: "2023-01-02" },
    // Add more pending requests as needed
  ]);

  const openPendingModal = () => {
    setIsPendingModalOpen(true);
  };

  const closePendingModal = () => {
    setIsPendingModalOpen(false);
  };

  const handleViewRequest = (request) => {
    // console.log("View request:", request);
    // Add your logic to handle the click on a pending request
    // You can open a detailed view or perform any other action
  };

  // ==========================

  // const { events } = useContext(UserContext);
  // console.log("events -=-=-=-=-=-=--=>", props.events)

  const events = props.events

  // console.log("Employee total days ===============>", events.length)


  const titleCount = events.reduce((count, value) => {
    return count + 1; // Increment count for each event
  }, 0);


  // ==========================================================================================
  // Get the current date
  const currentDate = new Date(props.formattedCenterDate);

  // Set the start date to the first day of the current month
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Set the end date to the last day of the current month
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);

  // Filter events within the current month
  const filteredEvents = events.filter((event) => {
    const eventDate = new Date(event.start);
    return eventDate >= startDate && eventDate <= endDate;
  });

  // Initialize counts for titles, permission, and late
  const titleCounts = {
    Present: 0,
    Absent: 0,
    Leave: 0,
    "On Duty": 0,
  };

  let permissionCount = 0;
  let lateCount = 0;
  let halfDayCount = 0;
  let AbsentCount = 0;
  let LeaveCount = 0;

  // Count occurrences of each title, permission, and late
  filteredEvents.forEach((event) => {
    const { title, permission, late, HalfDay, Absent, Leave } = event;
    titleCounts[title]++;

    if (permission) {
      permissionCount++;
    }

    if (late) {
      lateCount++;
    }

    if (HalfDay) {
      halfDayCount++;
    }

    if (Absent) {
      AbsentCount++;
    }

    if (Leave) {
      LeaveCount++;
    }
  });

  // ==========================================================================================
  // Log the counts
  // console.log(`Title Counts for ${startDate.toISOString().split('T')[0]} to ${endDate.toISOString().split('T')[0]}:`);
  // console.log("Present:", titleCounts.Present);
  // console.log("Absent:", titleCounts.Absent);
  // console.log("On Duty:", titleCounts["On Duty"]);

  // console.log("Permission Count:", permissionCount);
  // console.log("Late Count:", lateCount);
  // console.log("Late Count:", offdayCount);

  // ==============================================

  
  
  


  const [conditionDisplayAdmin, setConditionDisplayAdmin] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://officeinteriorschennai.com/api.php?dashboardcountview`);
        setConditionDisplayAdmin(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);











  return (
    <>

      {loading ? (
        <div style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#f6f6f6'
        }}>
          <ScaleLoader color="#36d7b7" />


        </div>
      ) : (
        <>
          <div className="container">

            {conditionDisplayAdmin.includes(userrole) ?
              <>
                <h2 style={{ paddingBottom: '15px' }}>{props.name}'s {props.formattedCenterDate} Attendance</h2><br />
                {/* <p>Current Center Date: {props.formattedCenterDate}</p>  */}
              </> :
              <h2 style={{ paddingBottom: '15px' }}>{props.formattedCenterDate} Employee Attendance <span>{props.calendertotal}</span></h2>

            }

            <div className="top-container">

              <h3>Total</h3>

              <div style={{ display: 'flex', alignItems: 'center' }}>


                {/* <Button style={{ marginRight: '15px' }} onClick={openPendingModal}>
              Pending request ({pendingRequests.length})
            </Button> */}


                {conditionDisplayAdmin.includes(userrole) ? null :
                  <>
                    <Button style={{ marginRight: '15px' }} onClick={openLeaveModal}>
                      leave
                    </Button>

                    <Button onClick={handleApplyClick}>
                      Apply
                    </Button>
                  </>
                }

              </div>
            </div>


            {/* ======================= */}

            {/* Pending Requests Modal */}
            <Modal show={isPendingModalOpen} onHide={closePendingModal}>
              <Modal.Header closeButton>
                <Modal.Title>Pending Requests</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Message</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingRequests.map((request) => (
                      <tr key={request.id}>
                        <td>{request.id}</td>
                        <td>{request.message}</td>
                        <td>{request.date}</td>
                        <td>
                          <Button variant="info" onClick={() => handleViewRequest(request)}>
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePendingModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            {/* ======================= */}



            <div className="row">
              <div className="col-sm" style={{ border: '1px solid #ddd' }}>
                {/* <h1>{present}</h1> */}
                <h1>{titleCounts.Present}</h1>
                <h4>Present</h4>
              </div>

              <div className="col-sm" style={{ border: '1px solid #ddd' }}>
                {/* <h1>{absent}</h1> */}
                <h1>{LeaveCount}</h1>
                <h4>Leave</h4>
              </div>


              <div className="col-sm" style={{ border: '1px solid #ddd' }}>
                {/* <h1>{absent}</h1> */}
                <h1>{AbsentCount}</h1>
                <h4>Absent</h4>
              </div>

              <div className="col-sm" style={{ border: '1px solid #ddd' }}>
                {/* <h1>{halfDays}</h1> */}
                {/* <h1>{halfDays}</h1> */}
                <h1>{halfDayCount}</h1>
                <h4>Half Days</h4>
              </div>

              <div className="col-sm" style={{ border: '1px solid #ddd' }}>
                {/* <h1>{late}</h1> */}
                <h1>{lateCount}</h1>
                <h4>Late</h4>
              </div>

              <div className="col-sm" style={{ border: '1px solid #ddd' }}>
                {/* <h1>{permission}</h1> */}
                <h1>{permissionCount}</h1>
                <h4>Permission</h4>
              </div>

              {/* <div className="col-sm" style={{ border: '1px solid #ddd' }}>
               
                <h1>{titleCounts["On Duty"]}</h1>
                <h4>On Duty</h4>
              </div> */}

            </div>
          </div>

          {/* =========================== */}
          <Modal show={isModalOpen} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Attendance Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="request_type">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Request type:</Form.Label>
                  <Form.Control
                    as="select"
                    name="request_type"
                    value={formData.request_type}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>Select Request Type</option>
                    {requestTypes.map(type => (
                      <option key={type.id} value={type.id}>{type.request_type_name}</option>
                    ))}
                  </Form.Control>

                </Form.Group>

                <Form.Group controlId="request_location">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Location:</Form.Label>
                  <Form.Control
                    as="select"
                    name="request_location"
                    value={formData.request_location}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>Select Location</option>
                    {locations.map(location => (
                      <option key={location.id} value={location.id}>{location.request_location}</option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="request_date">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="request_date"
                    value={formData.request_date}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Form.Group controlId="request_fromtime" style={{ width: '48%' }}>
                    <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Time From</Form.Label>
                    <Form.Control
                      type="time"
                      name="request_fromtime"
                      value={formData.request_fromtime}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="request_totime" style={{ width: '48%' }}>
                    <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Time To</Form.Label>
                    <Form.Control
                      type="time"
                      name="request_totime"
                      value={formData.request_totime}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>

                <Form.Group controlId="request_reason">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Reason:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="request_reason"
                    value={formData.request_reason}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <div style={{ paddingTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" style={{ marginLeft: '10px' }}>
                    Submit
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          {/* =========================== */}

          {/* =========================== */}

          {/* Leave Request Modal */}
          <Modal show={isLeaveModalOpen} onHide={closeLeaveModal}>
            <Modal.Header closeButton>
              <Modal.Title>Leave Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleLeaveSubmit}>
                <Form.Group controlId="requestType">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Request Type:</Form.Label>
                  <Form.Control
                    as="select"
                    name="request_type"
                    value={leaveFormData.request_type}
                    onChange={handleLeaveInputChange}
                  >
                    <option value="" disabled>Select Request Type</option>
                    {leaverequesttype.map(type => (
                      <option key={type.id} value={type.id}>{type.request_type_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="reason">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Category:</Form.Label>
                  <Form.Control
                    as="select"
                    name="request_category"
                    value={leaveFormData.request_category}
                    onChange={handleLeaveInputChange}
                  >
                    <option value="" disabled>Select Category</option>
                    {leaverequestcategory.map(type => (
                      <option key={type.cid} value={type.cid}>{type.category_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {leaveFormData.request_type === "1" &&  ( // If request type is leave
                  <Form.Group controlId="dateRange" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="from_date"
                        value={leaveFormData.from_date}
                        onChange={handleLeaveInputChange}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="to_date"
                        value={leaveFormData.to_date}
                        onChange={handleLeaveInputChange}
                      />
                    </div>
                  </Form.Group>
                )}
                {leaveFormData.request_type === "4" &&  ( // If request type is absent
                  <Form.Group controlId="dateRange" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="from_date"
                        value={leaveFormData.from_date}
                        onChange={handleLeaveInputChange}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="to_date"
                        value={leaveFormData.to_date}
                        onChange={handleLeaveInputChange}
                      />
                    </div>
                  </Form.Group>
                )}
                {leaveFormData.request_type === "2" &&  ( // If request type is half day
                  <>
                    <Form.Group controlId="permissionDate">
                      <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>HalfDay Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="permission_date"
                        value={leaveFormData.permission_date}
                        onChange={handleLeaveInputChange}
                      />
                    </Form.Group>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Form.Group controlId="permissionTimeFrom" style={{ width: '48%' }}>
                        <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>HalfDay Time From</Form.Label>
                        <Form.Control
                          type="time"
                          name="permission_timefrom"
                          value={leaveFormData.permission_timefrom}
                          onChange={handleLeaveInputChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="permissionTimeTo" style={{ width: '48%' }}>
                        <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>HalfDay Time To</Form.Label>
                        <Form.Control
                          type="time"
                          name="permission_timeto"
                          value={leaveFormData.permission_timeto}
                          onChange={handleLeaveInputChange}
                        />
                      </Form.Group>
                    </div>
                  </>
                )}
                {leaveFormData.request_type === "3" &&  ( // If request type is permission
                  <>
                    <Form.Group controlId="permissionDate">
                      <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Permission Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="permission_date"
                        value={leaveFormData.permission_date}
                        onChange={handleLeaveInputChange}
                      />
                    </Form.Group>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Form.Group controlId="permissionTimeFrom" style={{ width: '48%' }}>
                        <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Permission Time From</Form.Label>
                        <Form.Control
                          type="time"
                          name="permission_timefrom"
                          value={leaveFormData.permission_timefrom}
                          onChange={handleLeaveInputChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="permissionTimeTo" style={{ width: '48%' }}>
                        <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Permission Time To</Form.Label>
                        <Form.Control
                          type="time"
                          name="permission_timeto"
                          value={leaveFormData.permission_timeto}
                          onChange={handleLeaveInputChange}
                        />
                      </Form.Group>
                    </div>
                  </>
                )}
                <Form.Group controlId="attachment">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Attachment:</Form.Label>
                  <Form.Control
                    type="file"
                    name="proof"
                    // value={leaveFormData.proof}
                    onChange={handleProofChange}
                  />
                </Form.Group>
                <Form.Group controlId="comment">
                  <Form.Label style={{ fontWeight: "bold", color: '#4b5c72' }}>Reason:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="leave_reason"
                    value={leaveFormData.leave_reason}
                    onChange={handleLeaveInputChange}
                  />
                </Form.Group>
                <div style={{ paddingTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="secondary" onClick={closeLeaveModal}>Cancel</Button>
                  <Button variant="primary" type="submit" style={{ marginLeft: '10px' }}>Submit</Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          {/* =============================== */}

        </>
      )}
    </>
  );
}


