import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';

export default function AddEventTable() {

    const userData = JSON.parse(localStorage.getItem('userData'));

    const myStyles = {
        color: 'white',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'
    };

    const myStyles1 = {
        color: 'black',
        fontSize: '16px',
        border: '1px solid black',
        marginRight: '15px',
        padding: '5px 7px',
        boxShadow: 'black 0px 0px 10px 1px'
    };

    const navigate = useNavigate();

    const GotoViewPage = (id) => {
        navigate(`/admin/viewevent/${id}`);
    };

    const GoToEditPage = (id) => {
        navigate(`/admin/editevent/${id}`);
    };

    // =======================================================

    // =====================================

    const [tableData, setTableData] = useState([]);

    const fetchTableData = async () => {

        const apiUrl = `https://officeinteriorschennai.com/api.php?eventlistempid=${userData.userempid}&&eventlistroleid=${userData.userrole}`;
        try {
            const response = await axios.get(apiUrl);
            const data = response.data.data;
            setTableData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchTableData();
    }, []);

    // =================================================


    const [conditionDisplay, setConditionDisplay] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://officeinteriorschennai.com/api.php?userrolenavigation`);
                setConditionDisplay(response.data.data);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        fetchTableData();
    }, []);

    //------------------- Handle Delete -----------------------------------

    const HandleDelete = async (item) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this event?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`https://officeinteriorschennai.com/api.php?deleteevent=${item.id}`, {
                    method: 'GET',
                });

                if (response.ok) {
                    Swal.fire(
                        'Deleted!',
                        'Your item has been deleted.',
                        'success'
                    );
                    fetchTableData()
                } else {
                    Swal.fire(
                        'Error!',
                        'Failed to delete data. Please try again.',
                        'error'
                    );
                }
            } catch (error) {
                console.error('Error:', error);
                Swal.fire(
                    'Error!',
                    'An error occurred. Please try again.',
                    'error'
                );
            }
        }
    };

    //---------------------------- Page Number -------------------------------

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');


    //---------------------------  Excel -------------------------------------


    const handleExportCSV = () => {
        const csvData = filteredEvents.map((event, indexInPage) => {
            return {
                'S.no': calculateSNo(indexInPage),
                'Title': event.event_title,
                'Created by': event.hrname,
                'Teams': event.teamname,
                'Members': event.membername,
                'Date': event.event_date,
                'Start Time': event.start_time,
                'End Time': event.end_time,
                'Agenda': event.event_reason,
            };
        });

        const headers = [
            { label: 'S.No', key: 'S.No' },
            { label: 'Title', key: 'Title' },
            { label: 'Created by', key: 'Created by' },
            { label: 'Teams', key: 'Teams' },
            { label: 'Members', key: 'Members' },
            { label: 'Date', key: 'Date' },
            { label: 'Start Time', key: 'Start Time' },
            { label: 'End Time', key: 'End Time' },
            { label: 'Agenda', key: 'Agenda' },
        ];

        const csvReport = {
            data: csvData,
            headers: headers,
            filename: 'event_data.csv',
        };

        return <CSVLink {...csvReport}><i className="fas fa-file-csv" style={{ fontSize: '25px', color: '#212529' }}></i></CSVLink>;
    };


    //=========================== PDF ==========================

    const handleExportPDF = () => {
        const unit = 'pt';
        const size = 'A4';

        const doc = new jsPDF('landscape', unit, size);

        const data = filteredEvents.map((event, indexInPage) => [
            calculateSNo(indexInPage),
            event.event_title,
            event.teamname,
            event.membername,
            event.event_date,
            event.start_time,
            event.end_time,
        ]);

        doc.autoTable({
            head: [
                ['S.No', 'Title', 'Teams', 'Members', 'Date', 'Start Time', 'End Time', 'Agenda'],
            ],
            body: data,
        });

        doc.save('event_data.pdf');
    };

    //-------------------- Page --------------------------------------- 

    const filteredData = tableData.filter((row) =>
        Object.values(row).some(
            (value) =>
                (typeof value === 'string' || typeof value === 'number') &&
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const pageCount = Math.ceil(tableData.length / itemsPerPage);

    const filteredEvents = filteredData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );


    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };


    const calculateSNo = (index) => {
        return currentPage * itemsPerPage + index + 1;
    };


    



    return (

        <div style={{ maxWidth: '100%', overflowX: 'auto', paddingTop: '7vh', padding:'80px 110px' }}>
            <h2 style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>Event List</h2>


            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', justifyContent: 'space-between' }}>
                <div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(0);
                        }}

                        style={myStyles1}
                    />
                </div>
                <div>
                    <button style={myStyles}>{handleExportCSV()}</button>
                    <button style={myStyles} onClick={handleExportPDF}><i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#212529' }}></i></button>
                </div>
            </div>


            <div style={{ width: '100%', overflowX: 'auto' }}>
                <table className="table table-auto table-dark table-striped" style={{ minWidth: '100%', width: 'max-content' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '100px' }}>S.No</th>
                            <th style={{ width: '150px' }}>Title</th>
                            <th style={{ width: '150px' }}>Teams</th>
                            <th style={{ width: '250px' }}>Members</th>
                            <th style={{ width: '150px' }}>Date</th>
                            <th style={{ width: '150px' }}>Start Time</th>
                            <th style={{ width: '150px' }}>End Time</th>
                            <th style={{ width: '150px' }}>Agenda</th>
                            <th style={{ width: '250px' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            filteredEvents.length === 0 ? (
                                <tr>
                                    <td colSpan="9" style={{ textAlign: 'center' }}>No search data found</td>
                                </tr>
                            ) : (

                                // =====================

                                filteredEvents.map((event, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{event.event_title}</td>
                                        <td>{event.teamname}</td>
                                        <td>{event.membername}</td>
                                        <td>{event.event_date}</td>
                                        <td>{event.start_time}</td>
                                        <td>{event.end_time}</td>
                                        <td>{event.event_reason}</td>

                                        {conditionDisplay.includes(userData.userrole) ? (
                                            <td>
                                                <button className="btn btn-info btn-sm mr-2"
                                                    style={{ width: '45%', backgroundColor: '#0d6efd', borderColor: '#0d6efd', color: 'white', marginRight: '10px' }}
                                                    onClick={() => { GoToEditPage(event.id) }}>Edit</button>
                                                <button className="btn btn-danger btn-sm" style={{ width: '45%' }}
                                                    onClick={() => { HandleDelete(event) }}>Delete</button>
                                            </td>
                                        ) :
                                            (<td>
                                                <button className="btn btn-danger btn-sm" style={{ width: '45%' }}
                                                    onClick={() => { GotoViewPage(event.id) }}>View</button>
                                            </td>)
                                        }

                                    </tr>
                                ))

                                // =====================

                            )}
                    </tbody>
                </table>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <ReactPaginate
                    previousLabel={<span aria-hidden="true">&laquo;</span>}
                    nextLabel={<span aria-hidden="true">&raquo;</span>}
                    breakLabel={<span>...</span>}
                    breakClassName={'page-item disabled'}
                    breakLinkClassName={'page-link'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    disabledClassName={'disabled'}
                    activeLinkClassName={'bg-dark text-white'}
                />
            </div>



        </div >


    );
}