// Dashboard.js

import React, { useState } from 'react';
import '../../assets/admin/css/Admindashboard.css'; // Import your CSS file

import Ontime from "../../assets/admin/assets/img/ontime.png";
import employee from "../../assets/admin/assets/img/employee.png";
import slow from "../../assets/admin/assets/img/late.png";
import Absent from "../../assets/admin/assets/img/absent.png";
import Present from "../../assets/admin/assets/img/present.png";
import Halfday from "../../assets/admin/assets/img/halfday.png";
import leave from "../../assets/admin/assets/img/leave.png";
import DateTimeComponent from './DateTimeComponent';
import { Link } from 'react-router-dom';


function Admindashboard({ days_absent, days_leave, days_halfday, days_late, days_onduty, days_permission, days_present, total_employee_count }) {
  const [employees] = useState(40);
  const [onTime] = useState(37);
  const [late] = useState(2);
  const [absent] = useState(1);
  const [present] = useState(39);

  return (
    <>
      <h1 className='dash-title-main'>Admin Dashboard</h1>
      <div className="admin-dashboard">

        <div className="data-box">
          <Link to="/admin/employeedetails" style={{ color: 'white', textDecoration: 'none' }}>
            <div className="box-content">
              <span>
                <span className='img-alignment'>
                  <img src={employee} alt="employee" className='dash-img' />
                </span>
                <h4 className='dash-title'>Total Employees</h4>
                <h4 className='dash-values'>{total_employee_count}</h4>
              </span>
            </div>
          </Link>
        </div>
        <div className="data-box">
          <Link to="/admin/dashboardviewattendance/P" style={{ color: 'white', textDecoration: 'none' }}>
            <div className="box-content">
              <span>
                <span className='img-alignment'>
                  <img src={Present} alt="employee Present" className='dash-img' />
                </span>
                <h4 className='dash-title'>Total Present</h4>
                <h4 className='dash-values'>{days_present}</h4>
              </span>
            </div></Link>
        </div>

        <div className="data-box">
          <Link to="/admin/dashboardviewattendance/A" style={{ color: 'white', textDecoration: 'none' }}>
            <div className="box-content">
              <span>
                <span className='img-alignment'>
                  <img src={Absent} alt="employee absent" className='dash-img' />
                </span>
                <h4 className='dash-title'>Absent</h4>
                <h4 className='dash-values'>{days_absent}</h4>
              </span>
            </div></Link>
        </div>

        <div className="data-box">
          <Link to="/admin/dashboardviewattendance/L" style={{ color: 'white', textDecoration: 'none' }}>
            <div className="box-content">
              <span>
                <span className='img-alignment'>
                  <img src={leave} alt="employee leave" className='dash-img' />
                </span>
                <h4 className='dash-title'>Leave</h4>
                <h4 className='dash-values'>{days_leave}</h4>
              </span>
            </div></Link>
        </div>

        <div className="data-box">
          <Link to="/admin/dashboardviewattendance/LA" style={{ color: 'white', textDecoration: 'none' }}>
            <div className="box-content">
              <span>
                <span className='img-alignment'>
                  <img src={slow} alt="employee late" className='dash-img' />
                </span>
                <h4 className='dash-title'>Late</h4>
                <h4 className='dash-values'>{days_late}</h4>
              </span>
            </div>  </Link>
        </div>
        <div className="data-box">
          <Link to="/admin/dashboardviewattendance/PR" style={{ color: 'white', textDecoration: 'none' }}>
            <div className="box-content">
              <span>
                <span className='img-alignment'>
                  <img src={Ontime} alt="Ontime" className='dash-img' />
                </span>
                <h4 className='dash-title'>Permission</h4>
                <h4 className='dash-values'>{days_permission}</h4>
              </span>
            </div></Link>
        </div>

        <div className="data-box">
          <Link to="/admin/dashboardviewattendance/HL" style={{ color: 'white', textDecoration: 'none' }}>
            <div className="box-content">
              <span>
                <span className='img-alignment'>
                  <img src={Halfday} alt="halfday" className='dash-img' />
                </span>
                <h4 className='dash-title'>Half Day</h4>
                <h4 className='dash-values'>{days_halfday}</h4>
              </span>
            </div></Link>
        </div>



      </div>
      {/* <DateTimeComponent /> */}
    </>
  );
}

export default Admindashboard;
